import {
  getAPIAddress, toCamelCase, handleRes, formatText, toSnakeCase, convertArrayToHashmap, convertInchesToMm, handleError,
} from '../../helpers';
import * as types from '../../constants';


export const fetchVerificationTypes = (params, successCallback) => dispatch => {
  dispatch({
    type: types.FETCH_VERIFICATION_TYPES,
    params,
  });

  const APIAdress = getAPIAddress();
  let reqParams = {
    page: 1,
    perPage: 9000,
  };
  if (params) {
    reqParams = {
      perPage: 30,
      ...params,
    };
  }
  reqParams = toSnakeCase(reqParams);
  fetch(`${APIAdress}/qc/verification_types?` + new URLSearchParams(reqParams))
    .then(res => handleRes(res))
    .then(res => {
      if (res.total === undefined) throw res;

      let verificationTypes = toCamelCase(res);
      verificationTypes.list = verificationTypes.list.map(type => {
        type.subtypes = convertArrayToHashmap(type.subtypes);
        return type;
      });
      verificationTypes.list = convertArrayToHashmap(verificationTypes.list);

      dispatch({
        type: types.FETCH_VERIFICATION_TYPES_SUCCESS,
        verificationTypes,
      });
      if (successCallback) successCallback();
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_VERIFICATION_TYPES_ERROR,
        error: formatText(res.error),
      });
    });
};

export const createVerificationType = (
  checklistTypeId, verification, isImperial, successCallback,
) => dispatch => {
  let reference;
  let deviationStart;
  let deviationEnd;
  let defaultMeasurement;
  if (verification.reference) {
    if (isImperial) {
      reference = convertInchesToMm(verification.reference);
    } else {
      reference = parseFloat(
        verification.reference.replace(',', '.'),
      );
    }
  }
  if (verification.deviationStart) {
    if (isImperial) {
      deviationStart = convertInchesToMm(verification.deviationStart);
      deviationEnd = convertInchesToMm(verification.deviationEnd);
    } else {
      deviationStart = parseFloat(
        verification.deviationStart.replace(',', '.'),
      );
      deviationEnd = parseFloat(
        verification.deviationEnd.replace(',', '.'),
      );
    }
  }
  if (verification.defaultMeasurement) {
    if (isImperial) {
      defaultMeasurement = convertInchesToMm(verification.defaultMeasurement);
    } else {
      defaultMeasurement = parseFloat(
        verification.defaultMeasurement.replace(',', '.'),
      );
    }
  }
  if (verification.isAcceptanceDisabled !== undefined) {
    verification.isAcceptanceDisabled = !verification.isAcceptanceDisabled;
  }

  if ((verification.reference && !reference)
      || (verification.deviationStart && !deviationStart === undefined)
      || (verification.deviationEnd && !deviationEnd === undefined)
      || (verification.defaultMeasurement && !defaultMeasurement)
  ) {
    dispatch({
      type: types.SHOW_NOTIFICATION,
      params: {
        type: 'error',
        text: 'Validation error',
      },
    });
    return;
  } else {
    verification.reference = reference;
    verification.deviationStart = deviationStart;
    verification.deviationEnd = deviationEnd;
    verification.defaultMeasurement = defaultMeasurement;
  }
  const fakeId = Math.random();

  dispatch({
    type: types.CREATE_VERIFICATION_TYPE,
    fakeId,
    verification,
  });

  delete verification.position;
  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/qc/verification_types`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      toSnakeCase(verification),
    ),
  })
    .then(res => handleRes(res))
    .then(res => {
      if (res.error) throw res;

      dispatch({
        type: types.CREATE_VERIFICATION_TYPE_SUCCESS,
        fakeId,
        verification: {
          id: res.id,
        },
      });
      if (successCallback) successCallback(res.id);
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Verification has been created!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.CREATE_VERIFICATION_TYPE_ERROR,
        fakeId,
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const editVerificationType = (
  checklistTypeId, id, params, originalVerification, isImperial, successCallback,
) => dispatch => {
  if (params.name === undefined) {
    params.name = originalVerification.name;
  }
  if (params.reference) {
    if (isImperial) {
      params.reference = convertInchesToMm(params.reference);
    } else {
      params.reference = parseFloat(
        params.reference.replace(',', '.'),
      );
    }
  } else if (params.reference === '') {
    params.reference = null;
  }
  if (params.deviationStart) {
    if (isImperial) {
      params.deviationStart = convertInchesToMm(params.deviationStart);
      params.deviationEnd = convertInchesToMm(params.deviationEnd);
    } else {
      if (params.deviationStart) {
        params.deviationStart = parseFloat(
          params.deviationStart.replace(',', '.'),
        );
      }
      if (params.deviationEnd) {
        params.deviationEnd = parseFloat(
          params.deviationEnd.replace(',', '.'),
        );
      }
    }
  } else if (params.deviationStart === '') {
    params.deviationStart = null;
    params.deviationEnd = null;
  }
  if (params.defaultMeasurement) {
    if (isImperial) {
      params.defaultMeasurement = convertInchesToMm(params.defaultMeasurement);
    } else {
      params.defaultMeasurement = parseFloat(
        params.defaultMeasurement.replace(',', '.'),
      );
    }
  } else if (params.defaultMeasurement === '') {
    params.defaultMeasurement = null;
  }
  if (params.isAcceptanceDisabled !== undefined) {
    params.isAcceptanceDisabled = !params.isAcceptanceDisabled;
  }

  dispatch({
    type: types.EDIT_VERIFICATION_TYPE,
    id,
    params,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/qc/verification_types/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      toSnakeCase(params),
    ),
  })
    .then(res => handleRes(res))
    .then(res => {
      if (res.error) throw res;

      dispatch({
        type: types.EDIT_VERIFICATION_TYPE_SUCCESS,
        id: res.id,
      });
      if (successCallback) successCallback(res.id);
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Verification type has been edited!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.EDIT_VERIFICATION_TYPE_ERROR,
        id,
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const removeVerificationType = (id, successCallback) => dispatch => {
  dispatch({
    type: types.REMOVE_VERIFICATION_TYPE,
    id,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/qc/verification_types/${id}`, {
    method: 'DELETE',
  })
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      dispatch({
        type: types.REMOVE_VERIFICATION_TYPE_SUCCESS,
        id,
      });
      if (successCallback) successCallback();
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Removed!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.REMOVE_VERIFICATION_TYPE_ERROR,
        id,
        error: formatText(res.error),
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const choose = (verificationType, parentId) => dispatch => {
  dispatch({
    type: types.CHOOSE_VERIFICATION_TYPE,
    verificationType,
    parentId,
  });
};

export const unchoose = id => dispatch => {
  dispatch({
    type: types.UNCHOOSE_VERIFICATION_TYPE,
    id,
  });
};

export const reset = () => dispatch => {
  dispatch({
    type: types.RESET_CHOSEN_VERIFICATION_TYPES,
  });
};