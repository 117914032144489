import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import {
  Box, Table, TableBody, TableCell, TableHeader, TableRow, Text, Pagination, Button,
} from 'grommet';
import {
  FormPreviousLink,
} from 'grommet-icons';

import Moment from 'react-moment';

import Loader from '../loader';
import AuthCheck from '../auth-check';
import { fetchChangelog } from './actions';

import s from './index.module.scss';


const Changelog = () => {
  const changelog = useSelector(state => state.changelog);
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const { partId } = useParams();
  const history = useHistory();

  const changePage = ({ page }) => {
    dispatch(
      fetchChangelog({ page, partId }),
    );
  };

  useEffect(() => {
    dispatch(
      fetchChangelog({
        page: changelog.page || 1,
        partId,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    history.push('/tools');
  };

  let changelogArray;
  if (changelog.total && changelog.isLoading === false) {
    changelogArray = Object.keys(changelog.list)
      .filter(id => changelog.list[id].isLoading !== true)
      .sort((prevId, id) => {
        return new Date(changelog.list[id].created) - new Date(changelog.list[prevId].created);
      })
      .map(id => changelog.list[id]);
  }

  const getFormat = created => {
    const date = new Date(created);
    const currentDate = new Date();
    if (currentDate.getMonth() === date.getMonth() && currentDate.getYear() === date.getYear()) {
      if (currentDate.getDate() === date.getDate()) {
        return '[Today]';
      } else if (currentDate.getDate() - 1 === date.getDate()) {
        return '[Yesterday]';
      }
    }
    if (currentDate.getFullYear() === date.getFullYear()) {
      return 'MMMM Do, dddd';
    } else {
      return 'MMMM Do, y';
    }
  };

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return changelog.isLoading === false ?
    changelog.total ?
      <div className={s.changelog}>
        <Box direction='row' width='100%' justify='between' className={s.navHeader}>
          <Button className='buttonLink' onClick={goBack}>
            <FormPreviousLink />
            Tools
          </Button>
        </Box>
        <Table>
          <TableHeader>
            <TableRow>
                <TableCell>
                  <Text className={s.header}>Action</Text>
                </TableCell>
                <TableCell>
                  <Text className={s.header}>User</Text>
                </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {changelogArray.map((changelogItem, index) => {
              let prevDay;
              if (changelogArray[index + 1]) prevDay = new Date(changelogArray[index + 1].created).getDate();
              const currentDay = new Date(changelogItem.created).getDate();

              return [
                !index &&
                  <TableRow key={index + 101} className={s.dateRow}>
                    <TableCell colSpan='2'>
                      <Moment format={getFormat(changelogItem.created)}>{changelogItem.created}</Moment>
                    </TableCell>
                  </TableRow>,
                  <TableRow key={index} className={s.row}>
                    <TableCell>
                      {changelogItem.nameData ? changelogItem.nameData.map((nameSection, index) =>
                        <span key={index}>
                          {nameSection.type === 'link' ?
                            <Link
                              to={nameSection.url}
                              className='link'
                              target={nameSection.url.includes('/api/v1/') ? '_blank' : null}
                            >
                              {nameSection.text}
                            </Link> :
                            nameSection.type === 'text' ?
                            <Text>{nameSection.text}</Text> : null}
                        </span>) : <Text>{changelogItem.name}</Text>}
                      {changelogItem.repeated > 1 ? ` (x${changelogItem.repeated})` : null}
                    </TableCell>
                    <TableCell className={s.date}>
                      <>
                        <Moment format='hh:mm A'>{changelogItem.created}</Moment>
                        <br />
                        <Text> by {changelogItem.createdUser.name}</Text>
                      </>
                    </TableCell>
                  </TableRow>,
                  prevDay !== currentDay && changelogArray[index + 1] &&
                  <TableRow key={index + 102} className={s.dateRow}>
                    <TableCell colSpan='2' margin={{top: 'medium'}}>
                      <Moment format={getFormat(changelogArray[index + 1].created)}>
                        {changelogArray[index + 1].created}
                      </Moment>
                    </TableCell>
                  </TableRow>
              ]
          })}
          </TableBody>
        </Table>

        {changelog.total > 50 &&
          <Box>
            <Pagination
              className={s.pagination}
              numberItems={changelog.total}
              step={50}
              numberMiddlePages={3}
              onChange={changePage}
              page={changelog.page}
              alignSelf='center'
            />
          </Box>
        }
        <AuthCheck isAuthorized={true} />
      </div>
    :
      <Box className={s.changelog} align='center'>Nothing found</Box>
  :
    <Loader mod={s.loader} />
};

export default Changelog;
