import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SectionComponent from '../../components/sections';
import { fetchProfile } from '../profile/actions';

import s from './index.module.scss';


const Sections = () => {
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.sections}>
      {profile.name &&
        <SectionComponent />}
    </div>
  );
};

export default Sections;
