import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  Pagination,
  Button,
} from 'grommet';
import {
  FormPreviousLink,
} from 'grommet-icons';
import Moment from 'react-moment';

import { clearObject } from '../../helpers';
import Loader from '../loader';
import AuthCheck from '../auth-check';
import { fetchChecklistTypes } from './actions';

import s from './index.module.scss';


const ChecklistTypes = () => {
  const locations = useSelector(state => state.locations);
  const checklistTypes = useSelector(state => state.checklistTypes);
  const partTypes = useSelector(state => state.partTypes);
  const materialTypes = useSelector(state => state.materialTypes);
  const entities = useSelector(state => state.entities);
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const history = useHistory();

  const changePage = ({ page }) => {
    dispatch(
      fetchChecklistTypes({ page }),
    );
  };

  useEffect(() => {
    dispatch(
      fetchChecklistTypes({
        page: checklistTypes.page || 1,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToConstructor = () => {
    history.push('/tools/create-checklist-type');
  };

  const goBack = () => {
    history.push('/tools');
  };

  let checklistTypesArray;
  if (checklistTypes.total && checklistTypes.isLoading === false) {
    checklistTypesArray = Object.keys(clearObject(checklistTypes.list))
      .filter(id => checklistTypes.list[id].isLoading !== true)
      .sort((prevId, id) => {
        return new Date(checklistTypes.list[id].changed || checklistTypes.list[id].created) -
          new Date(checklistTypes.list[prevId].changed || checklistTypes.list[prevId].created);
      })
      .map(id => checklistTypes.list[id]);
  }

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return checklistTypes.isLoading === false && partTypes.isLoading === false && materialTypes.isLoading === false
      && locations.isLoading === false && entities.isLoading === false ?
    checklistTypes.total ?
      <div className={s.checklistTypes}>
        <Box direction='row' width='100%' justify='between' className={s.navHeader}>
          <Button className='buttonLink' onClick={goBack}>
            <FormPreviousLink />
            Tools
          </Button>
        </Box>
        <div className={s.controls}>
          <Button onClick={goToConstructor} primary label='Add' />
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>
                <Text className={s.header}>Name</Text>
              </TableCell>
              <TableCell>
                <Text className={s.header}>Type</Text>
              </TableCell>
              <TableCell>
                <Text className={s.header}>Subtypes</Text>
              </TableCell>
              <TableCell>
                <Text className={s.header}>Locations</Text>
              </TableCell>
              <TableCell>
                <Text className={s.header}>Last changed</Text>
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {checklistTypesArray.map(checklistType =>
              (!checklistType.error && checklistType.created &&
                <TableRow key={checklistType.id} className={s.row}>
                  <TableCell>
                    <Link to={`/tools/checklist-types/${checklistType.id}`} className='link'>
                      <Text>{checklistType.name}</Text>
                    </Link>
                  </TableCell>
                  <TableCell size='xsmall'>
                    <Text>{checklistType.entity.name}</Text>
                  </TableCell>
                  <TableCell size='xsmall' className={s.breakLine}>
                    <Text>
                      {checklistType.partTypes &&
                        checklistType.partTypes.length === Object.keys(partTypes.list).length ?
                          'All' :
                          checklistType.partTypes.map(type => type.name + '\n')}
                      {checklistType.entity.name === 'Part' && checklistType.partTypes.length === 0 && '—'}
                      {checklistType.materials &&
                        checklistType.materials.length === Object.keys(materialTypes.list).length ?
                          'All' :
                          checklistType.materials.map(type => type.name + '\n')}
                      {checklistType.entity.name === 'Material' && checklistType.materials.length === 0 && '—'}
                    </Text>
                  </TableCell>
                  <TableCell size='xxsmall' className={s.breakLine}>
                    <Text>
                      {checklistType.locations &&
                        checklistType.locations.length === Object.keys(locations.list).length ?
                          'All' :
                          checklistType.locations.map(location => location.name + '\n')}
                      {checklistType.locations && checklistType.locations.length === 0 && '—'}
                    </Text>
                  </TableCell>
                  <TableCell size='small'>
                    <>
                      <Text>
                        <Moment format='lll'>{checklistType.changed || checklistType.created}</Moment>
                      </Text>
                      <br />
                      <Text>by {(checklistType.changed && checklistType.changedUser.name)
                        || (checklistType.created && checklistType.createdUser.name)}</Text>
                    </>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>

        {checklistTypes.total > 20 &&
          <Box>
            <Pagination
              className={s.pagination}
              numberItems={checklistTypes.total}
              step={20}
              numberMiddlePages={3}
              onChange={changePage}
              page={checklistTypes.page}
              alignSelf='center'
            />
          </Box>
        }
        <AuthCheck isAuthorized={true} />
      </div>
    :
      <Box className={s.checklistTypes} align='center'>Nothing found</Box>
  :
    <Loader mod={s.loader} />
};

export default ChecklistTypes;
