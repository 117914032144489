import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DevicesComponent from '../../components/devices';
import { fetchProfile } from '../profile/actions';

import s from './index.module.scss';


const Devices = () => {
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.devices}>
      {profile.name &&
        <DevicesComponent />}
    </div>
  );
};

export default Devices;
