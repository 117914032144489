import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Menu,
} from 'grommet';
import {
  MoreVertical,
} from 'grommet-icons';

import { convertHashmapToArray, clearObject, sortByKey } from '../../helpers';
import Loader from '../loader';
import { deleteVerificationType } from '../checklist-type/actions';

import s from './index.module.scss';


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Verification = ({ verification, index, checklistTypeId }) => {
  const dispatch = useDispatch();

  const remove = id => {
    dispatch(
      deleteVerificationType(checklistTypeId, id),
    );
  };

  return (
    <Draggable draggableId={verification.id.toString()} index={index}>
      {provided => (
        <div
          className={typeof(verification.id) === 'string' ? `${s.verification} ${s.section}` : s.verification}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {verification.isLoading ?
            <Loader mod={s.loader} type='icon' /> :
            <Menu
              icon=<MoreVertical />
              items={[
                {label: 'Remove', onClick: () => remove(verification.id), color: '#ff0000' },
              ]}
            />
          }
          {verification.name}
        </div>
      )}
    </Draggable>
  );
};

const VerificationsList = ({ verifications, checklistTypeId }) => {
  if (!verifications.length) {
    return <div className={s.nothing}>Nothing found</div>;
  }
  return verifications.map((verification, index) => (
    <Verification verification={verification} index={index} key={verification.id} checklistTypeId={checklistTypeId} />
  ));
};

const DraggableVerifications = props => {
  const verificationsArray = sortByKey(
    convertHashmapToArray(clearObject(props.verifications)), 'position',
  );
  const [state, setState] = useState({ verifications: verificationsArray });

  useEffect(() => {
    setState({ verifications: verificationsArray });
    props.callback(verificationsArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.verifications]);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const verifications = reorder(
      state.verifications,
      result.source.index,
      result.destination.index,
    );

    verifications.forEach((verification, index) => {
      verification.position = index;
    });

    setState({ verifications });
    props.callback(verifications);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='list'>
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps} className={s.draggableVerifications}>
            <VerificationsList verifications={state.verifications} checklistTypeId={props.checklistTypeId} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableVerifications;
