import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Box,
} from 'grommet';
import {
  Schedules, CatalogOption, Trash, Cluster, Beacon, Group, Analytics, Time,
} from 'grommet-icons';

import Loader from '../loader';
import AuthCheck from '../auth-check';

import s from './index.module.scss';


const Tools = () => {
  const profile = useSelector(state => state.profile);

  return profile && profile.isLoading === false ?
    profile.error === false ?
      <Box className={s.tools}>
        <Link to='/tools/checklist-types' className={s.link}>
          <Box>
            <Schedules size='xlarge' />
            Checklists Constructor
          </Box>
        </Link>
        <Link to='/tools/changelog' className={s.link}>
          <Box>
            <CatalogOption size='xlarge' />
            Changelog
          </Box>
        </Link>
        <Link to='/tools/work-centers' className={s.link}>
          <Box>
            <Cluster size='xlarge' />
            Work Centers
          </Box>
        </Link>
        <Link to='/tools/reject-reasons' className={s.link}>
          <Box>
            <Trash size='xlarge' />
            Reject Reasons
          </Box>
        </Link>
        <Link to='/tools/scanner' className={s.link}>
          <Box>
            <Beacon size='xlarge' />
            Scanner Page
          </Box>
        </Link>
        <Link to='/tools/user-management' className={s.link}>
          <Box>
            <Group size='xlarge' />
            User Management
          </Box>
        </Link>
        <Link target='_blank' to='/metabase' className={s.link}>
          <Box>
            <Analytics size='xlarge' />
            Metabase
          </Box>
        </Link>
        <Link target='_blank' to={{ pathname: 'http://sensors.mightybuildings.com' }} className={s.link}>
          <Box>
            <Time size='xlarge' />
            Grafana
          </Box>
        </Link>
        <AuthCheck isAuthorized={true} />
      </Box>
    :
      <AuthCheck isAuthorized={false} />
  :
    <Loader />
};

export default Tools;
