import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom'
import {
  Box, Button, Form, FormField, TextInput, Select, TextArea,
} from 'grommet';
import $ from 'jquery';

import { deepCopy } from '../../helpers';
import Loader from '../loader';
import AuthCheck from '../auth-check';
import Header from '../header';
import { editPart } from './actions';
// import { fetchPart, fetchManufacturingOrders } from '../part/actions';
import { fetchPart } from '../part/actions';
import { fetchStages } from '../stages/actions';
import { fetchSteelFrames } from '../steel-frames/actions';

import s from './index.module.scss';


const EditPart = () => {
  const parts = useSelector(state => state.parts);
  const stages = useSelector(state => state.stages);
  // const manufacturingOrders = useSelector(state => state.manufacturingOrders);
  const steelFrames = useSelector(state => state.steelFrames);
  const { partId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      fetchPart(partId)
    );
    dispatch(fetchStages());
    // We explicitly update Odoo entities
    // dispatch(fetchManufacturingOrders());
    dispatch(fetchSteelFrames());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partId]);

  let part;
  if (parts.list && parts.list[partId]) {
    part = parts.list[partId];
  }
  if (stages.list) delete stages.list['1']; // Remove 'Incoming control'

  const goBack = () => {
    history.push(`/parts/${partId}`);
  };

  const edit = e => {
    const params = deepCopy(e.value);
    params.rfid = $('#rfid').val();
    // dispatch(
    //   editPart(partId, part, stages.list, manufacturingOrders.list, steelFrames.list, params, goBack),
    // );
    dispatch(
      editPart(partId, part, stages.list, {}, params, goBack),
    );

  };

  // const getManufacturingOrders = () => {
  //   let manufacturingOrdersToSelect = ['None'];
  //   if (manufacturingOrders.list) {
  //     manufacturingOrdersToSelect = manufacturingOrdersToSelect.concat(
  //       Object.keys(manufacturingOrders.list).map(key => manufacturingOrders.list[key].name),
  //     );
  //   }
  //   if (part.manufacturingOrder && !manufacturingOrdersToSelect.includes(part.manufacturingOrder.name)) {
  //     manufacturingOrdersToSelect.push(part.manufacturingOrder.name);
  //   }
  //   return manufacturingOrdersToSelect;
  // };

  const getSteelFrames = () => {
    let steelFramesToSelect = ['None'];
    if (steelFrames.list) {
      steelFramesToSelect = steelFramesToSelect.concat(Object.keys(steelFrames.list));
    }
    if (part.steelFrame && !steelFramesToSelect.includes(part.steelFrame.name)) {
      steelFramesToSelect.push(part.steelFrame.name);
    }
    return steelFramesToSelect;
  };

  // return part && part.isLoading === false && stages.isLoading === false && manufacturingOrders.isLoading === false ?
  return part && part.isLoading === false && stages.isLoading === false ?
    part.error === false ?
      <div className={s.editPart}>
        <Header type='editPart' entity={part} />
        <Box margin={{left: '25px'}} width='50%'>
          <Form onSubmit={edit}>
            <FormField htmlFor='name' label='Name'>
              <TextInput id='name' name='name' defaultValue={part.name} />
            </FormField>
            <FormField label='Work center' name='stage'>
              <Select
                name='stage'
                options={Object.keys(stages.list).map(key => stages.list[key].name)}
                defaultValue={part.stage && part.stage.name}
              />
            </FormField>
            <FormField htmlFor='rfid' label='RFID'>
              <TextInput id='rfid' name='rfid' defaultValue={part.rfid} />
            </FormField>
            <FormField htmlFor='insignia' label='Insignia'>
              <TextInput id='insignia' name='insignia' defaultValue={part.insignia} />
            </FormField>
{/*            <FormField label='Manufacturing order (from Odoo)' name='manufacturingOrder'>
              <Select
                name='manufacturingOrder'
                options={getManufacturingOrders()}
                defaultValue={part.manufacturingOrder && part.manufacturingOrder.name}
              />
            </FormField>*/}
            <FormField label='Steel frame (from Odoo)' name='steelFrame'>
              <Select
                name='steelFrame'
                options={getSteelFrames()}
                defaultValue={part.steelFrame && part.steelFrame.name}
              />
            </FormField>
            <FormField htmlFor='part-description' label='Description'>
              <TextArea id='part-description' name='description' defaultValue={part.description} />
            </FormField>
            <Box direction='row' margin={{top: 'medium'}}>
              <Button type='submit' primary label='Submit' />
            </Box>
          </Form>
        </Box>
        <AuthCheck isAuthorized={true} />
      </div>
    :
      <AuthCheck isAuthorized={false} />
  :
    <Loader />
};

export default EditPart;
