import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Table, TableBody, TableCell, TableHeader, TableRow, Pagination, Text,
} from 'grommet';
import {
  StatusGood, CircleAlert, Clear,
} from 'grommet-icons';

import Moment from 'react-moment';

import AuthCheck from '../auth-check';
import Loader from '../loader';
import { fetchChecklists } from './actions';
import { getPartName } from '../../helpers';

import s from './index.module.scss';


const Checklists = () => {
  const checklists = useSelector(state => state.checklists);
  const profile = useSelector(state => state.profile);
  const checklistTypes = useSelector(state => state.checklistTypes);
  const dispatch = useDispatch();

  const changePage = ({ page }) => {
    dispatch(
      fetchChecklists(
        { page, },
        {
          types: checklistTypes,
        },
        checklists.filters,
      ),
    );
  };

  useEffect(() => {
    dispatch(
      fetchChecklists(
        {
          page: checklists.page || 1,
        },
        {
          types: checklistTypes,
        },
        checklists.filters,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let checklistsArray;
  if (checklists.total && checklists.isLoading === false) {
    checklistsArray = Object.keys(checklists.list)
      .sort((prevId, id) => {
        return new Date(checklists.list[id].created) - new Date(checklists.list[prevId].created);
      })
      .map(id => checklists.list[id]);
  }

  const getFormat = created => {
    const date = new Date(created);
    const currentDate = new Date();
    if (currentDate.getMonth() === date.getMonth() && currentDate.getYear() === date.getYear()) {
      if (currentDate.getDate() === date.getDate()) {
        return '[Today]';
      } else if (currentDate.getDate() - 1 === date.getDate()) {
        return '[Yesterday]';
      }
    }
    if (currentDate.getFullYear() === date.getFullYear()) {
      return 'MMMM Do, dddd';
    } else {
      return 'MMMM Do, y';
    }
  };

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return checklists.isLoading === false ?
    checklists.total ?
      <div className={s.checklists}>
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Entity</TableCell>
              <TableCell>Resolution</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {checklistsArray.map((checklist, index) => {
              let prevDay;
              if (checklistsArray[index + 1]) prevDay = new Date(checklistsArray[index + 1].created).getDate();
              const currentDay = new Date(checklist.created).getDate();

              return [
                !index &&
                  <TableRow key={index + 101} className={s.dateRow}>
                    <TableCell colSpan='6'>
                      <Moment format={getFormat(checklist.created)}>{checklist.created}</Moment>
                    </TableCell>
                  </TableRow>,
                  <TableRow key={checklist.id} className={s.row}>
                    <TableCell className={s.name} size='medium'>
                      <Link
                        to={checklist.part ?
                          `/parts/${checklist.part.id}/checklists/${checklist.id}` :
                          checklist.barrel ?
                          `/barrels/${checklist.barrel.id}/checklists/${checklist.id}` :
                          checklist.steelFrameName ?
                          `/steel-frames/${checklist.steelFrameName}/checklists/${checklist.id}` :
                          null}
                        className='link'
                      >
                        {(checklist.name && checklist.name) || (checklist.type && checklist.type.name)}
                        {checklist.stage && checklist.stage.name}
                      </Link>
                    </TableCell>
                    <TableCell size='small'>
                      <Link
                        to={checklist.part ?
                          `/parts/${checklist.part.id}` :
                          checklist.barrel ?
                          `/barrels/${checklist.barrel.id}` :
                          checklist.steelFrameName ?
                          `/steel-frames/${checklist.steelFrameName}` :
                          null}
                        className='link'
                      >
                        {checklist.part ? getPartName(checklist.part) :
                          checklist.barrel ? checklist.barrel.serial :
                          checklist.steelFrameName ? checklist.steelFrameName :
                          null}
                      </Link>
                    </TableCell>
                    <TableCell className={s.resolution} size='xsmall'>
                      {checklist.resolution &&
                        <Text>
                          {[1, 4].includes(checklist.resolution.id) ?
                              <StatusGood color='#008000' /> :
                            [2, 5, 7].includes(checklist.resolution.id) ?
                              <CircleAlert color='#ff8d00' /> :
                            [3, 6].includes(checklist.resolution.id) ?
                              <Clear color='#ff0000' /> : null}
                          <Text
                            color={[1, 4].includes(checklist.resolution.id) ? '#008000' :
                              [2, 5, 7].includes(checklist.resolution.id) ? '#ff8d00' :
                              [3, 6].includes(checklist.resolution.id) ? '#ff0000' : null}
                          >
                            {checklist.resolution.name}
                          </Text>
                        </Text>}
                    </TableCell>
                  </TableRow>,
                  prevDay !== currentDay && checklistsArray[index + 1] &&
                  <TableRow key={index + 102} className={s.dateRow}>
                    <TableCell colSpan='6'>
                      <Moment format={getFormat(checklistsArray[index + 1].created)}>
                        {checklistsArray[index + 1].created}
                      </Moment>
                    </TableCell>
                  </TableRow>,
              ]
          })}
          </TableBody>
        </Table>

        {checklists.total > 50 &&
          <Box>
            <Pagination
              className={s.pagination}
              numberItems={checklists.total}
              step={50}
              numberMiddlePages={3}
              onChange={changePage}
              page={checklists.page}
              alignSelf='center'
            />
          </Box>
        }
        <AuthCheck isAuthorized={true} />
      </div>
    :
      <Box className={s.checklists} align='center'>Nothing found</Box>
  :
    <Loader mod={s.loader} />
};

export default Checklists;
