import { getAPIAddress, toCamelCase, handleRes, formatText, handleError } from '../../helpers';
import * as types from '../../constants';


export const fetchProfile = () => (dispatch) => {
  dispatch({
    type: types.FETCH_PROFILE,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/profile`)
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      const profile = toCamelCase(res);
      dispatch({
        type: types.FETCH_PROFILE_SUCCESS,
        profile,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_PROFILE_ERROR,
        error: formatText(res.error),
      });
    });
};

export const signOut = successCallback => (dispatch) => {
  dispatch({
    type: types.SIGNOUT,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/auth/sign-out`, {
    method: 'POST',
  })
    .then(res => handleRes(res))
    .then(res => {
      if (!res.success) throw res;

      dispatch({
        type: types.SIGNOUT_SUCCESS,
      });
      successCallback();
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Bye!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.SIGNOUT_ERROR,
        error: formatText(res.error),
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};
