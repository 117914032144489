import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import {
  Box, Table, TableBody, TableCell, TableRow, Text, Button, Menu, Layer, Form, FormField, Select, TextInput,
} from 'grommet';
import {
  MoreVertical, Close,
} from 'grommet-icons';
import Moment from 'react-moment';
import $ from 'jquery';

import Loader from '../loader';
import { removeChecklist, createChecklist } from './actions';

import s from './index.module.scss';


const QC = props => {
  let { partId, barrelId, steelFrameName } = useParams();
  partId = parseInt(partId);
  barrelId = parseInt(barrelId);
  const dispatch = useDispatch();

  const [modalIsActive, toggleModal] = useState();
  const [removeModalIsActive, toggleRemoveModal] = useState();

  const urlId = partId || barrelId || steelFrameName;
  const EntityType = partId ? 'parts' : barrelId ? 'barrels' : steelFrameName ? 'steel-frames' : null;

  const remove = id => {
    dispatch(
      removeChecklist(id, partId, barrelId, steelFrameName),
    );
    toggleRemoveModal(false);
  };

  const downloadAsPDF = id => {
    window.location.replace(`/api/v1/qc/checklists/${id}/pdf`);
  };

  const create = e => {
    dispatch(
      createChecklist(
        {
          id: parseInt(e.value.type.value),
          name: e.value.name || e.value.type.label,
        },
        partId, barrelId, steelFrameName,
      ),
    );
    toggleModal(false);
  };

  const unlockButton = e => {
    $('.submit').prop('disabled', false).removeClass('disabled');
    $('#name').prop('value', e.value.label);
  };

  const getChecklistTypes = () => {
    let entityName = 'Part';
    if (props.entity.serial) {
      entityName = 'Material';
    } else if (props.entity.steelFrames) {
      entityName = 'Steel Frame';
    }

    return Object.keys(props.checklistTypes.list).filter(key =>
      props.checklistTypes.list[key].entity.name === entityName,
    ).map(key => {
      return {
        value: key,
        label: props.checklistTypes.list[key].name,
      };
    });
  };

  return <Box className={`${s.qc} ${props.mod}`}>
    <Text
      margin={{top: 'medium', left: 'small', right: 'small', bottom: 'small'}} weight='bold'
    >
      Quality Control:
    </Text>
    {(props.checklists && props.checklists.length) ?
      <Table>
        <TableBody>
          {props.checklists.map((checklist, index) => {
            let verifiedLength;
            if (checklist && checklist.verifications) {
              verifiedLength = Object.keys(checklist.verifications).filter(
                verificationId => checklist.verifications[verificationId].accepted
              ).length;
            }

            return checklist &&
              <TableRow key={index}>
                <TableCell className={s.cell} verticalAlign='top'>
                  <div className={s.title}>
                    {checklist.isLoading ?
                      <Loader mod={s.loader} type='icon' /> :
                      <>
                        <Menu
                          dropProps={{ className: s.drop }}
                          icon=<MoreVertical />
                          items={props.profile.role
                              && ['Admin', 'QC Admin', 'QC Inspector'].includes(props.profile.role.name) ?
                            [{
                              label: 'Download as PDF', onClick: () => downloadAsPDF(checklist.id),
                            },
                            {
                              label: 'Remove',
                              className: s.remove,
                              onClick: () => toggleRemoveModal(checklist.id),
                              color: '#ff0000',
                            }]
                          : [{
                              label: 'Download as PDF', onClick: () => downloadAsPDF(checklist.id),
                            }]}
                        />
                        {removeModalIsActive === checklist.id &&
                          <Layer
                            className='modal40'
                            onEsc={() => toggleRemoveModal(false)}
                            onClickOutside={() => toggleRemoveModal(false)}
                          >
                            <Text>Are you sure?</Text>
                            <Box direction='row' className='removeModalButtons'>
                              <Button type='button' label='Discard' onClick={() => toggleRemoveModal(false)} />
                              <Button
                                type='button'
                                primary
                                label='Remove'
                                onClick={() => remove(checklist.id)} color='#ff0000'
                              />
                            </Box>
                            <Close onClick={() => toggleRemoveModal(false)} />
                          </Layer>}
                      </>}
                    <Link className='link' to={`/${EntityType}/${urlId}/checklists/${checklist.id}`}>
                      {(checklist.name && checklist.name)
                        || (checklist.type && checklist.type.name)}
                      {checklist.stage && checklist.stage.name}
                    </Link>
                  </div>
                </TableCell>
                <TableCell>
                  <Table className={s.qcConclusion}>
                    <TableBody>
                      <TableRow>
                        <TableCell verticalAlign='top'>Resolution:</TableCell>
                        <TableCell verticalAlign='top'>
                          <Text weight='bold' color={
                            checklist.resolution && (
                              [1, 4, 8].includes(checklist.resolution.id) ? 'green' :
                                [2, 5, 7].includes(checklist.resolution.id) ? '#ff8d00' :
                                [3, 6].includes(checklist.resolution.id) ? 'red' : 'black'
                            )}
                          >
                            {checklist.resolution
                              ? checklist.resolution.name
                              : 'Unknown resolution'}
                          </Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell verticalAlign='top'>
                          <Text>Nonconformities</Text><br /><Text>detected:</Text>
                        </TableCell>
                        <TableCell verticalAlign='top'>
                          <Text>
                            <Text
                              weight={verifiedLength ? 'bold' : 'normal'}
                              color={verifiedLength ? 'red' : 'black'}
                            >
                              {checklist.verifications ? verifiedLength : 0}
                            </Text>
                            <Text> of </Text>
                            <Text>
                              {checklist.verifications
                                ? Object.keys(checklist.verifications).length
                                : 0
                              }
                            </Text>
                          </Text>
                        </TableCell>
                      </TableRow>
                      {checklist.changed &&
                        <TableRow>
                          <TableCell verticalAlign='top'>Last changed:</TableCell>
                          <TableCell verticalAlign='top'>
                            <Moment format='lll'>{checklist.changed}</Moment>
                            <br />
                            <Text>by {checklist.changedUser.name}</Text>
                          </TableCell>
                        </TableRow>}
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>})}
        </TableBody>
      </Table>
    :
      <Box align='start' margin='small'>Nothing found</Box>}
    <Button type='button' primary label='Add' onClick={() => toggleModal(true)} />
    {modalIsActive &&
      <Layer
        className='modal60'
        onEsc={() => toggleModal(false)}
        onClickOutside={() => toggleModal(false)}
      >
        <Form onSubmit={create}>
          <FormField label='Type' name='type'>
            <Select
              name='type'
              labelKey='label'
              options={getChecklistTypes()}
              placeholder='Select type'
              onChange={unlockButton}
            />
          </FormField>
          <FormField htmlFor='name' label='As name'>
            <TextInput id='name' name='name' />
          </FormField>
          <Box direction='row' margin={{top: 'medium'}}>
            <Button type='submit' primary disabled label='Create' className='submit disabled' />
          </Box>
        </Form>
        <Close onClick={() => toggleModal(false)} />
      </Layer>}
  </Box>
};

export default QC;
