import {
  getAPIAddress, toCamelCase, toSnakeCase, handleRes, formatText, convertArrayToHashmap, handleError,
} from '../../helpers';

import * as types from '../../constants';


export const fetchChecklists = (params, info, filters) => dispatch => {
  dispatch({
    type: types.FETCH_CHECKLISTS,
    params,
  });

  const APIAdress = getAPIAddress();
  let reqParams = {
    page: 1,
    perPage: 9000,
  };
  if (params) {
    reqParams = {
      page: params.page,
      perPage: 50,
    };
  }

  if (filters) {
    if (filters.started) reqParams.started = filters.started;
    if (filters.ended) reqParams.ended = filters.ended;
    if (filters.search) {
      reqParams.name = filters.search;
    }
    if (info.types.list && filters.types) {
      reqParams.typeIds = Object.keys(info.types.list).filter(key => {
        if (filters.types.includes(info.types.list[key].name)) return key;
        return null;
      });
    }
  }

  reqParams = toSnakeCase(reqParams);
  fetch(`${APIAdress}/qc/checklists?` + new URLSearchParams(reqParams))
    .then(res => handleRes(res))
    .then(res => {
      if (res.total === undefined) throw res;

      let checklists = toCamelCase(res);
      checklists.list = convertArrayToHashmap(checklists.list);

      dispatch({
        type: types.FETCH_CHECKLISTS_SUCCESS,
        checklists,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_CHECKLISTS_ERROR,
        error: formatText(res.error),
      });
    });
};

export const filterChecklists = filters => dispatch => {
  if (filters) {
    dispatch({
      type: types.FILTER_CHECKLISTS,
      filters,
    });
  } else {
    dispatch({
      type: types.RESET_CHECKLISTS_FILTERS,
    });
  }
};
