import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ThermoComponent from '../../components/thermo';
import { fetchProfile } from '../profile/actions';

import s from './index.module.scss';


const Thermo = () => {
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.thermo}>
      {profile.name && <ThermoComponent />}
    </div>
  );
};

export default Thermo;