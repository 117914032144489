import * as types from '../../constants';


const changelog = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_CHANGELOG:
      return {
        ...state,
        ...action.params,
        isLoading: true,
      };

    case types.FETCH_CHANGELOG_SUCCESS:
      return {
        ...state,
        ...action.changelog,
        isLoading: false,
        error: false,
      };

    case types.FETCH_CHANGELOG_ERROR:
      return {
        ...state,
        total: 0,
        list: [],
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default changelog;
