import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box, Table, TableBody, TableCell, TableHeader, TableRow, Text, Pagination, TextInput, Form, Button, Layer, FormField,
} from 'grommet';
import {
  Checkbox, CheckboxSelected, Close, FormPreviousLink,
} from 'grommet-icons';
import Moment from 'react-moment';
import $ from 'jquery';

import { clearObject, objectIsEmpty } from '../../helpers';
import Loader from '../loader';
import AuthCheck from '../auth-check';
import {
  fetchRejectReasons, createRejectReason, editRejectReason, removeRejectReason, choose, unchoose, reset,
} from './actions';

import s from './index.module.scss';


const RejectReasons = () => {
  const rejectReasons = useSelector(state => state.rejectReasons);
  const choosenRejectReasons = useSelector(state => state.choosenRejectReasons);
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchValue, setSearchValue] = useState('');
  const [modalIsActive, toggleModal] = useState();
  const [removeModalIsActive, toggleRemoveModal] = useState();
  const [showActionButtons, toggleActionButtons] = useState();
  const [editFormId, changeEditFormId] = useState();

  useEffect(() => {
    dispatch(
      fetchRejectReasons({
        page: rejectReasons.page || 1,
      }),
    );
    dispatch(reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let show;
    let editId;
    Object.keys(choosenRejectReasons).forEach(id => {
      if (choosenRejectReasons[id]) {
        show = true;
        editId === undefined ? editId = id : editId = false;
      }
    });
    changeEditFormId(editId);
    if (show) {
      toggleActionButtons(true);
      $('#controls').children('.submit').prop('disabled', false).removeClass('disabled');
    } else {
      toggleActionButtons(false);
      $('#controls').children('.submit').prop('disabled', true).addClass('disabled');
    }
    if (editId) {
      $('#controls').children('.submitEdit').prop('disabled', false).removeClass('disabled');
    } else {
      $('#controls').children('.submitEdit').prop('disabled', true).addClass('disabled');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choosenRejectReasons, showActionButtons]);

  const changePage = ({ page }) => {
    const params = { page };
    if (searchValue) params.name = searchValue;
    dispatch(
      fetchRejectReasons(params),
    );
  };

  const search = (value, page) => {
    if (value) {
      dispatch(
        fetchRejectReasons({
          page: 1,
          perPage: 30,
          name: value,
        }),
      );
    } else {
      dispatch(
        fetchRejectReasons({ page: page || 1 }),
      );
    }
    setSearchValue(value);
  };

  const chooseRejectReason = id => {
    if (choosenRejectReasons[id]) {
      dispatch(
        unchoose(id),
      );
    } else {
      dispatch(
        choose(rejectReasons.list[id]),
      );
    }
  };

  const goBack = () => {
    history.push('/tools');
  };

  let rejectReasonsArray = [];
  if (rejectReasons.total && rejectReasons.isLoading === false) {
    rejectReasonsArray = Object.keys(clearObject(rejectReasons.list))
      .map(id => rejectReasons.list[id]).reverse();
  }

  const submitRejectReason = e => {
    if (modalIsActive === 'edit') {
      dispatch(
        editRejectReason(
          editFormId,
          e.value,
          id => {
            search(searchValue, rejectReasons.page);
            toggleModal(false);
            dispatch(
              unchoose(id),
            );
          },
        ),
      );
    } else if (modalIsActive === 'create') {
      dispatch(
        createRejectReason(
          e.value,
          () => {
            search(searchValue, rejectReasons.page);
            toggleModal(false);
          },
        ),
      );
    }
  };

  const remove = () => {
    let rejectReasonIds = Object.keys(choosenRejectReasons).filter(id => choosenRejectReasons[id]);
    rejectReasonIds.forEach(id => {
      dispatch(
        removeRejectReason(id),
      );
      dispatch(
        unchoose(id),
      );
    });
    toggleRemoveModal(false);
  };

  const toggleButton = e => {
    if (e.target.value) {
      $('#rejectReasonForm .submit').prop('disabled', false).removeClass('disabled');
    } else {
      $('#rejectReasonForm .submit').prop('disabled', true).addClass('disabled');
    }
  };

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return <Box className={s.rejectReasons}>
    <Box direction='row' width='100%' justify='between' className={s.navHeader}>
      <Button className='buttonLink' onClick={goBack}>
        <FormPreviousLink />
        Tools
      </Button>
    </Box>
    <div id='controls' className={s.controls}>
      <Button type='button' primary label='Edit' className='submitEdit' onClick={() => toggleModal('edit')} />
      <Button
        type='button' primary label='Remove' className='submit' color='#ff0000'
        onClick={() => toggleRemoveModal(true)}
      />
      {removeModalIsActive &&
        <Layer
          className='modal40'
          onEsc={() => toggleRemoveModal(false)}
          onClickOutside={() => toggleRemoveModal(false)}
        >
          <Text>Are you sure?</Text>
          <Box direction='row' className='removeModalButtons'>
            <Button type='button' label='Discard' onClick={() => toggleRemoveModal(false)} />
            <Button type='button' primary label='Remove' onClick={remove} color='#ff0000' />
          </Box>
          <Close onClick={() => toggleRemoveModal(false)} />
        </Layer>}
      <Button type='button' primary label='Add' onClick={() => toggleModal('create')} />
      {modalIsActive &&
        <Layer
          className='modal60'
          onEsc={() => toggleModal(false)}
          onClickOutside={() => toggleModal(false)}
        >
          <Form id='rejectReasonForm' onSubmit={submitRejectReason}>
            <FormField htmlFor='name' label='Name'>
              <TextInput
                id='name'
                name='name'
                defaultValue={modalIsActive === 'edit' ? choosenRejectReasons[editFormId].name : null}
                onChange={toggleButton}
              />
            </FormField>
            <Box direction='row' margin={{top: 'medium'}}>
              <Button type='submit' primary disabled label='Submit' className='submit disabled' />
            </Box>
          </Form>
          <Close onClick={() => toggleModal(false)} />
        </Layer>
      }
    </div>
    <Form>
      <TextInput
        onChange={e => search(e.target.value)}
        placeholder='Type reason name here'
        value={searchValue}
      />
    </Form>
    {rejectReasons.isLoading === false ?
      !objectIsEmpty(rejectReasons.list) ?
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Text className={s.header}>Name</Text>
                </TableCell>
                <TableCell>
                  <Text className={s.header}>Created</Text>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {rejectReasonsArray.map(rejectReason =>
                rejectReason.id &&
                  <TableRow
                    key={rejectReason.id}
                    className={s.row}
                    onClick={() => chooseRejectReason(rejectReason.id)}
                  >
                    <TableCell>
                      {rejectReason.isLoading ?
                        <Loader mod={s.loader} type='icon' /> :
                        choosenRejectReasons[rejectReason.id] ? <CheckboxSelected /> : <Checkbox />}
                    </TableCell>
                    <TableCell>
                      <Text>{rejectReason.name}</Text>
                    </TableCell>
                    <TableCell size='small'>
                      <Moment format='lll'>{rejectReason.created}</Moment>
                    </TableCell>
                  </TableRow>
              )}
            </TableBody>
          </Table>

          {rejectReasons.total > 30 &&
            <Box>
              <Pagination
                className={s.pagination}
                numberItems={rejectReasons.total}
                step={30}
                numberMiddlePages={3}
                onChange={changePage}
                page={rejectReasons.page}
                alignSelf='center'
              />
            </Box>
          }
          <AuthCheck isAuthorized={true} />
        </>
      :
        <Box className={s.empty} align='center'>Nothing found</Box>
    :
      <Loader mod={s.loader} />}
  </Box>
};

export default RejectReasons;
