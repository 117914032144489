import {
  getAPIAddress, toCamelCase, handleRes, formatText, toSnakeCase, convertArrayToHashmap, handleError,
} from '../../helpers';
import * as types from '../../constants';


export const fetchSections = params => dispatch => {
  dispatch({
    type: types.FETCH_SECTIONS,
    params,
  });

  const APIAdress = getAPIAddress();
  let reqParams = {
    page: 1,
    perPage: 9000,
  };
  if (params) {
    reqParams = {
      perPage: 30,
      ...params,
    };
  }
  reqParams = toSnakeCase(reqParams);
  fetch(`${APIAdress}/qc/sections?` + new URLSearchParams(reqParams))
    .then(res => handleRes(res))
    .then(res => {
      if (res.total === undefined) throw res;

      let sections = toCamelCase(res);
      sections.list = convertArrayToHashmap(sections.list);

      dispatch({
        type: types.FETCH_SECTIONS_SUCCESS,
        sections,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_SECTIONS_ERROR,
        error: formatText(res.error),
      });
    });
};

export const createSection = (section, successCallback) => dispatch => {
  const fakeId = Math.random();

  dispatch({
    type: types.CREATE_SECTION,
    fakeId,
    section,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/qc/sections`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      toSnakeCase(section),
    ),
  })
    .then(res => handleRes(res))
    .then(res => {
      if (res.error) throw res;

      dispatch({
        type: types.CREATE_SECTION_SUCCESS,
        fakeId,
        section: {
          id: res.id,
        },
      });
      if (successCallback) successCallback(res.id);
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Section has been created!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.CREATE_SECTION_ERROR,
        fakeId,
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const editSection = (id, params, successCallback) => dispatch => {
  dispatch({
    type: types.EDIT_SECTION,
    id,
    params,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/qc/sections/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      toSnakeCase(params),
    ),
  })
    .then(res => handleRes(res))
    .then(res => {
      if (res.error) throw res;

      dispatch({
        type: types.EDIT_SECTION_SUCCESS,
        id: res.id,
      });
      if (successCallback) successCallback(res.id);
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Section has been edited!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.EDIT_SECTION_ERROR,
        id,
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const removeSection = (id, successCallback) => dispatch => {
  dispatch({
    type: types.REMOVE_SECTION,
    id,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/qc/sections/${id}`, {
    method: 'DELETE',
  })
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      dispatch({
        type: types.REMOVE_SECTION_SUCCESS,
        id,
      });
      if (successCallback) successCallback();
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Removed!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.REMOVE_SECTION_ERROR,
        id,
        error: formatText(res.error),
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const choose = section => dispatch => {
  dispatch({
    type: types.CHOOSE_SECTION,
    section,
  });
};

export const unchoose = id => dispatch => {
  dispatch({
    type: types.UNCHOOSE_SECTION,
    id,
  });
};

export const reset = () => dispatch => {
  dispatch({
    type: types.RESET_CHOSEN_SECTIONS,
  });
};
