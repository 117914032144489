import { getAPIAddress, handleRes, formatText, toCamelCase, handleError } from '../../helpers';
import * as types from '../../constants';


export const fetchMaterialTypes = () => dispatch => {
  dispatch({
    type: types.FETCH_MATERIALS_TYPES,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/materials`)
    .then(res => handleRes(res))
    .then(res => {
      if (!Array.isArray(res)) throw res;

      let materialTypes = res;
      materialTypes = materialTypes.filter((materialType) => {
        if ([1, 3].includes(materialType.id)) return materialType;
        return null;
      }).reduce((materialTypesHashMap, materialType) => {
        materialType = toCamelCase(materialType)
        materialTypesHashMap[materialType.id] = materialType;
        return materialTypesHashMap;
      }, {});

      dispatch({
        type: types.FETCH_MATERIALS_TYPES_SUCCESS,
        materialTypes,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_MATERIALS_TYPES_ERROR,
        error: formatText(res.error),
      });
    });
};