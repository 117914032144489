import { camelCase, snakeCase, last, flatten } from 'lodash';
import JSCookies from 'js-cookie';
import Fraction from 'fraction.js';


const LS_PREFIX = 'das:';

export const setCaretPosition = (input, caretPos) => {
  if (input.createTextRange) {
    const range = input.createTextRange();

    range.move('character', caretPos);
    range.select();
  } else {
    input.focus();

    setTimeout(() => {
      input.setSelectionRange(caretPos, caretPos);
    }, 0);
  }
};

export const normalizeControlledInputCursor = params => {
  let shift = 0;

  if (params.prevValue.length > params.value.length) {
    if (params.prevValue.length - params.value.length > 1) {
      shift = 1;
    }
  } else if (params.value.length - params.prevValue.length > 1) {
    shift = -1;
  }

  if (!params.valid) {
    shift = 1;
  }

  this.prototype.constructor.setCaretPosition(params.input, params.input.selectionStart - shift);
};

export const getLS = name => {
  const now = Date.now();
  const key = `${LS_PREFIX + name}`;

  const expire = localStorage.getItem(`${key}-expire`);

  if (expire && expire < now) {
    deleteLS(key);
    deleteLS(`${key}-expire`);
  } else {
    const data = localStorage.getItem(key);

    if (data) {
      if (data === '{}') return;

      // Naive object/array check
      if (
        (data.indexOf('{') >= 0 && data.indexOf('}') >= 0)
          || (data.indexOf('[') >= 0 && data.indexOf(']') >= 0)
      ) {
        return JSON.parse(data);
      }

      return data;
    }
  }
};

export const setLS = (name, value, expires) => {
  const key = `${LS_PREFIX + name}`;

  if (typeof(value) === 'object') {
    value = JSON.stringify(value);
  }

  localStorage.setItem(key, value);

  if (expires) {
    const now = Date.now();

    localStorage.setItem(
      `${key}_expire`,
      now + (expires * 1000),
    );
  }
};

export const deleteLS = name => {
  const key = `${LS_PREFIX + name}`;

  localStorage.removeItem(key);
  localStorage.removeItem(`${key}_expire`);
};

export const getCookie = name => {
  return JSCookies.get(name);
};

export const setCookie = (name, value, params) => {
  JSCookies.set(name, value, params);
};

export const deleteCookie = (name, params) => {
  JSCookies.remove(name, params);
};

export const deleteAllCookies = () => {
  document
    .cookie
    .split(';')
    .forEach(cookie => {
      deleteCookie(
        cookie.split('=')[0],
      );
    });
};

export const getEnv = () => {
  // env from Webpack
  return process.env.NODE_ENV;
};

export const isDev = () => {
  return getEnv() === 'development';
};

export const isProd = () => {
  return getEnv() === 'production';
};

export const getAPIAddress = () => {
  return '/api/v1';
};

export const toCamelCase = obj => {
  if (Array.isArray(obj)) {
    return obj.map(v => toCamelCase(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: toCamelCase(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

export const toSnakeCase = obj => {
  if (Array.isArray(obj)) {
    return obj.map(v => toSnakeCase(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [snakeCase(key)]: toSnakeCase(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

export const getQSParams = query => {
    return query ?
      (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          let [key, value] = param.split('=');
          params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
          return params;
        }, {})
      : {}
};

export const handleRes = res => {
  if (res.status === 404) {
    const error = {error: "Can't find anything"};
    throw error;
  } else if (res.status === 500) {
    const error = {error: 'Something went wrong'};
    throw error;
  } else {
    return res.json();
  }
};

export const formatText = text => {
  text = text.toLocaleLowerCase().charAt(0).toUpperCase() + text.slice(1);
  if (text.includes('Duplicate key value violates unique constraint')) {
    return 'Name already exists';
  }
  return text;
};

export const deepCopy = obj => {
  return JSON.parse(
    JSON.stringify(obj),
  );
};

export const convertArrayToHashmap = arr => {
  return arr.reduce((hashMap, obj) => {
    hashMap[obj.id] = obj;
    return hashMap;
  }, {});
};

export const convertHashmapToArray = obj => {
  return Object.keys(obj).map(key => {
    return obj[key];
  });
};

export const convertViscosityToConsistency = viscosity => {
  return Math.round((-viscosity / 30000 + 65 / 6) * 100) / 100;
};

export const convertInchesToMm = value => {
  try {
    return Math.round(
      parseFloat(new Fraction(value).toString().replace('(', '').replace('(', '')) * 25.4 * 1000000
    ) / 1000000;
  } catch (e) {
    return 0;
  }
};

export const convertMmToInches = value => {
  return new Fraction(
    Math.round(value / 25.4 * 1000000) / 1000000,
  ).toFraction(true);
};

export const isUUID = UUID => {
    if (UUID.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$')) {
      return true;
    }
    return false;
};

export const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const objectIsEmpty = obj => {
  let isEmpty = true;
  Object.keys(obj).forEach(key => {
    if (obj[key]) {
      isEmpty = false;
    }
  });
  return isEmpty;
};

export const clearArray = arr => {
  return arr.filter(item => item);
};

export const clearObject = obj => {
  const output = {};
  Object.keys(obj).forEach(key => {
    if (obj[key]) {
      output[key] = obj[key];
    }
  });
  return output;
};

export const sortByKey = (arr, key) => {
  return arr.sort((a, b) => {
    const x = a[key];
    const y = b[key];
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
};

export const formatVerificationType = (originalType, units) => {
  const type = deepCopy(originalType);
  if (type.deviationStart || type.deviationStart === 0) {
    if (units === 'Imperial') {
      type.name +=
        ` more than ${convertMmToInches(type.deviationStart)}…${convertMmToInches(type.deviationEnd)}" range`;
    } else {
      type.name += ` more than ${type.deviationStart}…${type.deviationEnd}mm range`;
    }
  }
  if (type.reference) {
    if (units === 'Imperial') {
      type.name += ` (ref.: ${convertMmToInches(type.reference)}")`
    } else {
      type.name += ` (ref.: ${type.reference}mm)`
    }
  }
  return type;
};

export const formatVerificationTypes = (types, units) => {
  return types.map(type => formatVerificationType(type, units));
};

export const transformVerificationTypes = (types, units) => {
  types = formatVerificationTypes(types, units);
  const output = [];
  let sectionId;
  types.forEach((type, index) => {
    type.position = index + 1;
    if (type.section && sectionId !== type.section.id) {
      output.push({
        id: 's_' + type.section.id,
        name: type.section.name,
        position: index + 0.5,
      });
      sectionId = type.section.id;
    }
    output.push(type);
  });
  return output;
};

export const distinctFakeId = list => {
  const lastItem = last(Object.keys(list));
  if (lastItem.includes('.')) {
    return lastItem;
  }
};

export const formatChangelog = changelog => {
  const URLS = {
    part: '/parts/:id',
    barrel: '/barrels/:id',
    part_checklist: '/parts/:id/checklists/:id',
    barrel_checklist: '/barrels/:id/checklists/:id',
    steel_frame: '/steel-frames/:id',
    steel_frame_checklist: '/steel-frames/:id/checklists/:id',
    light_calibration: '/light-calibrations/:id',
    attachment: '/api/v1/attachments/:id',
    checklist_type: '/tools/checklist-types/:id',
  };

  return changelog.map(changelogItem => {
    if (changelogItem.nameIdTypes) {
      const types = changelogItem.nameIdTypes.split(', ');
      changelogItem.nameData = [];
      // eslint-disable-next-line no-useless-escape
      const changeItemSplit = changelogItem.name.split(/[<\>]/);
      let substringIsLink = false;
      let linksCounter = -1;
      changeItemSplit.forEach(substring => {
        let type, text, url;
        if (substringIsLink) {
          type = 'link';
          const substringSplit = substring.split(', ');
          text = last(substringSplit);
          if (substringSplit.length > 1) substringSplit.pop();
          url = URLS[types[linksCounter]];
          substringSplit.forEach(id => {
            url = url.replace(':id', id);
          });
        } else {
          type = 'text';
          text = substring;
        }
        changelogItem.nameData.push({
          type, text, url,
        });
        if (substring.slice(-2) === ': ' ) {
          substringIsLink = true;
          linksCounter++;
        } else {
          substringIsLink = false;
        }
      });
    }
    return changelogItem;
  });
};

export const handleError = res => {
  if (res.error && res.error === 'Please update your client') window.location.href = '/update';
};

export const getPartName = part => {
  return part.name !== 'Unknown part' ? part.name :
    part.typeName ? part.typeName :
    part.type ? part.type.name :
    part.uuid.split('-')[0];
};

export const sortChecklists = (checklists, stages) => {
  if (!checklists || objectIsEmpty(checklists)) return [];

  let sortedChecklists = [];
  const checklistsTail = [];
  Object.keys(checklists).forEach(key => {
    let name = checklists[key] && (checklists[key].name ||
      (checklists[key].type && checklists[key].type.name) ||
      (checklists[key].stage && checklists[key].stage.name));
    if (!name) name = '';
    name = name.toLowerCase();
    let relevantStage;
    Object.keys(stages).forEach(stageKey => {
      if (name.includes(stages[stageKey].searchName) && stages[stageKey].order) {
        if (relevantStage) {
          if (stages[stageKey].order < relevantStage[0]) {
            relevantStage = [stages[stageKey].order, checklists[key]];
          }
        } else {
          relevantStage = [stages[stageKey].order, checklists[key]];
        }
      }
    });
    if (relevantStage) {
      if (sortedChecklists[relevantStage[0]]) {
        sortedChecklists[relevantStage[0]].push(relevantStage[1]);
      } else {
        sortedChecklists[relevantStage[0]] = [relevantStage[1]];
      }
    } else {
      checklistsTail.push(checklists[key]);
    }
  });
  sortedChecklists = flatten(sortedChecklists);
  sortedChecklists = sortedChecklists.concat(checklistsTail);
  return sortedChecklists.filter(checklist => checklist);
};
