import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UserManagementComponent from '../../components/user-management';
import { fetchProfile } from '../profile/actions';
import { fetchUserRoles } from '../../components/user-management/actions';
import { fetchLocations } from '../../components/locations/actions';

import s from './index.module.scss';


const UserManagement = () => {
  const profile = useSelector(state => state.profile);
  const userRoles = useSelector(state => state.userRoles);
  const locations = useSelector(state => state.locations);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProfile());
    dispatch(fetchUserRoles());
    dispatch(fetchLocations());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.userManagement}>
      {profile.name && userRoles.list && locations.list &&
        <UserManagementComponent />}
    </div>
  );
};

export default UserManagement;
