import {
  toSnakeCase, isUUID,
} from '../../helpers';


export const makeFilterParamsString = (reqParams, filters, stages, locations, devices, types) => {
  const params = reqParams || {};
  if (filters) {
    if (filters.started) params.started = filters.started;
    if (filters.ended) params.ended = filters.ended;
    if (filters.datesByStage) params.datesByStageId = Object.keys(stages.list).filter(key => {
      if (stages.list[key].name === filters.datesByStage) return key;
      return null;
    });
    if (filters.search) {
      if (isUUID(filters.search)) {
        params.uuid = filters.search;
      } else {
        params.name = filters.search;
      }
    }
    if (filters.hasRfid) params.hasRfid = filters.hasRfid;
    if (filters.QCConditions) {
      params.QCConditions = filters.QCConditions;
    }
    if (locations.list && filters.location) {
      Object.keys(locations.list).forEach(key => {
        if (filters.location === locations.list[key].name) {
          params.locationId = locations.list[key].id;
        }
      });
    }
    if (devices.list && filters.device) {
      Object.keys(devices.list).forEach(key => {
        if (filters.device === devices.list[key].name) {
          params.deviceId = devices.list[key].id;
        }
      });
    }
    if (types.list && filters.types) {
      params.typeIds = Object.keys(types.list).filter(key => {
        if (filters.types.includes(types.list[key].name)) return key;
        return null;
      });
    }
    if (stages.list && filters.stages) {
      params.stageIds = Object.keys(stages.list).filter(key => {
        if (filters.stages.includes(stages.list[key].name)) return key;
        return null;
      });
    }
  }
  return new URLSearchParams(toSnakeCase(params));
};
