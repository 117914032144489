import * as types from '../../constants';


export const rejectReasons = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_REJECT_REASONS:
      return {
        ...state,
        ...action.params,
        isLoading: true,
      };

    case types.FETCH_REJECT_REASONS_SUCCESS:
      return {
        ...state,
        ...action.rejectReasons,
        isLoading: false,
      };

    case types.FETCH_REJECT_REASONS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case types.CREATE_REJECT_REASON:
      return {
        ...state,
        list: {
          ...state.list,
          [action.fakeId]: {
            ...action.rejectReason,
            isLoading: true,
          },
        },
      };

    case types.CREATE_REJECT_REASON_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.fakeId]: null,
          [action.rejectReason.id]: {
            ...state.list[action.fakeId],
            ...action.rejectReason,
            isLoading: false,
          },
        },
      };

    case types.CREATE_REJECT_REASON_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.fakeId]: null,
        },
      };

    case types.EDIT_REJECT_REASON:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...action.params,
            isLoading: true,
          },
        },
      };

    case types.EDIT_REJECT_REASON_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
          },
        },
      };

    case types.EDIT_REJECT_REASON_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
            error: action.error,
          },
        },
      };

    case types.REMOVE_REJECT_REASON:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: true,
          },
        },
      };

    case types.REMOVE_REJECT_REASON_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: null,
        },
      };

    case types.REMOVE_REJECT_REASON_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
            error: action.error,
          },
        },
      };

    default:
      return state;
  }
};

export const choosenRejectReasons = (state = {}, action) => {
  switch (action.type) {
    case types.CHOOSE_REJECT_REASON:
      return {
        ...state,
        [action.rejectReason.id]: action.rejectReason,
      };

    case types.UNCHOOSE_REJECT_REASON:
      return {
        ...state,
        [action.id]: null,
      };

    case types.RESET_CHOSEN_REJECT_REASONS:
      return {};

    default:
      return state;
  }
};
