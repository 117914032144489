import * as types from '../../constants';


export const users = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_USERS:
      return {
        ...state,
        ...action.params,
        isLoading: true,
      };

    case types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        ...action.users,
        isLoading: false,
      };

    case types.FETCH_USERS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case types.CREATE_USER:
      return {
        ...state,
        list: {
          ...state.list,
          [action.fakeId]: {
            ...action.user,
            isLoading: true,
          },
        },
      };

    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.fakeId]: null,
          [action.user.id]: {
            ...state.list[action.fakeId],
            ...action.user,
            isLoading: false,
          },
        },
      };

    case types.CREATE_USER_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.fakeId]: null,
        },
      };

    case types.EDIT_USER:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...action.params,
            isLoading: true,
          },
        },
      };

    case types.EDIT_USER_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
          },
        },
      };

    case types.EDIT_USER_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
            error: action.error,
          },
        },
      };

    case types.REMOVE_USER:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: true,
          },
        },
      };

    case types.REMOVE_USER_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: null,
        },
      };

    case types.REMOVE_USER_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
            error: action.error,
          },
        },
      };

    default:
      return state;
  }
};

export const choosenUsers = (state = {}, action) => {
  switch (action.type) {
    case types.CHOOSE_USER:
      return {
        ...state,
        [action.user.id]: action.user,
      };

    case types.UNCHOOSE_USER:
      return {
        ...state,
        [action.id]: null,
      };

    case types.RESET_CHOSEN_USERS:
      return {};

    default:
      return state;
  }
};

export const userRoles = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_USER_ROLES:
      return {
        ...state,
        ...action.params,
        isLoading: true,
      };

    case types.FETCH_USER_ROLES_SUCCESS:
      return {
        ...state,
        ...action.userRoles,
        isLoading: false,
      };

    case types.FETCH_USER_ROLES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
