import {
  getAPIAddress, toCamelCase, toSnakeCase, handleRes, formatText, convertArrayToHashmap, handleError,
} from '../../helpers';

import * as types from '../../constants';


export const fetchLightCalibrations = params => dispatch => {
  dispatch({
    type: types.FETCH_LIGHT_CALIBRATIONS,
    params,
  });

  const APIAdress = getAPIAddress();
  let reqParams = {
    page: 1,
    perPage: 9000,
  };
  if (params) {
    reqParams = {
      page: params.page,
      perPage: 20,
    };
  }
  reqParams = toSnakeCase(reqParams);
  fetch(`${APIAdress}/light_calibrations?` + new URLSearchParams(reqParams))
    .then(res => handleRes(res))
    .then(res => {
      if (res.total === undefined) throw res;

      let lightCalibrations = toCamelCase(res);
      lightCalibrations.list = convertArrayToHashmap(lightCalibrations.list);

      dispatch({
        type: types.FETCH_LIGHT_CALIBRATIONS_SUCCESS,
        lightCalibrations,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_LIGHT_CALIBRATIONS_ERROR,
        error: formatText(res.error),
      });
    });
};
