import { getAPIAddress, handleRes, formatText, handleError } from '../../helpers';
import * as types from '../../constants';


export const findBarrelBySerial = (serial, successCallback) => (dispatch) => {
  serial = serial.replace('/', '.');
  if (serial.length > 100) {
    dispatch({
      type: types.SHOW_NOTIFICATION,
      params: {
        type: 'error',
        text: 'Serial is too long',
      },
    });
    return;
  }

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/barrels?` + new URLSearchParams({
    serial,
    page: 1,
    per_page: 1,
  }))
    .then(res => handleRes(res))
    .then(res => {
      if (res.total === undefined) throw res;

      successCallback(res.list[0].id)
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });;
};
