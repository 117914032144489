import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useHistory } from 'react-router-dom'
import {
  Box, Table, TableBody, TableCell, TableHeader, TableRow, Text, Form, TextInput, Select,
} from 'grommet';
import {
  Logout, Video,
} from 'grommet-icons';
import Moment from 'react-moment';
import $ from 'jquery';

import { sortChecklists, objectIsEmpty } from '../../helpers';
import Loader from '../loader';
import AuthCheck from '../auth-check';
import Header from '../header';
import QC from '../qc';
import Attachments from '../attachments';
import Media from '../media';
import Timeline from '../timeline';
import { fetchPart } from './actions';
import { fetchStages } from '../stages/actions';
import { fetchChecklistResolutions } from '../checklist/actions';
import { fetchChecklistTypes } from '../checklist-types/actions';
import { findPartByRfid } from '../../pages/parts/actions';

import s from './index.module.scss';


const Part = () => {
  const parts = useSelector(state => state.parts);
  const stages = useSelector(state => state.stages);
  const checklistResolutions = useSelector(state => state.checklistResolutions);
  const checklistTypes = useSelector(state => state.checklistTypes);
  const profile = useSelector(state => state.profile);
  const { partId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      fetchPart(partId),
    );
    dispatch(fetchStages());
    dispatch(fetchChecklistResolutions());
    dispatch(fetchChecklistTypes());

    let prevRfidValue;
    const interval = setInterval(() => {
      const value = $('#rfid').val();
      if (value && value !== prevRfidValue) {
        dispatch(
          findPartByRfid(value, goToPart),
        );
      }
      prevRfidValue = value;
    }, 250);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partId]);

  const goToPart = part => {
    history.push(`/parts/${part.id}`);
  };

  let part;
  let sortedChecklists;
  let activeStages;
  let detailedPrintingDataLink;
  if (parts.list && parts.list[partId] && stages.isLoading === false) {
    part = parts.list[partId];
    if (part.qcChecklists && !objectIsEmpty(part.qcChecklists)) {
      sortedChecklists = sortChecklists(part.qcChecklists, stages.list);
    }
    activeStages = {
      'General': [[part.started, part.ended]],
      'Printing': null,
    };
    if (part.timings) {
      part.timings.forEach((timing, index) => {
        if (!activeStages[timing.stageTo.name]) activeStages[timing.stageTo.name] = [];
        activeStages[timing.stageTo.name].push([timing.started, timing.ended]);
      });
    }
    // For legacy panels
    if (!activeStages['Printing']) {
      activeStages['Printing'] = [[part.started, part.ended]];
    }
    if (part.location && part.location.name === 'Monterrey') {
      const from = new Date(activeStages['Printing'][0][0]).getTime() - 600000;
      const to = (activeStages['Printing'][0][1] && (new Date(activeStages['Printing'][0][1]).getTime() + 600000)) ||
        Date.now() + 600000;
      detailedPrintingDataLink = 'http://sensors.mightybuildings.com/d/zq0QMTvVk/dashboard-for-das?orgId=1&' +
        `from=${from}&to=${to}&var-device_id=${part.device.id}`;
    }
  }

  const showStage = e => {
    $(`.${s.tabs} > div > div`).addClass('hidden');
    $(`#${e.target.value.replace(/ /g, '')}`).removeClass('hidden');
  };

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return part && part.isLoading === false && stages.isLoading === false && checklistResolutions.isLoading === false
      && checklistTypes.isLoading === false ?
    part && !part.error ?
      <Box className={s.part}>
        <Header type='part' entity={part} />
        <Box margin={{left: '13px', right: '20px'}}>
          <Box margin={{left: '10px', bottom: '10px', top: '5px'}}>
            <Form>
              <TextInput id='rfid' name='rfid' placeholder="Scan part's rfid here" />
            </Form>
          </Box>
          <Box className={s.blockWithSep}>
            <Select id='type' onChange={showStage} options={Object.keys(activeStages)} defaultValue='General' />
            <Box className={s.tabs}>
              {Object.keys(activeStages).map((name, index) =>
                <Box key={index}>
                  {name === 'General' ?
                    <Box id={name.replace(/ /g, '')} className={s.infoBlock}>
                      <Text margin='small' weight='bold'>Basic info:</Text>
                      <Table align='start'>
                        <TableBody>
                          {part.name !== 'Unknown part' ?
                            <TableRow>
                              <TableCell className={s.name}>Name:</TableCell>
                              <TableCell>{part.name}</TableCell>
                            </TableRow>
                          : null}
                          {part.type ?
                            <TableRow>
                              <TableCell className={s.name}>Type:</TableCell>
                              <TableCell>{part.type.name}</TableCell>
                            </TableRow>
                          : null}
                          <TableRow>
                            <TableCell className={s.name}>Time:</TableCell>
                            <TableCell>
                              <Moment format='lll'>{part.started}</Moment>
                              <span> — </span>
                              {part.ended ? <Moment format='lll'>{part.ended}</Moment> : 'In progress'}
                            </TableCell>
                          </TableRow>
                          {(part.ended && part.started !== part.ended) ?
                            <TableRow>
                              <TableCell className={s.name}>Duration:</TableCell>
                              <TableCell>
                                <Moment duration={part.started} trim={true} date={part.ended}></Moment>
                              </TableCell>
                            </TableRow>
                          : null}
                          {part.stage ?
                            <TableRow>
                              <TableCell className={s.name}>Work center:</TableCell>
                              <TableCell>{part.stage ? part.stage.name : '—'}</TableCell>
                            </TableRow>
                          : null}
                          {part.description ?
                            <TableRow>
                              <TableCell className={s.name}>Description:</TableCell>
                              <TableCell>{part.description}</TableCell>
                            </TableRow>
                          : null}
                          {part.location ?
                            <TableRow>
                              <TableCell className={s.name}>Location:</TableCell>
                              <TableCell>{part.location.name}</TableCell>
                            </TableRow>
                          : null}
                          {part.manufacturingOrder ?
                            <TableRow>
                              <TableCell className={s.name}>Manufacturing order:</TableCell>
                              <TableCell>
                                <Link
                                  to={{ pathname: part.manufacturingOrder.link }}
                                  target='_blank'
                                  className={`link ${s.odoo}`}
                                >
                                  <Logout />
                                  {part.manufacturingOrder.name}
                                </Link>
                              </TableCell>
                            </TableRow>
                          : null}
                          {part.steelFrame ?
                            <TableRow>
                              <TableCell className={s.name}>Steel frame:</TableCell>
                              <TableCell>
                                <Link to={`/steel-frames/${part.steelFrame.name}`} className='link'>
                                  {part.steelFrame.name}
                                </Link>
                              </TableCell>
                            </TableRow>
                          : null}
                          {part.material ?
                            <TableRow>
                              <TableCell className={s.name}>Material:</TableCell>
                              <TableCell>{part.material.name}</TableCell>
                            </TableRow>
                          : null}
                          <TableRow>
                            <TableCell className={s.name}>UUID:</TableCell>
                            <TableCell>{part.uuid}</TableCell>
                          </TableRow>
                          {part.rfid ?
                            <TableRow>
                              <TableCell className={s.name}>RFID:</TableCell>
                              <TableCell>{part.rfid}</TableCell>
                            </TableRow>
                          : null}
                          {part.insignia ?
                            <TableRow>
                              <TableCell className={s.name}>Insignia:</TableCell>
                              <TableCell>{part.insignia}</TableCell>
                            </TableRow>
                          : null}
                          {(part.dimensions && part.dimensions.join('') !== '000') ?
                            <TableRow>
                              <TableCell className={s.name}>Dimensions (L×W×H):</TableCell>
                              <TableCell>{part.dimensions.join('×')}</TableCell>
                            </TableRow>
                          : null}
                          {(part.changedUser && part.changedUser.id !== 12) ? // Show nothing if it was edit by Mighty Bot
                            <TableRow>
                              <TableCell className={s.name}>Last changed by:</TableCell>
                              <TableCell>{part.changedUser.name}</TableCell>
                            </TableRow>
                          : null}
                        </TableBody>
                      </Table>
                    </Box> :
                  name === 'Printing' ?
                    <Box id={name.replace(/ /g, '')} className='hidden'>
                      <Box className={s.infoBlock}>
                        <Text margin='small' weight='bold'>Basic info:</Text>
                        <Table align='start'>
                          <TableBody>
                            {part.device ?
                              <TableRow>
                                <TableCell className={s.name}>Device:</TableCell>
                                <TableCell>{part.device.name}</TableCell>
                              </TableRow>
                            : null}
                            {part.gCode ?
                              <TableRow>
                                <TableCell className={s.name}>G-code:</TableCell>
                                <TableCell>{part.gCode}</TableCell>
                              </TableRow>
                            : null}
                            {part.gCodeComment ?
                              <TableRow>
                                <TableCell className={s.name}>G-code comment:</TableCell>
                                <TableCell>{part.gCodeComment.name}</TableCell>
                              </TableRow>
                            : null}
                            {activeStages[name].map((timing, index) => {
                              return [
                                <TableRow key={index}>
                                  <TableCell className={s.name}>Time:</TableCell>
                                  <TableCell>
                                    <Moment format='lll'>{timing[0]}</Moment>
                                    <span> — </span>
                                    {timing[1] ? <Moment format='lll'>{timing[1]}</Moment> : 'In progress'}
                                  </TableCell>
                                </TableRow>,
                                <TableRow key={index + 100}>
                                  <TableCell className={s.name}>Duration:</TableCell>
                                  <TableCell>
                                    <Moment duration={timing[0]} trim={true} date={timing[1]}></Moment>
                                  </TableCell>
                                </TableRow>]})}
                            {part.fiberUsage ?
                              <TableRow>
                                <TableCell className={s.name}>Fiber consumed (m):</TableCell>
                                <TableCell>{part.fiberUsage}</TableCell>
                              </TableRow>
                            : null}
                            {part.materialUsage ?
                              <TableRow>
                                <TableCell className={s.name}>Material consumed (kg):</TableCell>
                                <TableCell>{part.materialUsage}</TableCell>
                              </TableRow>
                            : null}
                            {part.impregnationUsage ?
                              <TableRow>
                                <TableCell className={s.name}>Impregnation consumed&nbsp;(kg):</TableCell>
                                <TableCell>{part.impregnationUsage}</TableCell>
                              </TableRow>
                            : null}
                            {detailedPrintingDataLink ?
                              <TableRow>
                                <TableCell className={s.name}>Detailed sensors data:</TableCell>
                                <TableCell>
                                  <Link
                                    to={{ pathname: detailedPrintingDataLink }}
                                    target='_blank'
                                    className={`link ${s.odoo}`}
                                  >
                                    <Logout />
                                    Grafana dashboard
                                  </Link>
                                </TableCell>
                              </TableRow> : null}
                            {part.hasThermo ?
                              <TableRow>
                                <TableCell>
                                  <Link to={`/parts/${partId}/thermo`} className={`link ${s.odoo}`}>
                                    <Video />
                                    Thermography
                                  </Link>
                                </TableCell>
                              </TableRow>
                            : null}
                          </TableBody>
                        </Table>
                      </Box>
                      {part.printingProcess && part.printingProcess.length ?
                        <Box className={s.infoBlock}>
                          <Text margin='small' weight='bold'>Measurments:</Text>
                          <Table align='start'>
                            <TableHeader>
                              <TableRow>
                                <TableCell>Parameter</TableCell>
                                <TableCell>Units</TableCell>
                                <TableCell>Min</TableCell>
                                <TableCell>Average</TableCell>
                                <TableCell>Max</TableCell>
                                <TableCell>Tolerance</TableCell>
                                <TableCell>Approved</TableCell>
                              </TableRow>
                            </TableHeader>
                            <TableBody>
                              {part.printingProcess.map((measurement, index) =>
                                <TableRow key={index}>
                                  <TableCell>{measurement.name}</TableCell>
                                  <TableCell>{measurement.units}</TableCell>
                                  <TableCell>{measurement.min}</TableCell>
                                  <TableCell>{measurement.avg}</TableCell>
                                  <TableCell>{measurement.max}</TableCell>
                                  <TableCell>{measurement.tolerance}</TableCell>
                                  <TableCell>
                                    <Text className={measurement.approved === true
                                        ? s.checkOk : measurement.approved === false
                                        ? s.check : null}>
                                      {measurement.approved ? 'OK' : 'NG'}
                                    </Text>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </Box> : null}
                      {part.printingEvents && part.printingEvents.length ?
                        <Box className={s.infoBlock}>
                          <Text margin='small' weight='bold'>Events:</Text>
                          <Table align='start'>
                            <TableBody>
                              {part.printingEvents.map((event, index) =>
                                <TableRow key={index}>
                                  <TableCell className={s.time}>
                                    <Moment format='LTS'>{event.time}</Moment>
                                  </TableCell>
                                  <TableCell>
                                    <Text className={
                                      event.approved === true ? s.checkOk : event.approved === false ? s.check : null
                                    }>
                                      {event.name}
                                    </Text>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </Box> : null}
                      {part.lightCalibration &&
                        <>
                          <Box className={s.infoBlock}>
                            <Text margin='small' weight='bold'>Curing lamps characteristics:</Text>
                            <Table align='start'>
                              <TableBody>
                                <TableRow>
                                  <TableCell>Total optical power, W:</TableCell>
                                  <TableCell>{part.lightCalibration.power}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell className={s.name}>Light spot diameter, mm:</TableCell>
                                  <TableCell>{part.lightCalibration.size.join('…')}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell className={s.name}>
                                    <Link to={`/light-calibrations/${part.lightCalibration.id}`} className='link'>
                                      Full report
                                    </Link>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                        </>}
                    </Box>
                  :
                    <Box id={name.replace(/ /g, '')} className={`${s.infoBlock} hidden`}>
                      <Text margin='small' weight='bold'>Basic info:</Text>
                      <Table align='start'>
                        <TableBody>
                          {activeStages[name].map((timing, index) => {
                            return ['Scrapped', 'Shipped', 'Failure of print', 'Test/sample'].includes(name) ?
                              <TableRow key={index}>
                                <TableCell className={s.name}>Time:</TableCell>
                                <TableCell>
                                  <Moment format='lll'>{timing[0]}</Moment>
                                </TableCell>
                              </TableRow> :
                              [<TableRow key={index}>
                                <TableCell className={s.name}>Time:</TableCell>
                                <TableCell>
                                  <Moment format='lll'>{timing[0]}</Moment>
                                  <span> — </span>
                                  {timing[1] ? <Moment format='lll'>{timing[1]}</Moment> : 'In progress'}
                                </TableCell>
                              </TableRow>,
                              <TableRow key={index + 100}>
                                <TableCell className={s.name}>Duration:</TableCell>
                                <TableCell>
                                  <Moment duration={timing[0]} trim={true} date={timing[1]}>
                                  </Moment>
                                </TableCell>
                              </TableRow>]})}
                        </TableBody>
                      </Table>
                    </Box>}
                </Box>)}
            </Box>
          </Box>
          <Timeline part={part} />
          {(Object.keys(part.barrels).length !== 0 || part.unidentifiedBarrels) &&
            <Box className={s.blockWithSep}>
              <Text margin={{top: 'medium', left: 'small', right: 'small', bottom: 'small'}} weight='bold'>
                Materials:
              </Text>
              <Table margin={{bottom: 'small'}}>
                <TableBody>
                  {Object.keys(part.barrels).map(id =>
                    <TableRow key={id}>
                      <TableCell>
                        <Link to={`/barrels/${id}`} className='link'>
                          {part.barrels[id].serial}
                        </Link>
                      </TableCell>
                    </TableRow>
                  )}
                  {part.unidentifiedBarrels && part.unidentifiedBarrels.split(',').map((name, index) =>
                    <TableRow key={index}>
                      <TableCell>{name}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>}
          <QC
            entity={part} checklists={sortedChecklists} stages={stages}
            checklistTypes={checklistTypes} profile={profile}
          />
          <Media media={part.media} />
          <Attachments attachments={part.attachments} />
        </Box>
        <AuthCheck isAuthorized={true} />
      </Box>
    :
      <Box className={s.part} align='center'>Nothing found</Box>
  :
    <Loader />
};

export default Part;
