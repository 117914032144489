import {
  getAPIAddress, toCamelCase, handleRes, formatText, toSnakeCase, convertArrayToHashmap, handleError,
} from '../../helpers';
import * as types from '../../constants';


export const fetchUsers = params => dispatch => {
  dispatch({
    type: types.FETCH_USERS,
    params,
  });

  const APIAdress = getAPIAddress();
  let reqParams = {
    page: 1,
    perPage: 9000,
  };
  if (params) {
    reqParams = {
      perPage: 30,
      ...params,
    };
  }
  reqParams = toSnakeCase(reqParams);
  fetch(`${APIAdress}/auth/users?` + new URLSearchParams(reqParams))
    .then(res => handleRes(res))
    .then(res => {
      if (res.total === undefined) throw res;

      let users = toCamelCase(res);
      users.list = convertArrayToHashmap(users.list);

      dispatch({
        type: types.FETCH_USERS_SUCCESS,
        users,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_USERS_ERROR,
        error: formatText(res.error),
      });
    });
};

export const createUser = (params, locations, roles, successCallback) => dispatch => {
  const fakeId = Math.random();

  dispatch({
    type: types.CREATE_USER,
    fakeId,
    params,
  });

  Object.keys(params).forEach(key => {
    if (key === 'location') {
      params.locationId = parseInt(
        Object.keys(locations).find(findKey => {
          return params[key] === locations[findKey].name;
        }),
      );
    } else if (key === 'role') {
      params.roleId = parseInt(
        Object.keys(roles).find(findKey => {
          return params[key] === roles[findKey].name;
        }),
      );
    }
  });
  delete params.location;
  delete params.role;

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/auth/users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      toSnakeCase(params),
    ),
  })
    .then(res => handleRes(res))
    .then(res => {
      if (res.error) throw res;

      dispatch({
        type: types.CREATE_USER_SUCCESS,
        fakeId,
        user: {
          id: res.id,
        },
      });
      if (successCallback) successCallback(res.id);
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'User has been created!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.CREATE_USER_ERROR,
        fakeId,
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const editUser = (id, params, originalUser, locations, roles, successCallback) => dispatch => {
  dispatch({
    type: types.EDIT_USER,
    id,
    params,
  });

  Object.keys(params).forEach(key => {
    if (key === 'location') {
      if (params[key] === 'All') {
        params.locationId = null;
      } else {
        params.locationId = parseInt(
          Object.keys(locations).find(findKey => {
            return params[key] === locations[findKey].name;
          }),
        );
      }
    } else if (key === 'role') {
      params.roleId = parseInt(
        Object.keys(roles).find(findKey => {
          return params[key] === roles[findKey].name;
        }),
      );
    }
  });
  if (params.password === '•••••') delete params.password;
  delete params.location;
  delete params.role;

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/auth/users/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      toSnakeCase(params),
    ),
  })
    .then(res => handleRes(res))
    .then(res => {
      if (res.error) throw res;

      dispatch({
        type: types.EDIT_USER_SUCCESS,
        id: res.id,
      });
      if (successCallback) successCallback(res.id);
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'User has been edited!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.EDIT_USER_ERROR,
        id,
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const removeUser = (id, successCallback) => dispatch => {
  dispatch({
    type: types.REMOVE_USER,
    id,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/auth/users/${id}`, {
    method: 'DELETE',
  })
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      dispatch({
        type: types.REMOVE_USER_SUCCESS,
        id,
      });
      if (successCallback) successCallback();
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Removed!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.REMOVE_USER_ERROR,
        id,
        error: formatText(res.error),
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const choose = user => dispatch => {
  dispatch({
    type: types.CHOOSE_USER,
    user,
  });
};

export const unchoose = id => dispatch => {
  dispatch({
    type: types.UNCHOOSE_USER,
    id,
  });
};

export const reset = () => dispatch => {
  dispatch({
    type: types.RESET_CHOSEN_USERS,
  });
};

export const fetchUserRoles = params => dispatch => {
  dispatch({
    type: types.FETCH_USER_ROLES,
    params,
  });

  const APIAdress = getAPIAddress();
  let reqParams = {
    page: 1,
    perPage: 9000,
  };
  if (params) {
    reqParams = {
      perPage: 30,
      ...params,
    };
  }
  reqParams = toSnakeCase(reqParams);
  fetch(`${APIAdress}/auth/user_roles?` + new URLSearchParams(reqParams))
    .then(res => handleRes(res))
    .then(res => {
      if (res.total === undefined) throw res;

      let userRoles = toCamelCase(res);
      userRoles.list = convertArrayToHashmap(userRoles.list);

      dispatch({
        type: types.FETCH_USER_ROLES_SUCCESS,
        userRoles,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_USER_ROLES_ERROR,
        error: formatText(res.error),
      });
    });
};
