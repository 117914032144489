import {
  getAPIAddress, toCamelCase, toSnakeCase, handleRes, formatText, convertArrayToHashmap, formatChangelog, handleError,
} from '../../helpers';
import * as types from '../../constants';


export const fetchChangelog = params => dispatch => {
  dispatch({
    type: types.FETCH_CHANGELOG,
    params,
  });

  const APIAdress = getAPIAddress();
  let reqParams = {
    page: params.page,
    perPage: 50,
  };
  if (params.partId) reqParams.partId = params.partId;
  reqParams = toSnakeCase(reqParams);

  fetch(`${APIAdress}/changelog?` + new URLSearchParams(reqParams))
    .then(res => handleRes(res))
    .then(res => {
      if (res.total === undefined) throw res;

      let changelog = toCamelCase(res);
      changelog.list = convertArrayToHashmap(formatChangelog(changelog.list));

      dispatch({
        type: types.FETCH_CHANGELOG_SUCCESS,
        changelog,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_CHANGELOG_ERROR,
        error: formatText(res.error),
      });
    });
};
