import * as types from '../../constants';


const checklistTypes = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_CHECKLIST_TYPES:
      return {
        ...state,
        ...action.params,
        isLoading: true,
      };

    case types.FETCH_CHECKLIST_TYPES_SUCCESS:
      return {
        ...state,
        ...action.checklistTypes,
        isLoading: false,
        error: false,
      };

    case types.FETCH_CHECKLIST_TYPES_ERROR:
      return {
        ...state,
        total: 0,
        list: [],
        isLoading: false,
        error: action.error,
      };

    case types.FETCH_CHECKLIST_TYPE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            isLoading: true,
          },
        },
      };

    case types.FETCH_CHECKLIST_TYPE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...action.checklistType,
            isLoading: false,
            error: false,
          },
        },
      };

    case types.FETCH_CHECKLIST_TYPE_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            isLoading: false,
            error: action.error,
          },
        },
      };

    case types.ADD_VERIFICATION_TYPES:
      return {
        ...state,
        list: {
          ...state.list,
          [action.checklistTypeId]: {
            ...state.list[action.checklistTypeId],
            verificationTypes: {
              ...state.list[action.checklistTypeId].verificationTypes,
              ...action.verifications,
            },
          },
        },
      };

    case types.DELETE_VERIFICATION_TYPE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.checklistTypeId]: {
            ...state.list[action.checklistTypeId],
            verificationTypes: {
              ...state.list[action.checklistTypeId].verificationTypes,
              [action.id]: null,
            },
          },
        },
      };

    case types.ADD_VERIFICATION_TYPE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.checklistTypeId]: {
            ...state.list[action.checklistTypeId],
            verificationTypes: {
              ...state.list[action.checklistTypeId].verificationTypes,
              [action.fakeId]: {
                ...action.verification,
                isLoading: true,
              },
            },
          },
        },
      };

    case types.ADD_VERIFICATION_TYPE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.checklistTypeId]: {
            ...state.list[action.checklistTypeId],
            verificationTypes: {
              ...state.list[action.checklistTypeId].verificationTypes,
              [action.fakeId]: null,
              [action.verification.id]: {
                ...state.list[action.checklistTypeId].verificationTypes[action.fakeId],
                ...action.verification,
                isLoading: false,
              },
            },
          },
        },
      };

    case types.ADD_VERIFICATION_TYPE_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.checklistTypeId]: {
            ...state.list[action.checklistTypeId],
            verificationTypes: {
              ...state.list[action.checklistTypeId].verificationTypes,
              [action.fakeId]: null,
            },
          },
        },
      };

    case types.CREATE_CHECKLIST_TYPE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.fakeId]: {
            ...action.checklistType,
            isLoading: true,
          },
        },
      };

    case types.CREATE_CHECKLIST_TYPE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.fakeId]: null,
          [action.checklistType.id]: {
            ...state.list[action.fakeId],
            ...action.checklistType,
            isLoading: false,
            error: false,
          },
        },
      };

    case types.CREATE_CHECKLIST_TYPE_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.fakeId]: {
            ...state.list[action.fakeId],
            isLoading: false,
          },
        },
      };

    case types.EDIT_CHECKLIST_TYPE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: true,
          },
        },
      };

    case types.EDIT_CHECKLIST_TYPE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.checklistType.id]: {
            ...action.checklistType,
            isLoading: false,
            error: false,
          },
        },
      };

    case types.EDIT_CHECKLIST_TYPE_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
          },
        },
      };

    case types.REMOVE_CHECKLIST_TYPE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: true,
          },
        },
      };

    case types.REMOVE_CHECKLIST_TYPE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: null,
        },
      };

    case types.REMOVE_CHECKLIST_TYPE_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
            error: action.error,
          },
        },
      };

    case types.SAVE_CHECKLIST_TYPE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            ...action.params,
          },
        },
      };

    case types.UPLOAD_CHECKLIST_TYPE_ATTACHMENTS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            attachments: {
               ...state.list[action.id].attachments,
              [action.fakeAttachmentId]: {
                ...action.params,
                isLoading: true,
              },
            }
          },
        },
      };

    case types.UPLOAD_CHECKLIST_TYPE_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            attachments: {
              ...state.list[action.id].attachments,
              [action.attachmentId]: {
                ...state.list[action.id].attachments[action.fakeAttachmentId],
                id: action.attachmentId,
                isLoading: false,
              },
              [action.fakeAttachmentId]: null,
            },
          },
        },
      };

    case types.UPLOAD_CHECKLIST_TYPE_ATTACHMENTS_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            attachments: {
              ...state.list[action.id].attachments,
              [action.fakeAttachmentId]: null,
            },
          },
        },
      };

    case types.REMOVE_CHECKLIST_TYPE_ATTACHMENTS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            attachments: {
              ...state.list[action.id].attachments,
              [action.attachmentId]: {
                ...state.list[action.id].attachments[action.attachmentId],
                isLoading: true,
              },
            },
          },
        },
      };

    case types.REMOVE_CHECKLIST_TYPE_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            attachments: {
              ...state.list[action.id].attachments,
              [action.attachmentId]: null,
            },
          },
        },
      };

    case types.CONVERT_VERIFICATION_TYPES:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            verificationTypes: {
              ...state.list[action.id].verificationTypes,
              ...action.verificationTypes,
            },
          },
        },
      };

    default:
      return state;
  }
};

export default checklistTypes;
