import * as types from '../../constants';


const materialTypes = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_MATERIALS_TYPES:
      return {
        ...state,
        isLoading: true,
      };

    case types.FETCH_MATERIALS_TYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.materialTypes,
      };

    case types.FETCH_MATERIALS_TYPES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default materialTypes;
