import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import {
  FileInput, Text, Box, Menu,
} from 'grommet';
import {
  MoreVertical,
} from 'grommet-icons';

import Loader from '../loader';
import { uploadAttachments, removeAttachment } from './actions';

import s from './index.module.scss';


const Attachments = props => {
  const profile = useSelector(state => state.profile);
  const { partId, barrelId, checklistId, steelFrameName, checklistTypeId } = useParams();
  const dispatch = useDispatch();

  const remove = attachment => {
    dispatch(
      removeAttachment(attachment, partId, barrelId, steelFrameName, checklistTypeId, checklistId),
    );
  };

  const submit = e => {
    dispatch(
      uploadAttachments(
        e.target.files, partId, barrelId, steelFrameName, checklistTypeId, checklistId, e.target, profile,
        props.isCreatePage,
      ),
    );
  };

  let attachmentsByUsers = {};
  props.attachments && Object.keys(props.attachments).forEach(id => {
    if (props.attachments[id]) {
      if (!attachmentsByUsers[props.attachments[id].uploadedUser.id]) {
        attachmentsByUsers[props.attachments[id].uploadedUser.id] = [];
      }
      attachmentsByUsers[props.attachments[id].uploadedUser.id].push(
        props.attachments[id],
      );
    }
  });

  return !props.disabled ?
    <div className={`${s.attachments} ${s[props.mod]}`}>
      <Text margin={{top: 'medium', left: 'small', right: 'small', bottom: 'small'}} weight='bold'>
        Attachments:
      </Text>
      <Box className={s.list}>
        {Object.keys(attachmentsByUsers).map(id =>
          <div key={id}>
            <Text className={s.user}>By {attachmentsByUsers[id][0].uploadedUser.name}:</Text>
            {attachmentsByUsers[id].map(attachment =>
              <div key={attachment.id} className={s.attachment}>
                {attachment.isLoading ?
                  <Loader mod={s.loader} type='icon' /> :
                  <Menu
                    icon=<MoreVertical />
                    items={[
                      {label: 'Remove', onClick: _ => remove(attachment), color: '#ff0000' },
                    ]}
                  />
                }
                <Link target='_blank' className='link' to={`/api/v1/attachments/${attachment.id}`}>
                  {attachment.name}
                </Link>
              </div>
            )}
          </div>
        )}
      </Box>
      <Box className={s.input}>
        <FileInput multiple value='' name='file' onChange={submit} />
      </Box>
    </div>
  : null
};

export default Attachments;
