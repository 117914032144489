import {
  getAPIAddress, toCamelCase, toSnakeCase, handleRes, formatText, convertArrayToHashmap,
  convertViscosityToConsistency, handleError,
} from '../../helpers';
import * as types from '../../constants';


export const fetchBarrels = (params, info, filters) => dispatch => {
  dispatch({
    type: types.FETCH_BARRELS,
    params,
  });

  const APIAdress = getAPIAddress();
  let reqParams = {
    page: params.page,
    perPage: 30,
    qc: true,
  };
  if (filters) {
    if (filters.started) reqParams.started = filters.started;
    if (filters.ended) reqParams.ended = filters.ended;
    if (filters.isNewMaterialIntroduction) reqParams.isNewMaterialIntroduction = filters.isNewMaterialIntroduction;
    if (info.materialTypes.list && filters.materialTypes) {
      reqParams.materialIds = Object.keys(info.materialTypes.list).filter(key => {
        if (filters.materialTypes.includes(info.materialTypes.list[key].name)) return key;
        return null;
      });
    }
  }
  reqParams = toSnakeCase(reqParams);
  fetch(`${APIAdress}/barrels?` + new URLSearchParams(reqParams))
    .then(res => handleRes(res))
    .then(res => {
      if (res.total === undefined) throw res;

      let barrels = toCamelCase(res);
      barrels.list = convertArrayToHashmap(barrels.list);
      Object.keys(barrels.list).forEach(id => {
        barrels.list[id].qcChecklists = convertArrayToHashmap(barrels.list[id].qcChecklists)
        barrels.list[id].attachments = convertArrayToHashmap(barrels.list[id].attachments);
        Object.keys(barrels.list[id].qcChecklists).forEach(checklistId => {
          barrels.list[id].qcChecklists[checklistId].verifications = convertArrayToHashmap(
            barrels.list[id].qcChecklists[checklistId].verifications,
          );
          barrels.list[id].qcChecklists[checklistId].attachments = convertArrayToHashmap(
            barrels.list[id].qcChecklists[checklistId].attachments,
          );
        });
        barrels.list[id].consistency = convertViscosityToConsistency(barrels.list[id].viscosity);
        barrels.list[id].isLoading = false;
        barrels.list[id].error = false;
      });

      dispatch({
        type: types.FETCH_BARRELS_SUCCESS,
        barrels,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_BARRELS_ERROR,
        error: formatText(res.error),
      });
    });
};

export const filterBarrels = filters => dispatch => {
  if (filters) {
    dispatch({
      type: types.FILTER_BARRELS,
      filters,
    });
  } else {
    dispatch({
      type: types.RESET_BARRELS_FILTERS,
    });
  }
};

export const reset = () => dispatch => {
  dispatch({
    type: types.RESET_BARRELS,
  });
};
