import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box, Table, TableBody, TableCell, TableHeader, TableRow, Text, Pagination, TextInput, Form, Button, Layer, FormField,
  Select,
} from 'grommet';
import {
  Checkbox, CheckboxSelected, Close, FormPreviousLink,
} from 'grommet-icons';
import Moment from 'react-moment';
import $ from 'jquery';

import { clearObject, objectIsEmpty } from '../../helpers';
import Loader from '../loader';
import AuthCheck from '../auth-check';
import {
  fetchUsers, createUser, editUser, removeUser, choose, unchoose, reset,
} from './actions';

import s from './index.module.scss';


const UserManagement = () => {
  const users = useSelector(state => state.users);
  const userRoles = useSelector(state => state.userRoles);
  const choosenUsers = useSelector(state => state.choosenUsers);
  const profile = useSelector(state => state.profile);
  const locations = useSelector(state => state.locations);
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchValue, setSearchValue] = useState('');
  const [modalIsActive, toggleModal] = useState();
  const [removeModalIsActive, toggleRemoveModal] = useState();
  const [showActionButtons, toggleActionButtons] = useState();
  const [editFormId, changeEditFormId] = useState();

  useEffect(() => {
    dispatch(
      fetchUsers({
        page: users.page || 1,
      }),
    );
    dispatch(reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let show;
    let editId;
    Object.keys(choosenUsers).forEach(id => {
      if (choosenUsers[id]) {
        show = true;
        editId === undefined ? editId = id : editId = false;
      }
    });
    changeEditFormId(editId);
    if (show) {
      toggleActionButtons(true);
      $('#controls').children('.submit').prop('disabled', false).removeClass('disabled');
    } else {
      toggleActionButtons(false);
      $('#controls').children('.submit').prop('disabled', true).addClass('disabled');
    }
    if (editId) {
      $('#controls').children('.submitEdit').prop('disabled', false).removeClass('disabled');
    } else {
      $('#controls').children('.submitEdit').prop('disabled', true).addClass('disabled');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choosenUsers, showActionButtons]);

  const changePage = ({ page }) => {
    const params = { page };
    if (searchValue) params.name = searchValue;
    dispatch(
      fetchUsers(params),
    );
  };

  const search = (value, page) => {
    if (value) {
      dispatch(
        fetchUsers({
          page: 1,
          perPage: 30,
          name: value,
        }),
      );
    } else {
      dispatch(
        fetchUsers({ page: page || 1 }),
      );
    }
    setSearchValue(value);
  };

  const chooseUser = id => {
    if (choosenUsers[id]) {
      dispatch(
        unchoose(id),
      );
    } else {
      dispatch(
        choose(users.list[id]),
      );
    }
  };

  const goBack = () => {
    history.push('/tools');
  };

  let usersArray = [];
  if (users.total && users.isLoading === false) {
    usersArray = Object.keys(clearObject(users.list))
      .sort((prevId, id) => {
        return new Date(users.list[id].changed || users.list[id].created) -
          new Date(users.list[prevId].changed || users.list[prevId].created);
      })
      .map(id => users.list[id]);
  }

  const submitUser = e => {
    if (modalIsActive === 'edit') {
      dispatch(
        editUser(
          editFormId,
          e.value,
          choosenUsers[editFormId],
          locations.list, userRoles.list,
          id => {
            search(searchValue, users.page);
            toggleModal(false);
            dispatch(
              unchoose(id),
            );
          },
        ),
      );
    } else if (modalIsActive === 'create') {
      dispatch(
        createUser(
          e.value,
          locations.list, userRoles.list,
          () => {
            search(searchValue, users.page);
            toggleModal(false);
          },
        ),
      );
    }
  };

  const remove = () => {
    let userIds = Object.keys(choosenUsers).filter(id => choosenUsers[id]);
    userIds.forEach(id => {
      dispatch(
        removeUser(id),
      );
      dispatch(
        unchoose(id),
      );
    });
    toggleRemoveModal(false);
  };

  const toggleButton = e => {
    if (e.target.value) {
      $('#userForm .submit').prop('disabled', false).removeClass('disabled');
    } else {
      $('#userForm .submit').prop('disabled', true).addClass('disabled');
    }
  };

  const getLocations = () => {
    let locationsToSelect = ['All'];
    return locationsToSelect.concat(Object.keys(locations.list).map(key => locations.list[key].name))
  };

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return <Box className={s.users}>
    <Box direction='row' width='100%' justify='between' className={s.navHeader}>
      <Button className='buttonLink' onClick={goBack}>
        <FormPreviousLink />
        Tools
      </Button>
    </Box>
    {profile.role && ['Admin'].includes(profile.role.name) &&
      <div id='controls' className={s.controls}>
        <Button type='button' primary label='Edit' className='submitEdit' onClick={() => toggleModal('edit')} />
        <Button
          type='button' primary label='Remove' className='submit' color='#ff0000'
          onClick={() => toggleRemoveModal(true)}
        />
        {removeModalIsActive &&
          <Layer
            className='modal40'
            onEsc={() => toggleRemoveModal(false)}
            onClickOutside={() => toggleRemoveModal(false)}
          >
            <Text>Are you sure?</Text>
            <Box direction='row' className='removeModalButtons'>
              <Button type='button' label='Discard' onClick={() => toggleRemoveModal(false)} />
              <Button type='button' primary label='Remove' onClick={remove} color='#ff0000' />
            </Box>
            <Close onClick={() => toggleRemoveModal(false)} />
          </Layer>}
        <Button type='button' primary label='Add' onClick={() => toggleModal('create')} />
        {modalIsActive &&
          <Layer
            className='modal60'
            onEsc={() => toggleModal(false)}
            onClickOutside={() => toggleModal(false)}
          >
            <Form id='userForm' onSubmit={submitUser}>
              <FormField htmlFor='name' label='Name'>
                <TextInput
                  id='name'
                  name='name'
                  defaultValue={modalIsActive === 'edit' ? choosenUsers[editFormId].name : null}
                  onChange={toggleButton}
                />
              </FormField>
              <FormField htmlFor='email' label='Email'>
                <TextInput
                  id='email'
                  name='email'
                  defaultValue={modalIsActive === 'edit' ? choosenUsers[editFormId].email : null}
                  onChange={toggleButton}
                />
              </FormField>
              <FormField htmlFor='password' label='Password'>
                <TextInput
                  id='password'
                  name='password'
                  onChange={toggleButton}
                  title='Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters'
                  pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}|•••••'
                  defaultValue={modalIsActive === 'edit' ? '•••••' : null}
                />
              </FormField>
              <FormField htmlFor='cardId' label='Card ID'>
                <TextInput
                  id='cardId'
                  name='cardId'
                  onChange={toggleButton}
                  defaultValue={modalIsActive === 'edit' ? '•••••' : null}
                />
              </FormField>
              <FormField name='role' label='Role'>
                <Select
                  id='role'
                  name='role'
                  placeholder='Select role'
                  onChange={toggleButton}
                  options={Object.keys(userRoles.list).map(key => userRoles.list[key].name)}
                  defaultValue={modalIsActive === 'edit' && choosenUsers[editFormId].role ?
                    choosenUsers[editFormId].role.name : null}
                />
              </FormField>
              <FormField name='location' label='Location'>
                <Select
                  id='location'
                  name='location'
                  placeholder='Select location'
                  onChange={toggleButton}
                  options={getLocations()}
                  defaultValue={modalIsActive === 'edit' && choosenUsers[editFormId].location ?
                    choosenUsers[editFormId].location.name : 'All'}
                />
              </FormField>
              <Box direction='row' margin={{top: 'medium'}}>
                <Button type='submit' primary disabled label='Submit' className='submit disabled' />
              </Box>
            </Form>
            <Close onClick={() => toggleModal(false)} />
          </Layer>}
      </div>}
    <Form>
      <TextInput
        onChange={e => search(e.target.value)}
        placeholder='Type user name here'
        value={searchValue}
      />
    </Form>
    {users.isLoading === false ?
      !objectIsEmpty(users.list) ?
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Text className={s.header}>Name</Text>
                </TableCell>
                <TableCell>
                  <Text className={s.header}>Role</Text>
                </TableCell>
                <TableCell>
                  <Text className={s.header}>Location</Text>
                </TableCell>
                <TableCell>
                  <Text className={s.header}>Last changed</Text>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {usersArray.map(user =>
                user.id &&
                  <TableRow
                    key={user.id}
                    className={s.row}
                    onClick={() => chooseUser(user.id)}
                  >
                    <TableCell>
                      {user.isLoading ?
                        <Loader mod={s.loader} type='icon' /> :
                        choosenUsers[user.id] ? <CheckboxSelected /> : <Checkbox />}
                    </TableCell>
                    <TableCell>
                      <Text>{user.name}</Text>
                    </TableCell>
                    <TableCell>
                      <Text>{user.role ? user.role.name : 'Member'}</Text>
                    </TableCell>
                    <TableCell>
                      <Text>{user.location ? user.location.name : 'All'}</Text>
                    </TableCell>
                    <TableCell size='small'>
                      <>
                        <Text>
                          <Moment format='lll'>{user.changed || user.created}</Moment>
                        </Text>
                      </>
                    </TableCell>
                  </TableRow>
              )}
            </TableBody>
          </Table>

          {users.total > 30 &&
            <Box>
              <Pagination
                className={s.pagination}
                numberItems={users.total}
                step={30}
                numberMiddlePages={3}
                onChange={changePage}
                page={users.page}
                alignSelf='center'
              />
            </Box>
          }
          <AuthCheck isAuthorized={true} />
        </>
      :
        <Box className={s.empty} align='center'>Nothing found</Box>
    :
      <Loader mod={s.loader} />}
  </Box>
};

export default UserManagement;
