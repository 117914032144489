import s from './index.module.scss';


const Update = props => (
  <div className={s.update}>
    Everything is ok. Your version of DAS has been updated, use link below to proceed to work
    <br />
    <br />
    <span className='link' onClick={() => props.history.goBack()}>Go back</span>
  </div>
);


export default Update;
