import {
  getAPIAddress, toCamelCase, handleRes, formatText, convertArrayToHashmap, handleError,
} from '../../helpers';
import { makeFilterParamsString } from './helpers';

import * as types from '../../constants';


export const fetchParts = (params, info = {}, filters) => dispatch => {
  let reqParams = {};
  if (params.page) {
    dispatch({
      type: types.FETCH_PARTS,
      params,
    });

    reqParams = {
      page: params.page,
      perPage: 30,
      opt: true,
    };
    if (params.sort) reqParams.sort = params.sort;
  } else {
    dispatch({
      type: types.FETCH_GROUPED_PARTS,
      params,
    });

    reqParams = {
      ...params,
    }
  }
  const APIAdress = getAPIAddress();
  fetch(
    `${APIAdress}/parts?` + makeFilterParamsString(
      reqParams, filters, info.stages, info.locations, info.devices, info.types
    )
  )
    .then(res => handleRes(res))
    .then(res => {
      if (res.dict) {
        dispatch({
          type: types.FETCH_GROUPED_PARTS_SUCCESS,
          groupedParts: res,
        });
        return;
      }

      if (res.total === undefined) throw res;

      let parts = toCamelCase(res);
      parts.list = convertArrayToHashmap(parts.list);
      Object.keys(parts.list).forEach(id => {
        parts.list[id].qcChecklists = convertArrayToHashmap(parts.list[id].qcChecklists);
      });

      dispatch({
        type: types.FETCH_PARTS_SUCCESS,
        parts,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_PARTS_ERROR,
        error: formatText(res.error),
      });
    });;
};

export const fetchPartTypes = () => dispatch => {
  dispatch({
    type: types.FETCH_PART_TYPES,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/part_types`)
    .then(res => handleRes(res))
    .then(res => {
      if (!Array.isArray(res)) throw res;

      let partTypes = res;
      partTypes = partTypes.reduce((partTypesHashMap, partType) => {
        partType = toCamelCase(partType)
        partTypesHashMap[partType.id] = partType;
        return partTypesHashMap;
      }, {});

      dispatch({
        type: types.FETCH_PART_TYPES_SUCCESS,
        partTypes,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_PART_TYPES_ERROR,
        error: formatText(res.error),
      });
    });
};

export const filterParts = filters => dispatch => {
  if (filters) {
    dispatch({
      type: types.FILTER_PARTS,
      filters,
    });
  } else {
    dispatch({
      type: types.RESET_PARTS_FILTERS,
    });
  }
};

export const reset = () => dispatch => {
  dispatch({
    type: types.RESET_PARTS,
  });
};

export const showExportError = () => dispatch => {
  dispatch({
    type: types.SHOW_NOTIFICATION,
    params: {
      type: 'error',
      text: 'Too many panels to export. Please use filters to reduce their number',
    },
  });
};
