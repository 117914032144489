import { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Table, TableBody, TableCell, TableHeader, TableRow, Text, Pagination, TextInput, Form, DropButton,
} from 'grommet';
import {
  StatusGood, CircleAlert, Clear,
} from 'grommet-icons';
import Moment from 'react-moment';
import $ from 'jquery';

import { sortChecklists } from '../../helpers';
import Loader from '../loader';
import AuthCheck from '../auth-check';
import { fetchBarrels } from './actions';
import { findBarrelBySerial } from '../../pages/barrels/actions';

import s from './index.module.scss';


const Barrels = () => {
  const barrels = useSelector(state => state.barrels);
  const profile = useSelector(state => state.profile);
  const locations = useSelector(state => state.locations);
  const stages = useSelector(state => state.stages);
  const materialTypes = useSelector(state => state.materialTypes);
  const dispatch = useDispatch();
  const history = useHistory();

  const changePage = ({ page }) => {
    dispatch(
      fetchBarrels(
        { page, },
        { materialTypes },
        barrels.filters,
      )
    );
  };

  useEffect(() => {
    dispatch(
      fetchBarrels(
        {
          page: barrels.page || 1,
        },
        { materialTypes },
        barrels.filters,
      ),
    );

    let prevBarcodeValue;
    const interval = setInterval(() => {
      const value = $('#barcode').val();
      if (value && value !== prevBarcodeValue) {
        dispatch(
          findBarrelBySerial(value, goToBarrel),
        );
      }
      prevBarcodeValue = value;
    }, 250);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let barrelsArray;
  if (barrels.total && barrels.isLoading === false && stages.isLoading === false) {
    barrelsArray = Object.keys(barrels.list)
      .filter(id => barrels.list[id].isLoading !== true)
      .sort((prevId, id) => {
        return new Date(barrels.list[id].manufactured) - new Date(barrels.list[prevId].manufactured);
      })
      .map(id => {
        const barrel = barrels.list[id];
        barrel.sortedChecklists = sortChecklists(barrel.qcChecklists, stages.list);
        return barrel;
      });
  }

  const goToBarrel = id => {
    history.push(`/barrels/${id}`);
  };

  const getFormat = manufactured => {
    const date = new Date(manufactured);
    const currentDate = new Date();
    if (currentDate.getMonth() === date.getMonth() && currentDate.getYear() === date.getYear()) {
      if (currentDate.getDate() === date.getDate()) {
        return '[Today]';
      } else if (currentDate.getDate() - 1 === date.getDate()) {
        return '[Yesterday]';
      }
    }
    if (currentDate.getFullYear() === date.getFullYear()) {
      return 'MMMM Do, dddd';
    } else {
      return 'MMMM Do, y';
    }
  };

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return barrels.isLoading === false && locations.isLoading === false && materialTypes.isLoading === false &&
      stages.isLoading === false ?
    barrels.total ?
      <div className={s.barrels}>
        <Form>
          <TextInput id='barcode' name='barcode' placeholder="Scan material's barcode here" />
        </Form>
        <Table>
          <TableHeader>
            <TableRow>
                <TableCell>
                  <Text className={s.header}>Serial</Text>
                </TableCell>
                <TableCell>
                  <Text className={s.header}>Expiration date</Text>
                </TableCell>
                <TableCell>
                  <Text className={s.header}>Viscosity</Text>
                </TableCell>
                <TableCell>
                  <Text className={s.header}>
                    Net weight <Text size='xsmall'>(kg)</Text>
                  </Text>
                </TableCell>
                <TableCell>
                  <Text className={s.header}>QC</Text>
                </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {barrelsArray.map((barrel, index) => {
              let prevDay;
              if (barrelsArray[index + 1]) prevDay = new Date(barrelsArray[index + 1].manufactured).getDate();
              const currentDay = new Date(barrel.manufactured).getDate();

              return [
                !index &&
                  <TableRow key={index + 101} className={s.dateRow}>
                    <TableCell colSpan='6'>
                      <Moment format={getFormat(barrel.manufactured)}>{barrel.manufactured}</Moment>
                    </TableCell>
                  </TableRow>,
                  <TableRow key={index} className={s.row}>
                    <TableCell size='medium'>
                      <Link to={`/barrels/${barrel.id}`} className='link'>
                        <Text>{barrel.serial}</Text>
                      </Link>
                    </TableCell>
                    <TableCell size='small' className={s.expired}>
                      <Text>
                        <Moment format='ll'>{barrel.expired}</Moment>
                      </Text>
                    </TableCell>
                    <TableCell size='small'>
                      <Text>{barrel.viscosity || '—'}</Text>
                    </TableCell>
                    <TableCell size='small'>
                      <Text>{barrel.netWeight || '—'}</Text>
                    </TableCell>
                    <TableCell size='xsmall'>
                      <div className={s.title}>
                        <DropButton
                          dropContent={
                            <Box className={s.drop}>
                              <Table>
                                <TableBody>
                                  {barrel.sortedChecklists.length && barrel.sortedChecklists.map(checklist =>
                                    checklist && checklist.resolution &&
                                      <TableRow key={checklist.id}>
                                        <TableCell>
                                          <Text
                                            color={[1].includes(checklist.resolution.id) ? '#008000' :
                                              [2].includes(checklist.resolution.id) ? '#ff8d00' :
                                              [3].includes(checklist.resolution.id) ? '#ff0000' : null}
                                          >
                                            {[1].includes(checklist.resolution.id) ?
                                                <StatusGood color='#008000' /> :
                                              [2].includes(checklist.resolution.id) ?
                                                <CircleAlert color='#ff8d00' /> :
                                              [3].includes(checklist.resolution.id) ?
                                                <Clear color='#ff0000' /> : null}
                                            {checklist.resolution.name}
                                          </Text>
                                        </TableCell>
                                        <TableCell>
                                          <Link to={`/barrels/${barrel.id}/checklists/${checklist.id}`}>
                                            {`${(checklist.type && checklist.type.name) ||
                                                (checklist.stage && checklist.stage.name)
                                              }`}
                                          </Link>
                                        </TableCell>
                                        {checklist.changed &&
                                          <TableCell>
                                            <Moment format='lll'>{checklist.changed}</Moment>
                                            <Text>by {checklist.changedUser.name}</Text>
                                          </TableCell>}
                                      </TableRow>)}
                                </TableBody>
                              </Table>
                            </Box>}
                          dropAlign={{ left: 'right' }}
                        >
                          <div className={s.qc}>
                            {barrel.sortedChecklists.length ? barrel.sortedChecklists.map(checklist =>
                              checklist.resolution &&
                                ([1].includes(checklist.resolution.id) ?
                                    <div key={checklist.id} className={s.useAsIs} /> :
                                  [2].includes(checklist.resolution.id) ?
                                    <div key={checklist.id} className={s.repair} /> :
                                  [3].includes(checklist.resolution.id) ?
                                    <div key={checklist.id} className={s.scrap} /> : null)
                            ) : null}
                          </div>
                        </DropButton>
                      </div>
                    </TableCell>
                  </TableRow>,
                  prevDay !== currentDay && barrelsArray[index + 1] &&
                  <TableRow key={index + 102} className={s.dateRow}>
                    <TableCell colSpan='6'>
                      <Moment format={getFormat(barrelsArray[index + 1].manufactured)}>
                        {barrelsArray[index + 1].manufactured}
                      </Moment>
                    </TableCell>
                  </TableRow>]})}
          </TableBody>
        </Table>

        {barrels.total > 30 &&
          <Box>
            <Pagination
              className={s.pagination}
              numberItems={barrels.total}
              step={30}
              numberMiddlePages={3}
              onChange={changePage}
              page={barrels.page}
              alignSelf='center'
            />
          </Box>
        }
        <AuthCheck isAuthorized={true} />
      </div>
    :
      <Box className={s.barrels} align='center'>Nothing found</Box>
  :
    <Loader mod={s.loader} />
};

export default Barrels;
