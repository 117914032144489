import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useHistory } from 'react-router-dom'
import {
  Box, Button, Layer, Text, FormField, Form, TextInput, Select,
} from 'grommet';
import {
  FormPreviousLink, DocumentVerified, StatusGood, Close,
} from 'grommet-icons';

import { getLS, setLS } from '../../helpers';
import Loader from '../loader';
import { removePart } from '../part/actions';
import { removeBarrel } from '../barrel/actions';
import { generateBarrelReport } from '../../pages/barrel/actions';
import { generatePartReport } from '../../pages/part/actions';

import s from './index.module.scss';


const Header = props => {
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const { partId, barrelId, steelFrameName, checklistTypeId } = useParams();
  const history = useHistory();
  const [modalIsActive, toggleModal] = useState();

  const download = (type, entity) => {
    const serial = entity.serial && entity.serial;
    window.location.replace(`/api/v1/reports/${type}/${serial || entity.uuid}`);
  }

  const runGenerateReport = entity => {
    if (entity.serial) {
      dispatch(
        generateBarrelReport(
          barrelId,
          entity.serial,
          (_ => download('barrels', entity))
        ),
      );
    } else if (entity.uuid) {
      dispatch(
        generatePartReport(
          partId,
          entity.uuid,
          (_ => download('parts', entity))
        ),
      );
    }
  };

  const goToEdit = () => {
    const path = props.entity.uuid ?
        `/parts/${partId}/edit` :
      props.entity.serial ?
        `/barrels/${barrelId}/edit` :
      null;
    if (path) history.push(path);
  };

  const remove = () => {
    if (props.entity.uuid) {
      dispatch(
        removePart(
          partId,
          () => history.push('/parts'),
        ),
      );
    } else if (props.entity.serial) {
      dispatch(
        removeBarrel(
          barrelId,
          () => history.push('/barrels'),
        ),
      );
    }
  };

  const setThermoSettings = e => {
    if (e.value.framesToRewind) {
      const settings = getLS('userSettings') || {};
      setLS(
        'userSettings',
        {
          ...settings,
          framesToRewind: parseInt(e.value.framesToRewind),
        },
      )
    }
    if (e.value.cameraId) props.callback(e.value.cameraId);
    toggleModal(false);
  };

  return <Box direction='row' width='100%' justify='between' className={s.partHeader}>
    <Link
      to={props.type === 'part' ? '/parts' :
        (props.type === 'editPart' || props.type === 'thermo') ? `/parts/${partId}` :
        props.type === 'barrel' ? '/barrels' :
        props.type === 'editBarrel' ? `/barrels/${barrelId}` :
        props.type === 'checklist' && props.entity.serial ? `/barrels/${barrelId}` :
        props.type === 'checklist' && props.entity.uuid ? `/parts/${partId}` :
        props.type === 'checklist' && props.entity.steelFrames ? `/steel-frames/${steelFrameName}` :
        props.type === 'checklistType' ? '/tools/checklist-types' :
        props.type === 'editChecklistType' ? `/tools/checklist-types/${checklistTypeId}` :
        props.type === 'createChecklistType' ? '/tools/checklist-types' :
        props.type === 'lightCalibration' ? '/light-calibrations' :
        props.type === 'steelFrame' ? '/steel-frames' : null
      }
      className={`${s.link} link`}
    >
      <FormPreviousLink />
      <span>
        {props.type === 'part' ? 'Parts' :
          (props.type === 'editPart' || props.type === 'thermo') ? 'Part' :
          props.type === 'barrel' ? 'Materials' :
          props.type === 'editBarrel' ? 'Material' :
          props.type === 'checklist' && props.entity.uuid ? 'Part' :
          props.type === 'checklist' && props.entity.serial ? 'Material' :
          props.type === 'checklist' && props.entity.steelFrames ? 'Steel Frame' :
          ['checklistType', 'editChecklistType', 'createChecklistType'].includes(props.type) ? 'Checklists' :
          props.type === 'lightCalibration' ? 'Calibrations' :
          props.type === 'steelFrame' ? 'Steel Frames' : null}
      </span>
    </Link>
    {props.type === 'part' &&
      <div className={s.report}>
        {props.entity.isGeneratingReport ?
            <Loader type='icon' mod={s.reportLoader} /> :
          props.entity.isReportReady ?
            <StatusGood /> :
            <DocumentVerified />}
        <span
          onClick={() => runGenerateReport(props.entity)}
          className={`${s.reportLink} link`}
        >
          Download Reports
        </span>
      </div>}
    {((props.type === 'barrel' &&
          (profile.role && ['Admin', 'QC Admin', 'Operator', 'QC Inspector'].includes(profile.role.name))) ||
        (props.type === 'part' &&
          (profile.role && ['Admin', 'QC Admin', 'Operator', 'QC Inspector'].includes(profile.role.name) &&
          (!profile.location || (profile.location && profile.location.id === props.entity.location.id))))) ?
      <div className={s.buttons}>
        <Button type='button' primary label='Edit' onClick={goToEdit} />
        <Button type='button' primary label='Remove' onClick={() => toggleModal(true)} color='#ff0000' />
        {modalIsActive &&
          <Layer
            className='modal40'
            onEsc={() => toggleModal(false)}
            onClickOutside={() => toggleModal(false)}
          >
            <Text>Are you sure?</Text>
            <Box direction='row' className='removeModalButtons'>
              <Button type='button' label='Discard' onClick={() => toggleModal(false)} />
              <Button type='button' primary label='Remove' onClick={remove} color='#ff0000' />
            </Box>
            <Close onClick={() => toggleModal(false)} />
          </Layer>}
      </div>
    :
      <span className={s.editLink} />}
    {props.type === 'thermo' &&
      <div className={s.buttons}>
        <Button type='button' primary label='Settings' onClick={() => toggleModal(true)} />
        {modalIsActive &&
          <Layer
            className='modal40'
            onEsc={() => toggleModal(false)}
            onClickOutside={() => toggleModal(false)}
          >
            <Form onSubmit={setThermoSettings}>
              <Box>
                <FormField htmlFor='framesToRewind' label='Frames to rewind'>
                  <TextInput
                    id='framesToRewind'
                    name='framesToRewind'
                    type='number'
                    defaultValue={(getLS('userSettings') && getLS('userSettings').framesToRewind) || 1}
                  />
                </FormField>
                <FormField htmlFor='cameraId' label='Camera id'>
                  <Select
                    id='cameraId'
                    name='cameraId'
                    placeholder='Select camera'
                    options={props.camerasIds}
                    defaultValue={props.activeCameraId.toString()}
                  />
                </FormField>
              </Box>
              <Box direction='row' className='removeModalButtons'>
                <Button type='button' label='Discard' onClick={() => toggleModal(false)} />
                <Button type='submit' primary label='Submit' />
              </Box>
            </Form>
            <Close onClick={() => toggleModal(false)} />
          </Layer>}
      </div>}
  </Box>
};

export default Header;