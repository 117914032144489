import { getAPIAddress, handleRes, formatText, handleError } from '../../helpers';

import * as types from '../../constants';


export const uploadAttachments = (
  files, partId, barrelId, steelFrameName, checklistTypeId, checklistId, target, profile, successCallback,
) => dispatch => {
  let type;
  let id;
  if (partId) {
    type = 'part';
    id = partId;
  } else if (barrelId) {
    type = 'barrel';
    id = barrelId;
  } else if (steelFrameName) {
    type = 'steel_frame';
    id = steelFrameName;
  } else if (checklistTypeId) {
    type = 'qc_checklist_type';
    id = checklistTypeId;
  }
  let constType = type.toUpperCase();
  if (checklistTypeId) {
    constType = 'CHECKLIST_TYPE';
  }
  if (checklistId) {
    constType += '_CHECKLIST';
  }

  const APIAdress = getAPIAddress();
  const params = new FormData();
  const fileTypes = [];
  const fakeAttachmentIds = [];
  Object.keys(files).forEach(index => {
    fakeAttachmentIds[index] = Math.random();
    dispatch({
      type: types[`UPLOAD_${constType}_ATTACHMENTS`],
      id,
      checklistId,
      fakeAttachmentId: fakeAttachmentIds[index],
      params: {
        id: fakeAttachmentIds[index],
        name: files[index].name,
        uploadedUser: {
          id: profile.id,
          name: profile.name,
        },
      },
    });

    params.append(index, files[index], files[index].name);
    if (files[index].type) {
      fileTypes.push(files[index].type);
    } else {
      fileTypes.push('application/octet-stream');
    }
  });
  params.append('file_types', fileTypes.toString());
  params.append('entity_type', type);
  if (steelFrameName) {
    params.append(`${type}_name`, id);
  } else {
    params.append(`${type}_id`, id);
  }
  if (checklistId) {
    params.append('qc_checklist_id', checklistId);
  }
  fetch(`${APIAdress}/attachments`, {
    method: 'POST',
    body: params,
  })
    .then(res => handleRes(res))
    .then(res => {
      if (res.error) throw res;

      Object.keys(res).forEach(index => {
        if (res[index].id) {
          dispatch({
            type: types[`UPLOAD_${constType}_ATTACHMENTS_SUCCESS`],
            id,
            checklistId,
            fakeAttachmentId: fakeAttachmentIds[index],
            attachmentId: res[index].id,
          });
          const MEDIA_MIMES = ['image/jpeg', 'image/png', 'video/mp4'];
          if (MEDIA_MIMES.includes(fileTypes[index]) && !checklistTypeId) {
            dispatch({
              type: types[`ADD_${constType}_MEDIA`],
              id,
              checklistId,
              fakeMediaId: fakeAttachmentIds[index],
              media: {
                id: res[index].id,
                type: {
                  mime: fileTypes[index],
                },
              },
            });
          }
          dispatch({
            type: types.SHOW_NOTIFICATION,
            params: {
              type: 'success',
              text: 'File has been uploaded!',
            },
          });
        } else {
          dispatch({
            type: types[`UPLOAD_${constType}_ATTACHMENTS_ERROR`],
            id,
            checklistId,
            fakeAttachmentId: fakeAttachmentIds[index],
          });

          dispatch({
            type: types.SHOW_NOTIFICATION,
            params: {
              type: 'error',
              text: formatText(res[index].error),
            },
          });
        }
      });
      target.value = '';
    })
    .catch(res => {
      handleError(res);

      target.value = '';
      Object.keys(files).forEach(index => {
        dispatch({
          type: types[`UPLOAD_${constType}_ATTACHMENTS_ERROR`],
          id,
          checklistId,
          fakeAttachmentId: fakeAttachmentIds[index],
        });
      });

      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};


export const removeAttachment = (
  attachment, partId, barrelId, steelFrameName, checklistTypeId, checklistId,
) => dispatch => {
  let type;
  let id;
  if (partId) {
    type = 'part';
    id = partId;
  } else if (barrelId) {
    type = 'barrel';
    id = barrelId;
  } else if (steelFrameName) {
    type = 'steel_frame';
    id = steelFrameName;
  } else if (checklistTypeId) {
    type = 'qc_checklist_type';
    id = checklistTypeId;
  }
  let constType = type.toUpperCase();
  if (checklistTypeId) {
    constType = 'CHECKLIST_TYPE';
  }
  if (checklistId) {
    constType += '_CHECKLIST';
  }

  dispatch({
    type: types[`REMOVE_${constType}_ATTACHMENTS`],
    id,
    checklistId,
    attachmentId: attachment.id,
  });
  if (!checklistTypeId) {
    dispatch({
      type: types[`REMOVE_${constType}_MEDIA`],
      id,
      checklistId,
      mediaId: attachment.id,
    });
  }

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/attachments/${attachment.id}`, {
    method: 'DELETE',
  })
    .then(res => handleRes(res))
    .then(res => {
      if (res.error) throw res;

      dispatch({
        type: types[`REMOVE_${constType}_ATTACHMENTS_SUCCESS`],
        id,
        checklistId,
        attachmentId: attachment.id,
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'File deleted!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};
