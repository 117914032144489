import { getAPIAddress, toCamelCase, handleRes, formatText, handleError } from '../../helpers';
import * as types from '../../constants';


export const fetchLightCalibration = id => dispatch => {
  dispatch({
    type: types.FETCH_LIGHT_CALIBRATION,
    id,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/light_calibrations/${id}`)
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      let lightCalibration = toCamelCase(res);

      dispatch({
        type: types.FETCH_LIGHT_CALIBRATION_SUCCESS,
        id,
        lightCalibration,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_LIGHT_CALIBRATION_ERROR,
        id,
        error: formatText(res.error),
      });
    });
};

export const removeLightCalibration = (id, successCallback) => dispatch => {
  dispatch({
    type: types.REMOVE_LIGHT_CALIBRATION,
    id,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/light_calibrations/${id}`, {
    method: 'DELETE',
  })
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      dispatch({
        type: types.REMOVE_LIGHT_CALIBRATION_SUCCESS,
        id,
      });
      successCallback();
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Removed!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.REMOVE_LIGHT_CALIBRATION_ERROR,
        id,
        error: formatText(res.error),
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};
