import { deepFreeze } from 'grommet/utils'


const theme = deepFreeze({
  global: {
    font: {
      family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    colors: {
      brand: '#25282A',
      'accent-1': '#967126',
      focus: '#967126',
    },
  },
  formField: {
    border: {
      side: 'all',
    },
  },
})

export default theme;
