import * as types from '../../constants';


const steelFrames = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_STEEL_FRAMES:
      return {
        ...state,
        ...action.params,
        isLoading: true,
      };

    case types.FETCH_STEEL_FRAMES_SUCCESS:
      return {
        ...state,
        ...action.steelFrames,
        isLoading: false,
      };

    case types.FETCH_STEEL_FRAMES_ERROR:
      return {
        ...state,
        total: 0,
        list: [],
        isLoading: false,
        error: action.error,
      };

    case types.FETCH_STEEL_FRAME:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            isLoading: true,
          },
        },
      };

    case types.FETCH_STEEL_FRAME_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...action.steelFrame,
            isLoading: false,
            error: false,
          },
        },
      };

    case types.FETCH_STEEL_FRAME_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            isLoading: false,
            error: action.error,
          },
        },
      };

    case types.EDIT_STEEL_FRAME:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...action.steelFrame,
            isLoading: action.hideLoader ? false : true,
          },
        },
      };

    case types.EDIT_STEEL_FRAME_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
            error: false,
          },
        },
      };

    case types.EDIT_STEEL_FRAME_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
            error: action.error,
          },
        },
      };

    case types.CREATE_STEEL_FRAME_CHECKLIST:
      return {
        ...state,
        list: {
          ...state.list,
          [action.entityId]: {
            ...state.list[action.entityId],
            qcChecklists: {
              ...state.list[action.entityId].qcChecklists,
              [action.fakeId]: {
                type: action.checklistType,
                isLoading: true,
              },
            },
          },
        },
      };

    case types.CREATE_STEEL_FRAME_CHECKLIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.entityId]: {
            ...state.list[action.entityId],
            qcChecklists: {
              ...state.list[action.entityId].qcChecklists,
              [action.fakeId]: null,
              [action.id]: {
                ...state.list[action.entityId].qcChecklists[action.fakeId],
                id: action.id,
                isLoading: false,
              },
            },
          },
        },
      };

    case types.REMOVE_STEEL_FRAME_CHECKLIST:
      return {
        ...state,
        list: {
          ...state.list,
          [action.entityId]: {
            ...state.list[action.entityId],
            qcChecklists: {
              ...state.list[action.entityId].qcChecklists,
              [action.id]: {
                ...state.list[action.entityId].qcChecklists[action.id],
                isLoading: true,
              },
            },
          },
        },
      };

    case types.UPLOAD_STEEL_FRAME_ATTACHMENTS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            attachments: {
               ...state.list[action.id].attachments,
              [action.fakeAttachmentId]: {
                ...action.params,
                isLoading: true,
              },
            }
          },
        },
      };

    case types.UPLOAD_STEEL_FRAME_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            attachments: {
              ...state.list[action.id].attachments,
              [action.attachmentId]: {
                ...state.list[action.id].attachments[action.fakeAttachmentId],
                id: action.attachmentId,
                isLoading: false,
              },
              [action.fakeAttachmentId]: null,
            },
          },
        },
      };

    case types.UPLOAD_STEEL_FRAME_ATTACHMENTS_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            attachments: {
              ...state.list[action.id].attachments,
              [action.fakeAttachmentId]: null,
            },
          },
        },
      };

    case types.ADD_STEEL_FRAME_MEDIA:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            media: {
              ...state.list[action.id].media,
              [action.fakeMediaId]: null,
              [action.media.id]: {
                ...state.list[action.id].media[action.fakeMediaId],
                ...action.media,
              },
            },
          },
        },
      };

    case types.REMOVE_STEEL_FRAME_ATTACHMENTS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            attachments: {
              ...state.list[action.id].attachments,
              [action.attachmentId]: {
                ...state.list[action.id].attachments[action.attachmentId],
                isLoading: true,
              },
            },
          },
        },
      };

    case types.REMOVE_STEEL_FRAME_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            attachments: {
              ...state.list[action.id].attachments,
              [action.attachmentId]: null,
            },
          },
        },
      };

    case types.REMOVE_STEEL_FRAME_MEDIA:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            media: {
              ...state.list[action.id].media,
              [action.mediaId]: null,
            },
          },
        },
      };

    case types.REMOVE_STEEL_FRAME_CHECKLIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.entityId]: {
            ...state.list[action.entityId],
            qcChecklists: {
              ...state.list[action.entityId].qcChecklists,
              [action.id]: null,
            },
          },
        },
      };

    case types.UPLOAD_STEEL_FRAME_CHECKLIST_ATTACHMENTS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            qcChecklists: {
              ...state.list[action.id].qcChecklists,
              [action.checklistId]: {
                ...state.list[action.id].qcChecklists[action.checklistId],
                attachments: {
                   ...state.list[action.id].qcChecklists[action.checklistId].attachments,
                  [action.fakeAttachmentId]: {
                    ...action.params,
                    isLoading: true,
                  },
                }
              },
            },
          },
        },
      };

    case types.UPLOAD_STEEL_FRAME_CHECKLIST_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            qcChecklists: {
              ...state.list[action.id].qcChecklists,
              [action.checklistId]: {
                ...state.list[action.id].qcChecklists[action.checklistId],
                attachments: {
                  ...state.list[action.id].qcChecklists[action.checklistId].attachments,
                  [action.attachmentId]: {
                    ...state.list[action.id].qcChecklists[action.checklistId].attachments[action.fakeAttachmentId],
                    id: action.attachmentId,
                    isLoading: false,
                  },
                  [action.fakeAttachmentId]: null,
                },
              },
            },
          },
        },
      };

    case types.UPLOAD_STEEL_FRAME_CHECKLIST_ATTACHMENTS_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            qcChecklists: {
              ...state.list[action.id].qcChecklists,
              [action.checklistId]: {
                ...state.list[action.id].qcChecklists[action.checklistId],
                attachments: {
                  ...state.list[action.id].qcChecklists[action.checklistId].attachments,
                  [action.fakeAttachmentId]: null,
                },
              },
            },
          },
        },
      };

    case types.ADD_STEEL_FRAME_CHECKLIST_MEDIA:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            qcChecklists: {
              ...state.list[action.id].qcChecklists,
              [action.checklistId]: {
                ...state.list[action.id].qcChecklists[action.checklistId],
                media: {
                  ...state.list[action.id].qcChecklists[action.checklistId].media,
                  [action.fakeMediaId]: null,
                  [action.media.id]: {
                    ...state.list[action.id].qcChecklists[action.checklistId].media[action.fakeMediaId],
                    ...action.media,
                  },
                },
              },
            },
          },
        },
      };

    case types.REMOVE_STEEL_FRAME_CHECKLIST_ATTACHMENTS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            qcChecklists: {
              ...state.list[action.id].qcChecklists,
              [action.checklistId]: {
                ...state.list[action.id].qcChecklists[action.checklistId],
                attachments: {
                  ...state.list[action.id].qcChecklists[action.checklistId].attachments,
                  [action.attachmentId]: {
                    ...state.list[action.id].qcChecklists[action.checklistId].attachments[action.attachmentId],
                    isLoading: true,
                  },
                },
              },
            },
          },
        },
      };

    case types.REMOVE_STEEL_FRAME_CHECKLIST_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            qcChecklists: {
              ...state.list[action.id].qcChecklists,
              [action.checklistId]: {
                ...state.list[action.id].qcChecklists[action.checklistId],
                attachments: {
                  ...state.list[action.id].qcChecklists[action.checklistId].attachments,
                  [action.attachmentId]: null,
                },
              },
            },
          },
        },
      };

    case types.REMOVE_STEEL_FRAME_CHECKLIST_MEDIA:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            qcChecklists: {
              ...state.list[action.id].qcChecklists,
              [action.checklistId]: {
                ...state.list[action.id].qcChecklists[action.checklistId],
                media: {
                  ...state.list[action.id].qcChecklists[action.checklistId].media,
                  [action.mediaId]: null,
                },
              },
            },
          },
        },
      };

    default:
      return state;
  }
};

export default steelFrames;
