import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchProfile } from '../profile/actions';
import ProfileComponent from '../../components/profile';

import s from './index.module.scss';


const Profile = () => {
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.profile}>
      {profile.name &&
        <ProfileComponent />}
    </div>
  );
};

export default Profile;
