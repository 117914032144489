import SignInComponent from '../../components/sign-in';

import s from './index.module.scss';


const SignIn = () => (
  <div className={s.signIn}>
    <SignInComponent />
  </div>
);


export default SignIn;
