import { combineReducers } from 'redux';

import profile from './pages/profile/reducers';
import { parts, groupedParts, partTypes } from './pages/parts/reducers';
import { checklistResolutions, manufacturingOrders } from './pages/part/reducers';
import devices from './pages/devices/reducers';
import locations from './pages/locations/reducers';
import barrels from './pages/barrels/reducers';
import checklistTypes from './pages/checklist-types/reducers';
import { sections, choosenSections } from './pages/sections/reducers';
import { verificationTypes, choosenVerificationTypes } from './pages/verification-types/reducers';
import lightCalibrations from './pages/light-calibrations/reducers';
import changelog from './pages/changelog/reducers';
import notification from './components/notification/reducers';
import materialTypes from './components/material-types/reducers';
import entities from './components/entities/reducers';
import { stages, choosenStages } from './pages/stages/reducers';
import { rejectReasons, choosenRejectReasons } from './pages/reject-reasons/reducers';
import checklists from './pages/checklists/reducers';
import steelFrames from './pages/steel-frames/reducers';
import { users, userRoles, choosenUsers } from './pages/user-management/reducers';
import thermo from './pages/thermo/reducers';


export default combineReducers({
  parts, groupedParts, partTypes,
  devices,
  locations,
  barrels,
  notification,
  checklistTypes, verificationTypes, choosenVerificationTypes,
  checklistResolutions, manufacturingOrders,
  profile,
  materialTypes,
  entities,
  sections, choosenSections,
  lightCalibrations,
  changelog,
  rejectReasons, choosenRejectReasons,
  stages, choosenStages,
  checklists,
  steelFrames,
  users, userRoles, choosenUsers,
  thermo,
});
