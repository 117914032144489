import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom'
import {
  Box, Table, TableBody, TableCell, TableRow, Text,
} from 'grommet';
import {
  Logout,
} from 'grommet-icons';
import Moment from 'react-moment';

import { sortChecklists, objectIsEmpty } from '../../helpers';
import Loader from '../loader';
import AuthCheck from '../auth-check';
import Header from '../header';
import QC from '../qc';
import Attachments from '../attachments';
import Media from '../media';
import { fetchSteelFrame } from './actions';

import s from './index.module.scss';


const SteelFrame = () => {
  const steelFrames = useSelector(state => state.steelFrames);
  const profile = useSelector(state => state.profile);
  const stages = useSelector(state => state.stages);
  const checklistTypes = useSelector(state => state.checklistTypes);
  const { steelFrameName } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchSteelFrame(steelFrameName),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steelFrameName]);

  let steelFrame;
  let sortedChecklists;
  if (steelFrames.list && steelFrames.list[steelFrameName] && stages.isLoading === false) {
    steelFrame = steelFrames.list[steelFrameName];
    if (steelFrame.qcChecklists && !objectIsEmpty(steelFrame.qcChecklists)) {
      sortedChecklists = sortChecklists(steelFrame.qcChecklists, stages.list);
    }
  }

  const getFormat = created => {
    const date = new Date(created);
    const currentDate = new Date();
    if (currentDate.getMonth() === date.getMonth() && currentDate.getYear() === date.getYear()) {
      if (currentDate.getDate() === date.getDate()) {
        return '[Today]';
      } else if (currentDate.getDate() - 1 === date.getDate()) {
        return '[Yesterday]';
      }
    }
    if (currentDate.getFullYear() === date.getFullYear()) {
      return 'MMMM Do, dddd';
    } else {
      return 'MMMM Do, y';
    }
  };

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return steelFrame && steelFrame.isLoading === false && stages.isLoading === false ?
    !steelFrame.error ?
      <Box className={s.steelFrame}>
        <Header type='steelFrame' />
        <Box margin={{left: '13px'}}>
          <Box className={s.blockWithSep}>
            <Text margin='small' weight='bold'>Basic information:</Text>
            <Table align='start'>
              <TableBody>
                <TableRow>
                  <TableCell verticalAlign='top'>Odoo links to frames:</TableCell>
                  <TableCell>
                    {steelFrame.steelFrames.map(steelFrame =>
                      <React.Fragment key={steelFrame.productId[1]}>
                        <Link target='_blank' to={{ pathname: steelFrame.link }} className={`link ${s.odoo}`}>
                          <Logout />
                          {steelFrame.productId[1]}
                        </Link>
                        <br />
                      </React.Fragment>)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Inventory Date:</TableCell>
                  <TableCell>
                    <Moment format='ll'>{steelFrame.inventoryDate}</Moment>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <QC
            entity={steelFrame} checklists={sortedChecklists} stages={stages}
            checklistTypes={checklistTypes} profile={profile}
          />
          {steelFrame.changelog &&
            <Box className={s.blockWithSep}>
              <Text margin={{top: 'medium', left: 'small', right: 'small', bottom: 'medium'}} weight='bold'>
                Last 10 changes:
              </Text>
              <Table>
                <TableBody>
                  {steelFrame.changelog.map((changelogItem, index) => {
                    let prevDay;
                    if (steelFrame.changelog[index + 1]) prevDay = new Date(steelFrame.changelog[index + 1].created).getDate();
                    const currentDay = new Date(changelogItem.created).getDate();

                    return [
                      !index &&
                        <TableRow key={index + 101} className={s.dateRow}>
                          <TableCell colSpan='2'>
                            <Moment format={getFormat(changelogItem.created)}>{changelogItem.created}</Moment>
                          </TableCell>
                        </TableRow>,
                        <TableRow key={index} className={s.row}>
                          <TableCell>
                            {changelogItem.nameData ? changelogItem.nameData.map((nameSection, index) =>
                              <span key={index}>
                                {nameSection.type === 'link' ?
                                  <Link
                                    to={nameSection.url}
                                    className='link'
                                    target={nameSection.url.includes('/api/v1/') ? '_blank' : null}
                                  >
                                    {nameSection.text}
                                  </Link> :
                                  nameSection.type === 'text' ?
                                  <Text>{nameSection.text}</Text> : null}
                              </span>) : <Text>{changelogItem.name}</Text>}
                            {changelogItem.repeated > 1 ? ` (x${changelogItem.repeated})` : null}
                          </TableCell>
                          <TableCell className={s.date}>
                            <>
                              <Moment format='hh:mm A'>{changelogItem.created}</Moment>
                              <br />
                              <Text> by {changelogItem.createdUser.name}</Text>
                            </>
                          </TableCell>
                        </TableRow>,
                        prevDay !== currentDay && steelFrame.changelog[index + 1] &&
                        <TableRow key={index + 102} className={s.dateRow}>
                          <TableCell colSpan='2' margin={{top: 'medium'}}>
                            <Moment format={getFormat(steelFrame.changelog[index + 1].created)}>
                              {steelFrame.changelog[index + 1].created}
                            </Moment>
                          </TableCell>
                        </TableRow>]})}
                </TableBody>
              </Table>
            </Box>}
          <Media media={steelFrame.media} />
          <Attachments attachments={steelFrame.attachments} />
        </Box>
        <AuthCheck isAuthorized={true} />
      </Box>
    :
      <Box className={s.steelFrame} align='center'>Nothing found</Box>
  :
    <Loader />
};

export default SteelFrame;
