import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import PartsComponent from '../../components/parts';
import GroupedPartsComponent from '../../components/grouped-parts';
import Filters from '../../components/filters';
import { fetchProfile } from '../profile/actions';
import { fetchPartTypes } from '../../components/parts/actions';
import { fetchLocations } from '../../components/locations/actions';
import { fetchStages } from '../../components/stages/actions';
import { fetchDevices } from '../../components/devices/actions';

import s from './index.module.scss';


const Parts = () => {
  const profile = useSelector(state => state.profile);
  const locations = useSelector(state => state.locations);
  const partTypes = useSelector(state => state.partTypes);
  const stages = useSelector(state => state.stages);
  const devices = useSelector(state => state.devices);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchProfile());
    dispatch(fetchLocations());
    dispatch(fetchPartTypes());
    dispatch(fetchStages());
    dispatch(fetchDevices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.parts}>
      {location.pathname.indexOf('grouped') === 1 ?
        <GroupedPartsComponent /> :
        <PartsComponent />}
      {locations.list && partTypes.list && stages.list && devices.list && profile.name &&
        <Filters
          type={location.pathname.indexOf('grouped') === 1 ? 'groupedParts' : 'parts'}
          locations={locations}
          types={partTypes}
          stages={stages}
          devices={devices}
        />}
    </div>
  );
};

export default Parts;
