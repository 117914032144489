import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom'
import {
  Box, Button, Form, FormField, TextInput, CheckBox,
} from 'grommet';

import Loader from '../loader';
import AuthCheck from '../auth-check';
import Header from '../header';
import { fetchBarrel } from '../barrel/actions';
import { editBarrel } from './actions';

import s from './index.module.scss';


const EditBarrel = () => {
  const barrels = useSelector(state => state.barrels);
  const { barrelId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(
      fetchBarrel(barrelId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barrelId]);
  let barrel;
  if (barrels.list && barrels.list[barrelId]) {
    barrel = barrels.list[barrelId];
  }

  const goBack = () => {
    history.push(`/barrels/${barrelId}`);
  };

  const edit = e => {
    dispatch(
      editBarrel(barrelId, barrel, e.value, goBack),
    );
  };

  return barrel && barrel.isLoading === false ?
    barrel.error === false ?
      <div className={s.editBarrel}>
        <Header type='editBarrel' entity={barrel} />
        <Box margin={{left: '25px'}} width='50%'>
          <Form onSubmit={edit}>
            <FormField htmlFor='viscosity' label='Viscosity'>
              <TextInput id='viscosity' name='viscosity' type='number' defaultValue={barrel.viscosity} />
            </FormField>
            <FormField htmlFor='grossWeight' label='Gross weight'>
              <TextInput id='grossWeight' name='grossWeight' type='number' defaultValue={barrel.grossWeight} />
            </FormField>
            <FormField htmlFor='netWeight' label='Net weight'>
              <TextInput id='netWeight' name='netWeight' type='number' defaultValue={barrel.netWeight} />
            </FormField>
            <FormField htmlFor='isNewMaterialIntroduction' className={s.isNewMaterialIntroduction}>
              <CheckBox
                name='isNewMaterialIntroduction' label='Is NMI' defaultChecked={barrel.isNewMaterialIntroduction}
              />
            </FormField>
            <Box direction='row' margin={{top: 'medium'}}>
              <Button type='submit' primary label='Submit' />
            </Box>
          </Form>
        </Box>
        <AuthCheck isAuthorized={true} />
      </div>
    :
      <AuthCheck isAuthorized={false} />
  :
    <Loader />
};

export default EditBarrel;
