import * as types from '../../constants';


export const showNotification = (rfid, error) => dispatch => {
  if (error) {
    dispatch({
      type: types.SHOW_NOTIFICATION,
      params: {
        type: 'error',
        text: `Can't copy RFID ${rfid} to clipboard ${error}`,
      },
    });
    return;
  }

  dispatch({
    type: types.SHOW_NOTIFICATION,
    params: {
      type: 'success',
      text: `RFID ${rfid} is copied to clipboard`,
      modifier: 'notification',
    },
  });
};
