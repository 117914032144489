import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LightCalibrationComponent from '../../components/light-calibration';
import { fetchDevices } from '../../components/devices/actions';
import { fetchProfile } from '../profile/actions';

import s from './index.module.scss';


const LightCalibration = () => {
  const profile = useSelector(state => state.profile);
  const devices = useSelector(state => state.devices);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProfile());
    dispatch(fetchDevices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.lightCalibration}>
      {profile.name && devices.list &&
        <LightCalibrationComponent />}
    </div>
  );
};

export default LightCalibration;
