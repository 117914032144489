import {
  getAPIAddress, handleRes, formatText, handleError, toCamelCase,
} from '../../helpers';

import * as types from '../../constants';


export const fetchTimestamps = (uuid, successCallback) => dispatch => {
  dispatch({
    type: types.FETCH_THERMO_TIMESTAMPS,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/thermo/get_timestamps/${uuid}`)
    .then(res => handleRes(res))
    .then(res => {
      if (res.error) throw res;

      res = toCamelCase(res);
      const streams = {};
      let initialCameraId;
      Object.keys(res.timestamps).forEach(cameraId => {
        if (!initialCameraId) initialCameraId = parseInt(cameraId);

        streams[cameraId] = {
          timestamps: res.timestamps[cameraId],
          maxFrameId: res.timestamps[cameraId].length,
        };
      });
      dispatch({
        type: types.FETCH_THERMO_TIMESTAMPS_SUCCESS,
        streams: { streams },
      });
      successCallback(streams, initialCameraId);
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_THERMO_TIMESTAMPS_ERROR,
        error: formatText(res.error),
      });
    });
};

export const fetchFrame = (uuid, frameId, timestamp, callback) => dispatch => {
  dispatch({
    type: types.FETCH_THERMO_FRAME,
    isLoading: callback ? false : true,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/thermo/get_frame/${uuid}/${timestamp}`)
    .then(res => handleRes(res))
    .then(res => {
      if (res.error) throw res;

      res = toCamelCase(res);
      dispatch({
        type: types.FETCH_THERMO_FRAME_SUCCESS,
        frame: {
          [frameId]: res,
        },
      });
      if (callback) callback();
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_THERMO_FRAME_ERROR,
        error: formatText(res.error),
      });

      if (callback) callback();
    });
};
