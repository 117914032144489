import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChecklistComponent from '../../components/checklist';
import { fetchProfile } from '../profile/actions';

import s from './index.module.scss';


const Checklist = () => {
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.checklist}>
      {profile.name &&
        <ChecklistComponent />}
    </div>
  );
};

export default Checklist;