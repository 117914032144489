import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, Form, FormField, TextInput, Select, DateInput, CheckBox,
} from 'grommet';
import {
  Previous, Next,
} from 'grommet-icons';
import $ from 'jquery';

import { filterBarrels, fetchBarrels } from '../barrels/actions';
import { filterParts, fetchParts } from '../parts/actions';
import { filterChecklists, fetchChecklists } from '../checklists/actions';

import s from './index.module.scss';


const Filters = props => {
  const barrels = useSelector(state => state.barrels);
  const parts = useSelector(state => state.parts);
  const checklists = useSelector(state => state.checklists);
  const dispatch = useDispatch();

  const open = () => {
    $(`.${s.controls}`).toggleClass(s.hidden);
    $(`.${s.filters}`).toggleClass(s.opened);
  };

  const runFilterBarrels = e => {
    const filters = {
      ...barrels.filters,
      ...e.value,
    };
    dispatch(
      filterBarrels(filters),
    );
    dispatch(
      fetchBarrels(
        { page: 1 },
        { materialTypes: props.materialTypes },
        filters,
      ),
    )
  };

  const resetBarrelsFilters = e => {
    dispatch(filterBarrels());
    dispatch(
      fetchBarrels({
        page: 1,
      }),
    );
  };

  const runFilterParts = e => {
    const filters = {
      ...parts.filters,
      ...e.value,
    };
    dispatch(
      filterParts(filters),
    );

    let params = { page: 1 };
    if (props.type === 'groupedParts') params = { grouped: true };
    dispatch(
      fetchParts(
        params,
        {
          locations: props.locations,
          types: props.types,
          stages: props.stages,
          devices: props.devices,
        },
        filters,
      ),
    );
  };

  const resetPartsFilters = e => {
    dispatch(filterParts());

    let params = { page: 1 };
    if (props.type === 'groupedParts') params = { grouped: true };
    dispatch(
      fetchParts(params),
    );
  };

  const runFilterChecklists = e => {
    const filters = {
      ...checklists.filters,
      ...e.value,
    };
    dispatch(
      filterChecklists(filters),
    );
    dispatch(
      fetchChecklists(
        { page: 1, },
        { types: props.types, },
        filters,
      ),
    );
  };

  const resetChecklistsFilters = e => {
    dispatch(filterChecklists());
    dispatch(
      fetchChecklists({
        page: 1,
      }),
    );
  };

  const onChangeDate = (e, entityType, dateType) => {
    if (entityType === 'barrels') {
      dispatch(
        filterBarrels({[dateType]: e.value}),
      );
    } else if (entityType === 'parts') {
      dispatch(
        filterParts({[dateType]: e.value}),
      );
    } else if (entityType === 'checklists') {
      dispatch(
        filterChecklists({[dateType]: e.value}),
      );
    }
  };

  return <Box className={s.filters}>
    <div className={s.arrow} onClick={open}>
      <Previous />
      <Next />
    </div>
    {props.type === 'barrels' ?
      <Box className={`${s.controls} ${s.hidden}`} pad={{'top': 'medium'}}>
        <Form onSubmit={runFilterBarrels} onReset={resetBarrelsFilters}>
          <FormField htmlFor='started' label='Manufactured from:'>
            <DateInput
              id='started'
              name='started'
              format='mm/dd/yyyy'
              value={barrels.filters ? barrels.filters.started : ''}
              onChange={(e) => onChangeDate(e, 'barrels', 'started')}
            />
          </FormField>
          <FormField htmlFor='ended' label='Manufactured until:'>
            <DateInput
              id='ended'
              name='ended'
              format='mm/dd/yyyy'
              value={barrels.filters ? barrels.filters.ended : ''}
              onChange={(e) => onChangeDate(e, 'barrels', 'ended')}
            />
          </FormField>
          <span className={s.select}>
            <FormField label='Material:' name='materialTypes'>
              <Select
                name='materialTypes'
                placeholder='Select material type'
                closeOnChange={false}
                defaultValue={barrels.filters && barrels.filters.materialTypes}
                options={Object.keys(props.materialTypes.list).map(key => props.materialTypes.list[key].name)}
              />
            </FormField>
          </span>
          <span className={s.checkbox}>
            <CheckBox
              name='isNewMaterialIntroduction'
              label='Is NMI'
              defaultChecked={barrels.filters && barrels.filters.isNewMaterialIntroduction}
            />
          </span>
          <Box direction='row' margin={{top: 'medium'}} pad={{bottom: 'medium'}}>
            <Button type='submit' primary label='Submit' />
            <Button type='reset' margin={{left: 'medium'}} label='Reset' />
          </Box>
        </Form>
      </Box>
    : props.type === 'parts' || props.type === 'groupedParts' ?
      <Box className={`${s.controls} ${s.hidden}`} pad={{'top': 'medium'}}>
        <Form onSubmit={runFilterParts} onReset={resetPartsFilters}>
          <FormField htmlFor='search' label='Search:'>
            <TextInput
              id='search'
              name='search'
              defaultValue={parts.filters ? parts.filters.search : ''}
              placeholder='Type name or UUID'
            />
          </FormField>
          <FormField htmlFor='started' label='Manufactured from:'>
            <DateInput
              id='started'
              name='started'
              format='mm/dd/yyyy'
              value={parts.filters ? parts.filters.started : ''}
              onChange={(e) => onChangeDate(e, 'parts', 'started')}
            />
          </FormField>
          <FormField htmlFor='ended' label='Manufactured until:'>
            <DateInput
              id='ended'
              name='ended'
              format='mm/dd/yyyy'
              value={parts.filters ? parts.filters.ended : ''}
              onChange={(e) => onChangeDate(e, 'parts', 'ended')}
            />
          </FormField>
          {props.type === 'parts' && parts.filters && (parts.filters.started || parts.filters.ended) &&
            <span className={s.select}>
              <FormField label='Dates for specific work center:' name='stages'>
                <Select
                  name='datesByStage'
                  placeholder='Select work center'
                  closeOnChange={false}
                  defaultValue={parts.filters && parts.filters.stages}
                  options={Object.keys(props.stages.list).map(
                    key => props.stages.list[key] && props.stages.list[key].name
                  )}
                />
              </FormField>
            </span>}
          <span className={s.select}>
            <FormField label='Location:' name='location'>
              <Select
                name='location'
                placeholder='Select location'
                defaultValue={parts.filters && parts.filters.location}
                options={Object.keys(props.locations.list).map(key => props.locations.list[key].name)}
              />
            </FormField>
          </span>
          <span className={s.select}>
            <FormField label='Device:' name='device'>
              <Select
                name='device'
                placeholder='Select device'
                defaultValue={parts.filters && parts.filters.device}
                options={Object.keys(props.devices.list).map(key => props.devices.list[key].name)}
              />
            </FormField>
          </span>
          <span className={s.select}>
            <FormField label='Type:' name='types'>
              <Select
                name='types'
                placeholder='Select type'
                multiple
                closeOnChange={false}
                defaultValue={parts.filters && parts.filters.types}
                options={Object.keys(props.types.list).map(key => props.types.list[key].name)}
              />
            </FormField>
          </span>
          <span className={s.select}>
            <FormField label='Work center:' name='stages'>
              <Select
                name='stages'
                placeholder='Select work center'
                multiple
                closeOnChange={false}
                defaultValue={parts.filters && parts.filters.stages}
                options={Object.keys(props.stages.list).map(
                  key => props.stages.list[key] && props.stages.list[key].name
                )}
              />
            </FormField>
          </span>
          {props.type === 'parts' &&
            <span className={s.select}>
              <FormField label='QC:' name='QCConditions'>
                <Select
                  name='QCConditions'
                  placeholder='Select QC conditions'
                  closeOnChange={false}
                  defaultValue={parts.filters && parts.filters.stages}
                  options={[
                    'Scrapped w/o comments', 'Scrapped w/ comments', 'QC passed', 'Need to repair'
                  ]}
                />
              </FormField>
            </span>}
          <span className={s.checkbox}>
            <CheckBox
              name='hasRfid'
              label='Has RFID'
              defaultChecked={parts.filters && parts.filters.hasRfid}
            />
          </span>
          <Box direction='row' margin={{top: 'medium'}} pad={{bottom: 'medium'}}>
            <Button type='submit' primary label='Submit' />
            <Button type='reset' margin={{left: 'medium'}} label='Reset' />
          </Box>
        </Form>
      </Box>
    : props.type === 'checklists' ?
      <Box className={`${s.controls} ${s.hidden}`} pad={{'top': 'medium'}}>
        <Form onSubmit={runFilterChecklists} onReset={resetChecklistsFilters}>
          <FormField htmlFor='search' label='Search:'>
            <TextInput
              id='search'
              name='search'
              defaultValue={checklists.filters ? checklists.filters.search : ''}
              placeholder='Type name'
            />
          </FormField>
          <FormField htmlFor='started' label='Created from:'>
            <DateInput
              id='started'
              name='started'
              format='mm/dd/yyyy'
              value={checklists.filters ? checklists.filters.started : ''}
              onChange={(e) => onChangeDate(e, 'checklists', 'started')}
            />
          </FormField>
          <FormField htmlFor='ended' label='Created until:'>
            <DateInput
              id='ended'
              name='ended'
              format='mm/dd/yyyy'
              value={checklists.filters ? checklists.filters.ended : ''}
              onChange={(e) => onChangeDate(e, 'checklists', 'ended')}
            />
          </FormField>
          <span className={s.select}>
            <FormField label='Type:' name='types'>
              <Select
                name='types'
                placeholder='Select type'
                multiple
                closeOnChange={false}
                defaultValue={checklists.filters && checklists.filters.types}
                options={Object.keys(props.types.list).map(key => props.types.list[key].name)}
              />
            </FormField>
          </span>
          <Box direction='row' margin={{top: 'medium'}} pad={{bottom: 'medium'}}>
            <Button type='submit' primary label='Submit' />
            <Button type='reset' margin={{left: 'medium'}} label='Reset' />
          </Box>
        </Form>
      </Box>
    : null}
  </Box>
};

export default Filters;
