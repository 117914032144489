import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Layer,
  Text,
  Button,
} from 'grommet';
import {
  Close,
} from 'grommet-icons';
import Moment from 'react-moment';

import Loader from '../loader';
import AuthCheck from '../auth-check';
import Header from '../header';
import { fetchLightCalibration, removeLightCalibration } from './actions';
import { getPartName } from '../../helpers';

import s from './index.module.scss';


const LightCalibration = () => {
  const devices = useSelector(state => state.devices);
  const lightCalibrations = useSelector(state => state.lightCalibrations);
  const profile = useSelector(state => state.profile);
  const { lightCalibrationId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [modalIsActive, toggleModal] = useState();
  const [reversedOutputXCoordinates, setReversedOutputXCoordinates] = useState([]);
  const [outputXCoordinatesCount, setOutputXCoordinatesCount] = useState(0);


  useEffect(() => {
    if (!lightCalibrations.list
      || !lightCalibrationId
      || (lightCalibrations.list[lightCalibrationId] && !lightCalibrations.list[lightCalibrationId].isLoading)
    ) {
      dispatch(
        fetchLightCalibration(lightCalibrationId),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lightCalibrationId]);

  useEffect(() => {
    if (lightCalibrations.list && lightCalibrations.list[lightCalibrationId] && lightCalibrations.list[lightCalibrationId].outputXCoordinates) {
      setReversedOutputXCoordinates(lightCalibrations.list[lightCalibrationId].outputXCoordinates.slice().reverse());
      setOutputXCoordinatesCount(
        lightCalibrations.list[lightCalibrationId].outputXCoordinates.length
      );
    }
  }, [lightCalibrationId, lightCalibrations.list]);



  let lightCalibration;
  if (lightCalibrations.list && lightCalibrations.list[lightCalibrationId]) {
    lightCalibration = lightCalibrations.list[lightCalibrationId];
  }

  let maxIntensity = 0;
  let maxX = 0;
  let maxY = 0;

  if (lightCalibration && lightCalibration.outputIntensities) {
    lightCalibration.outputIntensities.forEach((row, yIndex) => {
      row.forEach((intensity, xIndex) => {
        if (intensity > maxIntensity) {
          maxIntensity = intensity;
          maxX = xIndex;
          maxY = yIndex;
        }
      });
    });
  }


  // Why it here?
  // if (!lightCalibrationId && lightCalibrations.list) {
  //   const keys = Object.keys(lightCalibrations.list);
  //   lightCalibration = lightCalibrations.list[keys[keys.length - 1]];
  // }

  const goBack = () => {
    history.push('/light-calibrations');
  };

  const remove = () => {
    dispatch(
      removeLightCalibration(lightCalibrationId, goBack),
    );
  };

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return lightCalibration && lightCalibration.isLoading === false && devices.isLoading === false ?
    !lightCalibration.error ?
      <Box className={s.lightCalibration}>
        <Header type='lightCalibration' />
        <Box margin={{ left: '13px' }}>
          <Box className={s.blockWithSep}>
            <Text margin='small' weight='bold'>Basic information:</Text>
            <Table align='start'>
              <TableBody>
                <TableRow>
                  <TableCell>Date:</TableCell>
                  <TableCell>
                    <Moment format='lll'>{lightCalibration.times[0]}</Moment>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total optical power, W:</TableCell>
                  <TableCell>{lightCalibration.power}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Max. optical power, W:</TableCell>
                  <TableCell>{maxIntensity}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Position of peak (X/Y), mm:</TableCell>
                  <TableCell>{reversedOutputXCoordinates[maxX]}/{lightCalibration.outputYCoordinates[maxY]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={s.name}>Light spot diameter, mm:</TableCell>
                  <TableCell>{lightCalibration.size.join('…')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={s.name}>Device:</TableCell>
                  <TableCell>{lightCalibration.device.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={s.name}>G-code:</TableCell>
                  <TableCell>{lightCalibration.gCode}</TableCell>
                </TableRow>
                {lightCalibration.gCodeComment &&
                  <TableRow>
                    <TableCell className={s.name}>G-code comment:</TableCell>
                    <TableCell>{lightCalibration.gCodeComment.name}</TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </Box>

          <Box className={s.blockWithSep}>
            <Text margin={{ top: 'medium', left: 'small', right: 'small', bottom: 'small' }} weight='bold'>
              Intensities map:
            </Text>
            <div className={s.lightMap}>
              <div className={s.axis}>
                {reversedOutputXCoordinates.map((coordinate, index) => (
                  <div key={index}>{coordinate}</div>
                ))}
                <div className={s.axisName}>X/Y</div>
              </div>
              <div className={s.map}>
                {lightCalibration.outputYCoordinates.map((_, yIndex) => (
                  <div key={yIndex}>
                    {lightCalibration.outputIntensities[yIndex].map((intensity, xIndex) => (
                      <div
                        key={xIndex}
                        className={s[`c${Math.round(intensity)}`]}
                      >
                        {intensity}
                      </div>
                    ))}
                    <div className={s.axisValue}>{lightCalibration.outputYCoordinates[yIndex]}</div>
                  </div>
                ))}
              </div>
            </div>
          </Box>


          {lightCalibration.parts.length > 0 &&
            <Box className={s.blockWithSep}>
              <Text margin={{ top: 'medium', left: 'small', right: 'small', bottom: 'small' }} weight='bold'>
                Parts:
              </Text>
              <Table margin={{ bottom: 'small' }}>
                <TableBody>
                  {lightCalibration.parts.map(part =>
                    <TableRow key={part.id}>
                      <TableCell className={s.name}>
                        <Link to={`/parts/${part.id}`} className='link'>
                          {getPartName(part)}
                        </Link>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>}
        </Box>
        <Button type='button' primary label='Remove' onClick={() => toggleModal(true)} color='#ff0000' />
        {modalIsActive &&
          <Layer
            className='modal40'
            onEsc={() => toggleModal(false)}
            onClickOutside={() => toggleModal(false)}
          >
            <Text>Are you sure?</Text>
            <Box direction='row' className='removeModalButtons'>
              <Button type='button' label='Discard' onClick={() => toggleModal(false)} />
              <Button type='button' primary label='Remove' onClick={remove} color='#ff0000' />
            </Box>
            <Close onClick={() => toggleModal(false)} />
          </Layer>}
        <AuthCheck isAuthorized={true} />
      </Box>
      :
      <Box className={s.lightCalibration} align='center'>Nothing found</Box>
    :
    <Loader />
};

export default LightCalibration;
