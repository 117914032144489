import * as types from '../../constants';


export const verificationTypes = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_VERIFICATION_TYPES:
      return {
        ...state,
        ...action.params,
        isLoading: true,
      };

    case types.FETCH_VERIFICATION_TYPES_SUCCESS:
      return {
        ...state,
        ...action.verificationTypes,
        isLoading: false,
      };

    case types.FETCH_VERIFICATION_TYPES_ERROR:
      return {
        ...state,
        total: 0,
        list: [],
        isLoading: false,
        error: action.error,
      };

    case types.CREATE_VERIFICATION_TYPE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.fakeId]: {
            ...action.verification,
            isLoading: true,
          },
        },
      };

    case types.CREATE_VERIFICATION_TYPE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.fakeId]: null,
          [action.verification.id]: {
            ...state.list[action.fakeId],
            ...action.verification,
            isLoading: false,
          },
        },
      };

    case types.CREATE_VERIFICATION_TYPE_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.fakeId]: null,
        },
      };

    case types.EDIT_VERIFICATION_TYPE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...action.params,
            isLoading: true,
          },
        },
      };

    case types.EDIT_VERIFICATION_TYPE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
          },
        },
      };

    case types.EDIT_VERIFICATION_TYPE_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
            error: action.error,
          },
        },
      };

    case types.REMOVE_VERIFICATION_TYPE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: true,
          },
        },
      };

    case types.REMOVE_VERIFICATION_TYPE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: null,
        },
      };

    case types.REMOVE_VERIFICATION_TYPE_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
            error: action.error,
          },
        },
      };

    default:
      return state;
  }
};

export const choosenVerificationTypes = (state = {}, action) => {
  switch (action.type) {
    case types.CHOOSE_VERIFICATION_TYPE:
      return {
        ...state,
        [action.verificationType.id]: {
          ...action.verificationType,
          state: true,
          parentId: action.parentId,
        },
      };

    case types.UNCHOOSE_VERIFICATION_TYPE:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          state: false,
        },
      };

    case types.RESET_CHOSEN_VERIFICATION_TYPES:
      return {};

    default:
      return state;
  }
};
