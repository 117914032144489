import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  Layer,
  Text,
} from 'grommet';
import {
  Close,
} from 'grommet-icons';

import { distinctFakeId } from '../../helpers';
import Loader from '../loader';
import AuthCheck from '../auth-check';
import Header from '../header';
import ChecklistConstructor from '../checklist-constructor';
import { fetchChecklistType, createChecklistType, removeChecklistType } from './actions';

import s from './index.module.scss';


const ChecklistType = () => {
  const locations = useSelector(state => state.locations);
  const checklistTypes = useSelector(state => state.checklistTypes);
  const partTypes = useSelector(state => state.partTypes);
  const materialTypes = useSelector(state => state.materialTypes);
  const entities = useSelector(state => state.entities);
  const profile = useSelector(state => state.profile);
  const { checklistTypeId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [modalIsActive, toggleModal] = useState();

  useEffect(() => {
    if (!checklistTypes.list
      || !checklistTypeId
      || (checklistTypes.list[checklistTypeId] && !checklistTypes.list[checklistTypeId].verificationTypes)
    ) {
      dispatch(
        fetchChecklistType(checklistTypeId, checklistTypes),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistTypeId]);

  let checklistType;
  if (checklistTypes.list && checklistTypes.list[checklistTypeId]) {
    checklistType = checklistTypes.list[checklistTypeId];
  }
  if (!checklistTypeId && checklistTypes.list) {
    const fakeId = distinctFakeId(checklistTypes.list);
    if (fakeId) checklistType = checklistTypes.list[fakeId];
  }

  const goBack = () => {
    history.push('/tools/checklist-types');
  };

  const clone = () => {
    dispatch(
      createChecklistType({
        params: {
          name: `${checklistType.name} (clone)`
        },
        checklistType,
        entities: entities.list,
        partTypes: partTypes.list,
        materialTypes: materialTypes.list,
        locations: locations.list,
        successCallback: id => history.push(`/tools/checklist-types/${id}`),
      }),
    );
  };

  const remove = () => {
    dispatch(
      removeChecklistType(checklistTypeId, goBack),
    );
  };

  const isCreatePage = () => {
    return Number.isInteger(checklistType.id) ? false : true;
  };

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return checklistType && checklistType.isLoading === false && materialTypes.isLoading === false
      && locations.isLoading === false && entities.isLoading === false ?
    !checklistType.error ?
      <Box className={s.checklistType}>
        <Header type='checklistType' />
        {!isCreatePage() &&
          <div className={s.controls}>
            <Button type='button' primary label='Clone' onClick={clone} />
            <Button type='button' primary label='Remove' onClick={() => toggleModal(true)} color='#ff0000' />
            {modalIsActive &&
              <Layer
                className='modal40'
                onEsc={() => toggleModal(false)}
                onClickOutside={() => toggleModal(false)}
              >
                <Text>Are you sure?</Text>
                <Box direction='row' className='removeModalButtons'>
                  <Button type='button' label='Discard' onClick={() => toggleModal(false)} />
                  <Button type='button' primary label='Remove' onClick={remove} color='#ff0000' />
                </Box>
                <Close onClick={() => toggleModal(false)} />
              </Layer>}
          </div>}
        <ChecklistConstructor checklistType={checklistType} />
        <AuthCheck isAuthorized={true} />
      </Box>
    :
      <Box className={s.checklistType} align='center'>Nothing found</Box>
  :
    <Loader />
};

export default ChecklistType;
