import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { fetchProfile } from '../profile/actions';
import PartComponent from '../../components/part';
import EditPart from '../../components/edit-part';

import s from './index.module.scss';


const Part = () => {
  const location = useLocation();
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return profile.name ?
    <div className={s.part}>
      {location.pathname.indexOf('edit') === -1 ?
        <PartComponent /> :
        <EditPart />}
    </div> : null;
};

export default Part;
