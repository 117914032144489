import { getAPIAddress, handleRes, formatText, handleError } from '../../helpers';
import * as types from '../../constants';


export const generatePartReport = (id, uuid, successCallback) => dispatch => {
  dispatch({
    type: types.GENERATE_PART_REPORT,
    id,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/reports/parts/${uuid}`, {
    method: 'PUT',
  })
    .then(res => handleRes(res))
    .then(res => {
      if (!res.success) throw res;

      dispatch({
        type: types.GENERATE_PART_REPORT_SUCCESS,
        id,
      });

      let status;
      const intervalId = setInterval(() => {
        if (!status || status === 'in progress') {
          fetch(`${APIAdress}/reports/parts/${uuid}/status`)
            .then(res => handleRes(res))
            .then(res => status = res.status)
            .catch(res => {
              handleError(res);

              dispatch({
                type: types.SHOW_NOTIFICATION,
                params: {
                  type: 'error',
                  text: formatText(res.error),
                },
              });
            });
        } else if (status === 'ready') {
          dispatch({
            type: types.PART_REPORT_IS_READY,
            id,
          });
          clearInterval(intervalId);
          successCallback();
        }
      }, 1000)

    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};