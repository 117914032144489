import { getAPIAddress, toCamelCase, handleRes, formatText, handleError } from '../../helpers';
import * as types from '../../constants';


export const fetchLocations = () => dispatch => {
  dispatch({
    type: types.FETCH_LOCATIONS,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/locations`)
    .then(res => handleRes(res))
    .then(res => {
      if (!Array.isArray(res)) throw res;

      let locations = res;
      locations = locations.reduce((locationsHashMap, location) => {
        location = toCamelCase(location)
        locationsHashMap[location.id] = location;
        return locationsHashMap;
      }, {});

      dispatch({
        type: types.FETCH_LOCATIONS_SUCCESS,
        locations,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_LOCATIONS_ERROR,
        error: formatText(res.error),
      });
    });
};
