import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RejectReasonsComponent from '../../components/reject-reasons';
import { fetchProfile } from '../profile/actions';

import s from './index.module.scss';


const RejectReasons = () => {
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.rejectReasons}>
      {profile.name &&
        <RejectReasonsComponent />}
    </div>
  );
};

export default RejectReasons;
