import {
  getAPIAddress, toCamelCase, handleRes, formatText, toSnakeCase, convertArrayToHashmap, handleError,
} from '../../helpers';
import * as types from '../../constants';


export const fetchStages = params => dispatch => {
  dispatch({
    type: types.FETCH_STAGES,
    params,
  });

  const APIAdress = getAPIAddress();
  let reqParams = {
    page: 1,
    perPage: 9000,
  };
  if (params) {
    reqParams = {
      perPage: 30,
      ...params,
    };
  }
  reqParams = toSnakeCase(reqParams);
  fetch(`${APIAdress}/stages?` + new URLSearchParams(reqParams))
    .then(res => handleRes(res))
    .then(res => {
      if (res.total === undefined) throw res;

      let stages = toCamelCase(res);
      stages.list = convertArrayToHashmap(stages.list);

      dispatch({
        type: types.FETCH_STAGES_SUCCESS,
        stages,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_STAGES_ERROR,
        error: formatText(res.error),
      });
    });
};

export const createStage = (stage, successCallback) => dispatch => {
  const fakeId = Math.random();

  dispatch({
    type: types.CREATE_STAGE,
    fakeId,
    stage,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/stages`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      toSnakeCase(stage),
    ),
  })
    .then(res => handleRes(res))
    .then(res => {
      if (res.error) throw res;

      dispatch({
        type: types.CREATE_STAGE_SUCCESS,
        fakeId,
        stage: {
          id: res.id,
        },
      });
      if (successCallback) successCallback(res.id);
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Stage has been created!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.CREATE_STAGE_ERROR,
        fakeId,
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const editStage = (id, params, successCallback) => dispatch => {
  dispatch({
    type: types.EDIT_STAGE,
    id,
    params,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/stages/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      toSnakeCase(params),
    ),
  })
    .then(res => handleRes(res))
    .then(res => {
      if (res.error) throw res;

      dispatch({
        type: types.EDIT_STAGE_SUCCESS,
        id: res.id,
      });
      if (successCallback) successCallback(res.id);
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Stage has been edited!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.EDIT_STAGE_ERROR,
        id,
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const removeStage = (id, successCallback) => dispatch => {
  dispatch({
    type: types.REMOVE_STAGE,
    id,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/stages/${id}`, {
    method: 'DELETE',
  })
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      dispatch({
        type: types.REMOVE_STAGE_SUCCESS,
        id,
      });
      if (successCallback) successCallback();
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Removed!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.REMOVE_STAGE_ERROR,
        id,
        error: formatText(res.error),
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const choose = stage => dispatch => {
  dispatch({
    type: types.CHOOSE_STAGE,
    stage,
  });
};

export const unchoose = id => dispatch => {
  dispatch({
    type: types.UNCHOOSE_STAGE,
    id,
  });
};

export const reset = () => dispatch => {
  dispatch({
    type: types.RESET_CHOSEN_STAGES,
  });
};
