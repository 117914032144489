import {
  getAPIAddress, toSnakeCase, handleRes, formatText, handleError,
} from '../../helpers';
import * as types from '../../constants';


export const editPart = (
  id, part, stages, manufacturingOrders, params, successCallback
) => dispatch => {
  let stageId;
  if (params.stage) {
    stageId = parseInt(
      Object.keys(stages).filter(key => stages[key].name === params.stage)[0],
    );
  }
  let manufacturingOrderId;
  if (params.manufacturingOrder && manufacturingOrders) {
    manufacturingOrderId = parseInt(
      Object.keys(manufacturingOrders).filter(
        key => params.manufacturingOrder.includes(manufacturingOrders[key].name),
      ),
    );
  }

  let steelFrameName;
  if (params.steelFrame) steelFrameName = params.steelFrame;
  if (typeof(params.name) === 'string' && params.name === '') {
    params.name = 'Unknown part';
  }
  part = {...part, ...params};

  dispatch({
    type: types.EDIT_PART,
    id,
    part,
  });

  if (stageId) part.stageId = stageId;
  if (manufacturingOrderId) {
    part.manufacturingOrderId = manufacturingOrderId;
  } else if (part.manufacturingOrder && part.manufacturingOrder.id) {
    part.manufacturingOrderId = part.manufacturingOrder.id;
  } else if (part.manufacturingOrder === 'None') {
    part.manufacturingOrderId = null;
  }
  if (steelFrameName) {
    part.steelFrameName = steelFrameName;
  } else if (part.steelFrame && part.steelFrame.name) {
    part.steelFrameName = part.steelFrame.name;
  } else if (part.steelFrame === 'None') {
    part.steelFrameName = null;
  }
  part.locationId = part.location.id;
  part.deviceId = part.device.id;
  part.operatorId = part.operator.id;
  if (part.rfid || part.rfid === '') {
    part.rfid = part.rfid.split(', ');
  } else {
    delete part.rfid;
  }
  delete part.id;
  delete part.isLoading;
  delete part.qcChecklists;
  delete part.error;
  delete part.location;
  delete part.operator;
  delete part.changedUser;
  delete part.attachments;
  delete part.device;
  delete part.type;
  delete part.pdmTypes;
  delete part.material;
  delete part.gCodeComment;
  delete part.barrels;
  delete part.unidentifiedBarrels;
  delete part.media;
  delete part.printingEvents;
  delete part.printingProcess;
  delete part.rawParams;
  delete part.lightCalibration;
  delete part.changelog;
  delete part.stage;
  delete part.manufacturingOrder;
  delete part.steelFrame;
  delete part.sortedChecklists;
  delete part.timings;
  delete part.resolutionsChangelog;
  delete part.hasThermo;

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/parts/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      toSnakeCase(part),
    ),
  })
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      dispatch({
        type: types.EDIT_PART_SUCCESS,
        id: res.id,
      });
      successCallback();
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Changed!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.EDIT_PART_ERROR,
        id,
        error: formatText(res.error),
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};
