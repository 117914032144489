import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import {
  Box, Table, TableBody, TableCell, TableRow, Text, Button, Form,
} from 'grommet';

import Loader from '../loader';
import AuthCheck from '../auth-check';
import { signOut } from '../../pages/profile/actions';

import s from './index.module.scss';


const Profile = () => {
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const history = useHistory();

  const goMain = () => {
    history.push('/');
  };

  const submit = () => {
    dispatch(
      signOut(goMain),
    );
  };

  return profile && profile.isLoading === false ?
    profile.error === false ?
      <Box className={s.profile}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Text className={s.name}>Name:</Text>
              </TableCell>
              <TableCell>
                <Text>{profile.name}</Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Text className={s.name}>Email:</Text>
              </TableCell>
              <TableCell>
                <Text>{profile.email}</Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Text className={s.name}>Role:</Text>
              </TableCell>
              <TableCell>
                <Text>{profile.role ? profile.role.name : 'User'}</Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Text className={s.name}>Location:</Text>
              </TableCell>
              <TableCell>
                <Text>{profile.location ? profile.location.name : 'Worldwide'}</Text>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Form onSubmit={submit}>
          <Box direction='row' margin={{left: 'small', top: 'medium'}}>
            <Button type='submit' primary label='Logout' />
          </Box>
        </Form>
        <AuthCheck isAuthorized={true} />
      </Box>
    :
      <AuthCheck isAuthorized={false} />
  :
    <Loader />
};

export default Profile;
