import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChecklistTypesComponent from '../../components/checklist-types';
import { fetchLocations } from '../../components/locations/actions';
import { fetchPartTypes } from '../../components/parts/actions';
import { fetchMaterialTypes } from '../../components/material-types/actions';
import { fetchEntities } from '../../components/entities/actions';
import { fetchProfile } from '../profile/actions';

import s from './index.module.scss';


const ChecklistTypes = () => {
  const profile = useSelector(state => state.profile);
  const locations = useSelector(state => state.locations);
  const partTypes = useSelector(state => state.partTypes);
  const materialTypes = useSelector(state => state.materialTypes);
  const entities = useSelector(state => state.entities);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProfile());
    dispatch(fetchLocations());
    dispatch(fetchPartTypes());
    dispatch(fetchMaterialTypes());
    dispatch(fetchEntities());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.checklistTypes}>
      {profile.name && locations.list && partTypes.list && materialTypes.list && entities.list &&
        <ChecklistTypesComponent />}
    </div>
  );
};

export default ChecklistTypes;
