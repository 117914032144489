import * as types from '../../constants';


export const stages = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_STAGES:
      return {
        ...state,
        ...action.params,
        isLoading: true,
      };

    case types.FETCH_STAGES_SUCCESS:
      return {
        ...state,
        ...action.stages,
        isLoading: false,
      };

    case types.FETCH_STAGES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case types.CREATE_STAGE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.fakeId]: {
            ...action.stage,
            isLoading: true,
          },
        },
      };

    case types.CREATE_STAGE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.fakeId]: null,
          [action.stage.id]: {
            ...state.list[action.fakeId],
            ...action.stage,
            isLoading: false,
          },
        },
      };

    case types.CREATE_STAGE_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.fakeId]: null,
        },
      };

    case types.EDIT_STAGE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...action.params,
            isLoading: true,
          },
        },
      };

    case types.EDIT_STAGE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
          },
        },
      };

    case types.EDIT_STAGE_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
            error: action.error,
          },
        },
      };

    case types.REMOVE_STAGE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: true,
          },
        },
      };

    case types.REMOVE_STAGE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: null,
        },
      };

    case types.REMOVE_STAGE_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
            error: action.error,
          },
        },
      };

    default:
      return state;
  }
};

export const choosenStages = (state = {}, action) => {
  switch (action.type) {
    case types.CHOOSE_STAGE:
      return {
        ...state,
        [action.stage.id]: action.stage,
      };

    case types.UNCHOOSE_STAGE:
      return {
        ...state,
        [action.id]: null,
      };

    case types.RESET_CHOSEN_STAGES:
      return {};

    default:
      return state;
  }
};
