import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getQSParams } from '../../helpers';
import BarrelsComponent from '../../components/barrels';
import Filters from '../../components/filters';
import { findBarrelBySerial } from './actions';
import { fetchProfile } from '../profile/actions';
import { fetchLocations } from '../../components/locations/actions';
import { fetchMaterialTypes } from '../../components/material-types/actions';
import { fetchStages } from '../../components/stages/actions';

import s from './index.module.scss';


const Barrels = props => {
  const profile = useSelector(state => state.profile);
  const locations = useSelector(state => state.locations);
  const materialTypes = useSelector(state => state.materialTypes);
  const stages = useSelector(state => state.stages);
  const params = getQSParams(props.location.search);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(fetchProfile());
    dispatch(fetchLocations());
    dispatch(fetchMaterialTypes());
    dispatch(fetchStages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToBarrel = id => {
    history.push(`/barrels/${id}`);
  };

  if (params.find_by_serial) {
      dispatch(
        findBarrelBySerial(params.find_by_serial, goToBarrel),
      );
  };

  return (
    <div className={s.barrels}>
      <BarrelsComponent />
      {locations.list && materialTypes.list && profile.name && stages.list &&
        <Filters type='barrels' locations={locations} materialTypes={materialTypes} />
      }
    </div>
  )
};

export default Barrels;
