import * as types from '../../constants';


const devices = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_DEVICES:
      return {
        ...state,
        isLoading: true,
      };

    case types.FETCH_DEVICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        list: action.devices,
      };

    case types.FETCH_DEVICES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default devices;
