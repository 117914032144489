import * as types from '../../constants';


export const checklistResolutions = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_CHECKLIST_RESOLUTIONS:
      return {
        ...state,
        isLoading: true,
      };

    case types.FETCH_CHECKLIST_RESOLUTIONS_SUCCESS:
      return {
        ...state,
        ...action.resolutions,
        isLoading: false,
      };

    case types.FETCH_CHECKLIST_RESOLUTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const manufacturingOrders = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_MANUFACTORING_ORDERS:
      return {
        ...state,
        isLoading: true,
      };

    case types.FETCH_MANUFACTORING_ORDERS_SUCCESS:
      return {
        ...state,
        ...action.manufacturingOrders,
        isLoading: false,
      };

    case types.FETCH_MANUFACTORING_ORDERS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
