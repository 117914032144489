import { Link } from 'react-router-dom';

import s from './index.module.scss';


const NotFound = () => (
  <div className={s.notFound}>
    Nothing found
    <br />
    <br />
    <Link className='link' to='/'>Go to the main page</Link>
  </div>
);


export default NotFound;
