import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchProfile } from '../profile/actions';
import ToolsComponent from '../../components/tools';

import s from './index.module.scss';


const Tools = () => {
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.tools}>
      {profile.name &&
        <ToolsComponent />}
    </div>
  );
};

export default Tools;
