import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {
  Avatar, Button, Box, Nav, Stack, Text, Sidebar,
} from 'grommet';
import {
  Drawer, Test, Info, Troubleshoot, Compliance, Splits,
} from 'grommet-icons';

import { setCookie } from '../../helpers';
import s from './index.module.scss';


const SidebarHeader = props => (
  <Link to='/profile' className={`${s.link} ${s.userLink}`}>
    <Box align='center' gap='small' direction='row'>
      <Stack alignSelf='start' align='center' anchor='top-right'>
        <Avatar src={'/logo-white.png'} />
      </Stack>
      <Text margin={{'right': '5px'}}>
        {props.profile.name && props.profile.name.split(' ')[0]}
      </Text>
    </Box>
  </Link>
);

const SidebarButton = ({ icon, label, ...rest }) => (
  <Box pad='12px'>
    <Button
      gap='small'
      alignSelf='start'
      plain
      icon={icon}
      label={label}
      {...rest}
    />
  </Box>
);

const MainNavigation = props => {
  const location = useLocation();

  return (
    <Nav gap='small' responsive={false}>
      {((props.profile.role && ['Admin', 'QC Admin', 'Operator', 'QC Inspector'].includes(props.profile.role.name)) ||
          !props.profile.role) &&
        <>
          <Link to={'/barrels'} className={
            location.pathname.startsWith('/barrels') ? `${s.link} ${s.activeLink}` : s.link
          }>
            <SidebarButton icon={<Test />} label='Materials' />
          </Link>
          <Link to={'/parts'} className={
            (location.pathname.startsWith('/parts') || location.pathname.startsWith('/grouped-parts')) ?
              `${s.link} ${s.activeLink}` : s.link
          }>
            <SidebarButton icon={<Drawer />} label='Parts' />
          </Link>
        </>}
      <Link to={'/steel-frames'} className={
        location.pathname.startsWith('/steel-frames') ? `${s.link} ${s.activeLink}` : s.link
      }>
        <SidebarButton icon={<Splits />} label='Steel Frames' />
      </Link>
      {((props.profile.role && ['Admin', 'QC Admin', 'Operator', 'QC Inspector'].includes(props.profile.role.name)) ||
          !props.profile.role) &&
        <>
          <>
            <div className={s.separator}></div>
            <Link to={'/checklists'} className={
              location.pathname.startsWith('/checklists') ? `${s.link} ${s.activeLink}` : s.link
            }>
              <SidebarButton icon={<Compliance />} label='Checklists' />
            </Link>
          </>
          <Link to={'/light-calibrations'} className={
            location.pathname.startsWith('/light-calibrations') ? `${s.link} ${s.activeLink}` : s.link
          }>
            <SidebarButton icon={<Info />} label='Light Calibrations' />
          </Link>
          {props.profile.role && ['Admin', 'QC Admin'].includes(props.profile.role.name) &&
            <>
              <div className={s.separator}></div>
              <Link to={'/tools'} className={
                location.pathname.startsWith('/tools') ? `${s.link} ${s.activeLink}` : s.link
              }>
                <SidebarButton icon={<Troubleshoot />} label='Tools' />
              </Link>
            </>}
        </>}
    </Nav>)
};

const SideBarComponent = () => {
  const profile = useSelector(state => state.profile);
  if (profile.name) setCookie('username', profile.name);

  return !window.location.search.includes('embedded') &&
    <Sidebar
      responsive={false}
      background='brand'
      header={<SidebarHeader profile={profile} />}
      className={s.sidebar}
    >
      <MainNavigation profile={profile} />
    </Sidebar>
};

export default SideBarComponent;
