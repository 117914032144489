import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from 'grommet';
import Moment from 'react-moment';

import Loader from '../loader';
import AuthCheck from '../auth-check';
import { fetchDevices } from './actions';

import s from './index.module.scss';


const Devices = () => {
  const devices = useSelector(state => state.devices);
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDevices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return devices.list && devices.isLoading === false ?
    <div className={s.devices}>
      <Table>
        <TableHeader>
          <TableRow>
              <TableCell>
                <Text className={s.name}>Name</Text>
              </TableCell>
              <TableCell>
                <Text className={s.name}>Created</Text>
              </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.keys(devices.list).map(id => (
            <TableRow key={id}>
                <TableCell>
                  <Text>
                    <Text className={s.name}>{devices.list[id].name}</Text>
                  </Text>
                </TableCell>
                <TableCell>
                  <Moment format='lll'>{devices.list[id].created}</Moment>
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <AuthCheck isAuthorized={true} />
    </div>
  :
    <Loader />
};

export default Devices;
