import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SteelFrameComponent from '../../components/steel-frames';
import { fetchProfile } from '../profile/actions';
import { fetchStages } from '../../components/stages/actions';

import s from './index.module.scss';


const SteelFrames = () => {
  const stages = useSelector(state => state.stages);
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProfile());
    dispatch(fetchStages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.steelFrames}>
      {profile.name && stages.list &&
        <SteelFrameComponent />}
    </div>
  );
};

export default SteelFrames;
