import {
  getAPIAddress, toCamelCase, handleRes, formatText, convertArrayToHashmap, toSnakeCase,
  transformVerificationTypes, clearArray, sortByKey, convertHashmapToArray, distinctFakeId, objectIsEmpty,
  formatVerificationTypes, handleError,
} from '../../helpers';
import * as types from '../../constants';


export const fetchChecklistType = (id, checklistTypes) => dispatch => {
  if (checklistTypes && !objectIsEmpty(checklistTypes) && distinctFakeId(checklistTypes.list)) return;

  if (!id) {
    id = Math.random();
    dispatch({
      type: types.FETCH_CHECKLIST_TYPE_SUCCESS,
      id,
      checklistType: {
        id,
        verificationTypes: {},
      },
    });
    return;
  }

  dispatch({
    type: types.FETCH_CHECKLIST_TYPE,
    id,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/qc/checklist_types/${id}`)
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      let checklistType = toCamelCase(res);
      checklistType.verificationTypes = convertArrayToHashmap(
        transformVerificationTypes(
          checklistType.verificationTypes,
          checklistType.isImperial ? 'Imperial' : 'Metrical',
        ),
      );
      checklistType.attachments = convertArrayToHashmap(checklistType.attachments);

      dispatch({
        type: types.FETCH_CHECKLIST_TYPE_SUCCESS,
        id,
        checklistType,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_CHECKLIST_TYPE_ERROR,
        id,
        error: formatText(res.error),
      });
    });
};

export const createChecklistType = ({
  params, checklistType, entities, partTypes, materialTypes, locations, sortedVerifications, successCallback,
}) => dispatch => {
  Object.keys(params).forEach(key => {
    if (key === 'name') {
      checklistType.name = params[key];
    } else if (key === 'description') {
      checklistType.description = params[key];
    } else if (key === 'type') {
      checklistType.entityId = parseInt(
        Object.keys(entities).find(findKey => {
          return params[key] === entities[findKey].name;
        }),
      );
    } else if (key === 'locations') {
      checklistType.locationIds = [];
      params[key].forEach(location => {
        Object.keys(locations).forEach(locationKey => {
          if (location === locations[locationKey].name || params[key].includes('All')) {
            checklistType.locationIds.push(locations[locationKey].id);
          }
        });
      });
    }
  });
  const fakeId = checklistType.id;
  dispatch({
    type: types.CREATE_CHECKLIST_TYPE,
    fakeId,
    checklistType,
  });

  if (!checklistType.entityId) {
    checklistType.entityId = checklistType.entity.id;
  }
  if (checklistType.entityId === 1) { // Part type
    checklistType.partTypeIds = [];
    if (params.subtypes && params.subtypes.length) {
      params.subtypes.forEach(subtype => {
        Object.keys(partTypes).forEach(partTypeKey => {
          if (subtype === partTypes[partTypeKey].name || params.subtypes.includes('All')) {
            checklistType.partTypeIds.push(partTypes[partTypeKey].id);
          }
        });
      });
    }
  } else if (checklistType.entityId === 2) {  // Material type
    checklistType.materialIds = [];
    if (params.subtypes && params.subtypes.length) {
      params.subtypes.forEach(subtype => {
        Object.keys(materialTypes).forEach(materialKey => {
          if (subtype === materialTypes[materialKey].name || params.subtypes.includes('All')) {
            checklistType.materialIds.push(materialTypes[materialKey].id);
          }
        });
      });
    }
  }
  if (!checklistType.locationIds) checklistType.locationIds = [];

  const verificationTypeIds = []
  if (sortedVerifications) {
    let sectionId = null;
    sortedVerifications.forEach(verification => {
      if (typeof(verification.id) === 'string') {
        sectionId = parseInt(
          verification.id.replace('s_', ''),
        );
        return;
      }
      verificationTypeIds.push([verification.id, sectionId]);
    });
  } else {
    let sectionId = null;
    const formattedVerificationTypes = sortByKey(
      clearArray(convertHashmapToArray(checklistType.verificationTypes)),
      'position'
    );
    formattedVerificationTypes.forEach(type => {
      if (typeof(type.id) === 'string') {
        sectionId = parseInt(
          type.id.replace('s_', ''),
        );
        return;
      }
      verificationTypeIds.push([
        parseInt(type.id), sectionId,
      ]);
    });
  }
  checklistType.verificationTypeIds = verificationTypeIds;
  delete checklistType.id;
  delete checklistType.isLoading;
  delete checklistType.error;
  delete checklistType.verificationTypes;
  delete checklistType.materials;
  delete checklistType.partTypes;
  delete checklistType.entity;
  delete checklistType.locations;
  delete checklistType.changed;
  delete checklistType.changedUser;
  delete checklistType.created;
  delete checklistType.createdUser;
  delete checklistType.checklists;
  delete checklistType.attachments;

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/qc/checklist_types`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      toSnakeCase(checklistType)
    ),
  })
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      let checklistType = toCamelCase(res);
      dispatch({
        type: types.CREATE_CHECKLIST_TYPE_SUCCESS,
        fakeId,
        checklistType,
      });
      if (successCallback) successCallback(res.id);
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Created!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.CREATE_CHECKLIST_TYPE_ERROR,
        fakeId,
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const saveChecklistType = (id, params, extraParams) => dispatch => {
  const outputLocations = [];
  const outputPartTypes = [];
  const outputMaterials = [];
  Object.keys(params).forEach(key => {
    if (key === 'type') {
      Object.keys(extraParams.entities.list).forEach(typeKey => {
        if (params.type === extraParams.entities.list[typeKey].name) {
          params.entity = {
            id: extraParams.entities.list[typeKey].id,
            name: params.type,
          };
        }
      });
      delete params.type;
      params.partTypes = null;
      params.materials = null;
    } else if (key === 'subtypes') {
      if (extraParams.type.name === 'Part') {
        params.subtypes.forEach(subtype => {
          Object.keys(extraParams.partTypes.list).forEach(subtypeKey => {
            if (subtype === extraParams.partTypes.list[subtypeKey].name || params.subtypes.includes('All')) {
              outputPartTypes.push({
                id: extraParams.partTypes.list[subtypeKey].id,
                name: subtype,
              });
            }
          });
        });
      } else if (extraParams.type.name === 'Material') {
        params.subtypes.forEach(subtype => {
          Object.keys(extraParams.materialTypes.list).forEach(materialTypeKey => {
            if (subtype === extraParams.materialTypes.list[materialTypeKey].name || params.subtypes.includes('All')) {
              outputMaterials.push({
                id: extraParams.materialTypes.list[materialTypeKey].id,
                name: subtype,
              });
            }
          });
        });
      }
      delete params.subtypes;
    } else if (key === 'locations') {
      params.locations.forEach(location => {
        Object.keys(extraParams.locations.list).forEach(locationKey => {
          if (location === extraParams.locations.list[locationKey].name || params.locations.includes('All')) {
            outputLocations.push({
              id: extraParams.locations.list[locationKey].id,
              name: location,
            });
          }
        });
      });
    }
  });
  if (outputLocations.length) params.locations = outputLocations;
  if (outputPartTypes.length) params.partTypes = outputPartTypes;
  if (outputMaterials.length) params.materials = outputMaterials;

  dispatch({
    type: types.SAVE_CHECKLIST_TYPE,
    id,
    params,
  });
};

export const editChecklistType = ({
  params, checklistType, entities, partTypes, materialTypes, locations, sortedVerifications, successCallback,
}) => dispatch => {
  if (params) {
    Object.keys(params).forEach(key => {
      if (key === 'name') {
        checklistType.name = params[key];
      } else if (key === 'description') {
        checklistType.description = params[key];
      } else if (key === 'type') {
        checklistType.entityId = parseInt(
          Object.keys(entities).find(findKey => {
            return params[key] === entities[findKey].name;
          }),
        );
      } else if (key === 'locations') {
        checklistType.locationIds = [];
        params[key].forEach(location => {
          Object.keys(locations).forEach(locationKey => {
            if (location === locations[locationKey].name || params[key].includes('All')) {
              checklistType.locationIds.push(locations[locationKey].id);
            }
          });
        });
      }
    });
  }

  dispatch({
    type: types.EDIT_CHECKLIST_TYPE,
    id: checklistType.id,
  });

  if (!checklistType.entityId) {
    checklistType.entityId = checklistType.entity.id;
  }
  if (checklistType.entityId === 1) { // Part type
    checklistType.partTypeIds = [];
    if (params && params.subtypes) {
      params.subtypes.forEach(subtype => {
        Object.keys(partTypes).forEach(partTypeKey => {
          if (subtype === partTypes[partTypeKey].name || params.subtypes.includes('All')) {
            checklistType.partTypeIds.push(partTypes[partTypeKey].id);
          }
        });
      });
    } else if (checklistType.partTypes) {
      checklistType.partTypes.forEach(checklistPartType => {
        Object.keys(partTypes).forEach(partTypeKey => {
          if (checklistPartType.id === partTypes[partTypeKey].id) {
            checklistType.partTypeIds.push(partTypes[partTypeKey].id);
          }
        });
      });
    }
  } else if (checklistType.entityId === 2) { // Material type
    checklistType.materialIds = [];
    if (params && params.subtypes) {
      params.subtypes.forEach(subtype => {
        Object.keys(materialTypes).forEach(materialKey => {
          if (subtype === materialTypes[materialKey].name || params.subtypes.includes('All')) {
            checklistType.materialIds.push(materialTypes[materialKey].id);
          }
        });
      });
    } else if (checklistType.materials) {
      checklistType.materials.forEach(checklistMaterial => {
        Object.keys(materialTypes).forEach(materialKey => {
          if (checklistMaterial.id === materialTypes[materialKey].id) {
            checklistType.materialIds.push(materialTypes[materialKey].id);
          }
        });
      });
    }
  }
  if (!checklistType.locationIds && checklistType.locations) {
    checklistType.locationIds = [];
    checklistType.locations.forEach(location => {
      checklistType.locationIds.push(location.id);
    });
  }
  const verificationTypeIds = []
  if (sortedVerifications) {
    let sectionId = null;
    sortedVerifications.forEach(verification => {
      if (typeof(verification.id) === 'string') {
        sectionId = parseInt(
          verification.id.replace('s_', ''),
        );
        return;
      }
      verificationTypeIds.push([verification.id, sectionId]);
    });
  } else {
    let sectionId = null;
    const formattedVerificationTypes = sortByKey(
      clearArray(convertHashmapToArray(checklistType.verificationTypes)),
      'position'
    );
    formattedVerificationTypes.forEach(type => {
      if (typeof(type.id) === 'string') {
        sectionId = parseInt(
          type.id.replace('s_', ''),
        );
        return;
      }
      verificationTypeIds.push([
        parseInt(type.id), sectionId,
      ]);
    });
  }
  checklistType.verificationTypeIds = verificationTypeIds;
  const id = checklistType.id;
  delete checklistType.id;
  delete checklistType.isLoading;
  delete checklistType.error;
  delete checklistType.changed;
  delete checklistType.changedUser;
  delete checklistType.created;
  delete checklistType.createdUser;
  delete checklistType.checklist;
  delete checklistType.materials;
  delete checklistType.partTypes;
  delete checklistType.entity;
  delete checklistType.checklists;
  delete checklistType.verificationTypes;
  delete checklistType.locations;
  delete checklistType.attachments;

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/qc/checklist_types/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      toSnakeCase(checklistType)
    ),
  })
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      let checklistType = toCamelCase(res);
      dispatch({
        type: types.EDIT_CHECKLIST_TYPE_SUCCESS,
        checklistType,
      });
      if (successCallback) successCallback();
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Changed!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.EDIT_CHECKLIST_TYPE_ERROR,
        id,
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const removeChecklistType = (id, successCallback) => dispatch => {
  dispatch({
    type: types.REMOVE_CHECKLIST_TYPE,
    id,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/qc/checklist_types/${id}`, {
    method: 'DELETE',
  })
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      dispatch({
        type: types.REMOVE_CHECKLIST_TYPE_SUCCESS,
        id,
      });
      successCallback();
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Removed!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.REMOVE_CHECKLIST_TYPE_ERROR,
        id,
        error: formatText(res.error),
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const addVerificationTypes = (checklistTypeId, entities, type) => dispatch => {
  entities.forEach(entity => {
    if (typeof(entity.id) === 'number' && type === 'section') {
      entity.id = 's_' + entity.id;
    };
  });
  entities = convertArrayToHashmap(entities);
  dispatch({
    type: types.ADD_VERIFICATION_TYPES,
    checklistTypeId,
    verifications: entities,
  });
};

export const deleteVerificationType = (checklistTypeId, id, type) => dispatch => {
  if (typeof(id) === 'number' && type === 'section') {
    id = 's_' + id;
  };

  dispatch({
    type: types.DELETE_VERIFICATION_TYPE,
    checklistTypeId,
    id,
  });
};

export const deleteVerificationTypes = (checklistTypeId, ids, type) => dispatch => {
  ids.forEach(id => {
    if (typeof(ids[0]) === 'number' && type === 'section') id = 's_' + id;

    dispatch({
      type: types.DELETE_VERIFICATION_TYPE,
      checklistTypeId,
      id,
    });
  });
};

export const convertVerificationTypes = (type, id, checklistVerificationTypes, verificationTypes) => dispatch => {
  const ouputVerificationTypes = []
  Object.keys(checklistVerificationTypes).forEach(id => {
    if (!id.includes('s_') && verificationTypes[id]) {
      checklistVerificationTypes[id].name = verificationTypes[id].name;
      ouputVerificationTypes.push(checklistVerificationTypes[id]);
    }
  });

  dispatch({
    type: types.CONVERT_VERIFICATION_TYPES,
    id,
    verificationTypes: convertArrayToHashmap(
      formatVerificationTypes(ouputVerificationTypes, type),
    ),
  });
};
