import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChecklistsComponent from '../../components/checklists';
import Filters from '../../components/filters';
import { fetchProfile } from '../profile/actions';
import { fetchChecklistTypes } from '../../components/checklist-types/actions';

import s from './index.module.scss';


const Checklists = () => {
  const profile = useSelector(state => state.profile);
  const checklistTypes = useSelector(state => state.checklistTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProfile());
    dispatch(fetchChecklistTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.checklists}>
      <ChecklistsComponent />
      {profile.name && checklistTypes.list &&
        <Filters type='checklists' types={checklistTypes} />}
    </div>
  );
};

export default Checklists;
