import {
  getAPIAddress, toCamelCase, handleRes, formatText, handleError, convertMmToInches, formatVerificationType,
  convertArrayToHashmap,
} from '../../helpers';
import * as types from '../../constants';


export const fetchSteelFrame = id => dispatch => {
  dispatch({
    type: types.FETCH_STEEL_FRAME,
    id,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/steel_frames/${id}`)
    .then(res => handleRes(res))
    .then(res => {
      if (!res.name) throw res;

      let steelFrame = toCamelCase(res);
      steelFrame.qcChecklists = convertArrayToHashmap(steelFrame.qcChecklists);
      const MEDIA_MIMES = ['image/jpeg', 'image/png', 'video/mp4'];
      steelFrame.media = steelFrame.attachments.filter(attachment => {
        if (MEDIA_MIMES.includes(attachment.type.mime)) return attachment;
        return null;
      });
      steelFrame.media = convertArrayToHashmap(steelFrame.media);
      steelFrame.attachments = convertArrayToHashmap(steelFrame.attachments);
      Object.keys(steelFrame.qcChecklists).forEach(id => {
        steelFrame.qcChecklists[id].verifications.forEach(verification => {
          if (verification.measurement && steelFrame.qcChecklists[id].type.isImperial) {
            verification.measurement = convertMmToInches(verification.measurement);
          }
          verification.type = formatVerificationType(
            verification.type,
            steelFrame.qcChecklists[id].type && steelFrame.qcChecklists[id].type.isImperial ? 'Imperial' : 'Metrical',
          );
        });

        steelFrame.qcChecklists[id].verifications = convertArrayToHashmap(steelFrame.qcChecklists[id].verifications);
        steelFrame.qcChecklists[id].media = steelFrame.qcChecklists[id].attachments.filter(attachment => {
          if (MEDIA_MIMES.includes(attachment.type.mime)) return attachment;
          return null;
        });
        steelFrame.qcChecklists[id].media = convertArrayToHashmap(steelFrame.qcChecklists[id].media);
        steelFrame.qcChecklists[id].attachments = convertArrayToHashmap(steelFrame.qcChecklists[id].attachments);
      });
      dispatch({
        type: types.FETCH_STEEL_FRAME_SUCCESS,
        id,
        steelFrame,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_STEEL_FRAME_ERROR,
        id,
        error: formatText(res.error),
      });
    });
};

