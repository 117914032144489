import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom'
import {
  Box, Table, TableBody, TableCell, TableRow, Text, TextInput, Form,
} from 'grommet';
import {
  Logout,
} from 'grommet-icons';
import Moment from 'react-moment';
import $ from 'jquery';

import { sortChecklists, objectIsEmpty } from '../../helpers';
import Loader from '../loader';
import AuthCheck from '../auth-check';
import Header from '../header';
import Attachments from '../attachments';
import QC from '../qc';
import Media from '../media';
import { fetchBarrel } from './actions';
import { fetchStages } from '../stages/actions';
import { fetchChecklistResolutions } from '../checklist/actions';
import { fetchChecklistTypes } from '../checklist-types/actions';
import { findBarrelBySerial } from '../../pages/barrels/actions';
import { getPartName } from '../../helpers';

import s from './index.module.scss';


const Barrel = () => {
  const barrels = useSelector(state => state.barrels);
  const stages = useSelector(state => state.stages);
  const checklistResolutions = useSelector(state => state.checklistResolutions);
  const checklistTypes = useSelector(state => state.checklistTypes);
  const profile = useSelector(state => state.profile);
  const { barrelId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      fetchBarrel(barrelId),
    );
    dispatch(fetchStages());
    dispatch(fetchChecklistResolutions());
    dispatch(fetchChecklistTypes());

    let prevBarcodeValue;
    const interval = setInterval(() => {
      const value = $('#barcode').val();
      if (value && value !== prevBarcodeValue) {
        dispatch(
          findBarrelBySerial(value, goToBarrel),
        );
      }
      prevBarcodeValue = value;
    }, 250);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barrelId]);

  let barrel;
  let sortedChecklists;
  if (barrels.list && barrels.list[barrelId] && stages.isLoading === false) {
    barrel = barrels.list[barrelId];
    if (barrel.qcChecklists && !objectIsEmpty(barrel.qcChecklists)) {
      sortedChecklists = sortChecklists(barrel.qcChecklists, stages.list);
    }
  }

  const goToBarrel = id => {
    history.push(`/barrels/${id}`);
  };

  const getFormat = created => {
    const date = new Date(created);
    const currentDate = new Date();
    if (currentDate.getMonth() === date.getMonth() && currentDate.getYear() === date.getYear()) {
      if (currentDate.getDate() === date.getDate()) {
        return '[Today]';
      } else if (currentDate.getDate() - 1 === date.getDate()) {
        return '[Yesterday]';
      }
    }
    if (currentDate.getFullYear() === date.getFullYear()) {
      return 'MMMM Do, dddd';
    } else {
      return 'MMMM Do, y';
    }
  };

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return barrel && barrel.isLoading === false && stages.isLoading === false
      && checklistResolutions.isLoading === false ?
    barrel && !barrel.error ?
      <Box className={s.barrel}>
        <Header type='barrel' entity={barrel} />
        <Box margin={{left: '13px', right: '20px'}}>
          <Box margin={{left: '10px', bottom: '10px', top: '5px'}}>
            <Form>
              <TextInput id='barcode' name='barcode' placeholder="Scan material's barcode here" />
            </Form>
          </Box>
          <Box className={s.blockWithSep}>
            <Text margin='small' weight='bold'>Basic info:</Text>
            <Table margin={{bottom: 'small'}}>
              <TableBody className={s.info}>
                <TableRow>
                  <TableCell>
                    <Text className={s.name}>Serial:</Text>
                  </TableCell>
                  <TableCell>{barrel.odooLink
                    ? <Link to={{ pathname: barrel.odooLink }} target='_blank' className={`link ${s.odoo}`}>
                        <Logout />
                        {barrel.serial}
                      </Link>
                    : <Text>{barrel.serial}</Text>}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Text className={s.name}>Type:</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{barrel.material.name}</Text>
                  </TableCell>
                </TableRow>
                {barrel.description &&
                  <TableRow>
                    <TableCell>
                      <Text className={s.name}>Description:</Text>
                    </TableCell>
                    <TableCell>
                      <Text>{barrel.description}</Text>
                    </TableCell>
                  </TableRow>}
                <TableRow>
                  <TableCell>
                    <Text className={s.name}>Manufactured:</Text>
                  </TableCell>
                  <TableCell>
                    <Moment format='ll'>{barrel.manufactured}</Moment>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Text className={s.name}>Expiration date:</Text>
                  </TableCell>
                  <TableCell>
                    <Moment format='ll'>{barrel.expired}</Moment>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Text className={s.name}>Is NMI:</Text>
                  </TableCell>
                  <TableCell>{barrel.isNewMaterialIntroduction ? 'Yes' : 'No'}</TableCell>
                </TableRow>
                {barrel.viscosity ?
                  <TableRow>
                    <TableCell>
                      <Text className={s.name}>Viscosity:</Text>
                    </TableCell>
                    <TableCell>
                      <Text>{barrel.viscosity}</Text>
                    </TableCell>
                  </TableRow>
                : null}
                {barrel.viscosity ?
                  <TableRow>
                    <TableCell>
                      <Text className={s.name}>Consistency:</Text>
                    </TableCell>
                    <TableCell>
                      <Text>{barrel.consistency}</Text>
                    </TableCell>
                  </TableRow>
                : null}
                {barrel.grossWeight &&
                  <TableRow>
                    <TableCell>
                      <Text className={s.name}>Gross weight:</Text>
                    </TableCell>
                    <TableCell>
                      <Text>{barrel.grossWeight}</Text>
                    </TableCell>
                  </TableRow>}
                {barrel.netWeight &&
                  <TableRow>
                    <TableCell>
                      <Text className={s.name}>Net weight:</Text>
                    </TableCell>
                    <TableCell>
                      <Text>{barrel.netWeight}</Text>
                    </TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </Box>
          {Object.keys(barrel.parts).length > 0 &&
            <Box>
              <Text margin={{top: 'medium', left: 'small', right: 'small', bottom: 'small'}} weight='bold'>
                Parts:
              </Text>
              <Table margin={{bottom: 'small'}}>
                <TableBody>
                  {Object.keys(barrel.parts).map(id =>
                    <TableRow key={id}>
                      <TableCell className={s.name}>
                        <Link to={`/parts/${id}`} className='link'>
                          {getPartName(barrel.parts[id])}
                        </Link>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>}
          <QC
            entity={barrel} checklists={sortedChecklists} stages={stages}
            checklistTypes={checklistTypes} profile={profile}
          />
          {barrel.changelog &&
            <Box className={s.blockWithSep}>
              <Text margin={{top: 'medium', left: 'small', right: 'small', bottom: 'medium'}} weight='bold'>
                Last 10 changes:
              </Text>
              <Table>
                <TableBody>
                  {barrel.changelog.map((changelogItem, index) => {
                    let prevDay;
                    if (barrel.changelog[index + 1]) prevDay = new Date(barrel.changelog[index + 1].created).getDate();
                    const currentDay = new Date(changelogItem.created).getDate();

                    return [
                      !index &&
                        <TableRow key={index + 101} className={s.dateRow}>
                          <TableCell colSpan='2'>
                            <Moment format={getFormat(changelogItem.created)}>{changelogItem.created}</Moment>
                          </TableCell>
                        </TableRow>,
                        <TableRow key={index} className={s.row}>
                          <TableCell>
                            {changelogItem.nameData ? changelogItem.nameData.map((nameSection, index) =>
                              <span key={index}>
                                {nameSection.type === 'link' ?
                                  <Link
                                    to={nameSection.url}
                                    className='link'
                                    target={nameSection.url.includes('/api/v1/') ? '_blank' : null}
                                  >
                                    {nameSection.text}
                                  </Link> :
                                  nameSection.type === 'text' ?
                                  <Text>{nameSection.text}</Text> : null}
                              </span>) : <Text>{changelogItem.name}</Text>}
                            {changelogItem.repeated > 1 ? ` (x${changelogItem.repeated})` : null}
                          </TableCell>
                          <TableCell className={s.date}>
                            <>
                              <Moment format='hh:mm A'>{changelogItem.created}</Moment>
                              <br />
                              <Text> by {changelogItem.createdUser.name}</Text>
                            </>
                          </TableCell>
                        </TableRow>,
                        prevDay !== currentDay && barrel.changelog[index + 1] &&
                        <TableRow key={index + 102} className={s.dateRow}>
                          <TableCell colSpan='2' margin={{top: 'medium'}}>
                            <Moment format={getFormat(barrel.changelog[index + 1].created)}>
                              {barrel.changelog[index + 1].created}
                            </Moment>
                          </TableCell>
                        </TableRow>]})}
                </TableBody>
              </Table>
            </Box>}
          <Media media={barrel.media} />
          <Attachments attachments={barrel.attachments} />
        </Box>
        <AuthCheck isAuthorized={true} />
      </Box>
    :
      <Box className={s.barrel} align='center'>Nothing found</Box>
  :
    <Loader />
};

export default Barrel;
