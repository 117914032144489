import * as types from '../../constants';


const locations = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_LOCATIONS:
      return {
        ...state,
        isLoading: true,
      };

    case types.FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        list: action.locations,
      };

    case types.FETCH_LOCATIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default locations;
