import {
  getAPIAddress, toCamelCase, toSnakeCase, handleRes, formatText, convertArrayToHashmap, handleError,
} from '../../helpers';

import * as types from '../../constants';


export const fetchSteelFrames = params => dispatch => {
  dispatch({
    type: types.FETCH_STEEL_FRAMES,
    params,
  });

  const APIAdress = getAPIAddress();
  let reqParams = {
    page: 1,
    perPage: 9000,
  };
  if (params) {
    reqParams = {
      page: params.page,
      perPage: 50,
    };
  }
  reqParams = toSnakeCase(reqParams);
  fetch(`${APIAdress}/steel_frames?` + new URLSearchParams(reqParams))
    .then(res => handleRes(res))
    .then(res => {
      if (res.total === undefined) throw res;

      const steelFrames = res;
      Object.keys(steelFrames.list).forEach(id => {
        steelFrames.list[id] = toCamelCase(steelFrames.list[id]);
        steelFrames.list[id].name = id;
        steelFrames.list[id].inventoryDate = steelFrames.list[id]['steelFrames'][0].inventoryDate;
        steelFrames.list[id].qcChecklists = convertArrayToHashmap(steelFrames.list[id].qcChecklists);
      });

      dispatch({
        type: types.FETCH_STEEL_FRAMES_SUCCESS,
        steelFrames,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_STEEL_FRAMES_ERROR,
        error: formatText(res.error),
      });
    });
};
