import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  Box, Button, Form, FormField, TextArea, Text, RadioButtonGroup, Select,
} from 'grommet';
import $ from 'jquery';

import { convertInchesToMm, objectIsEmpty, deepCopy } from '../../helpers';
import Loader from '../loader';
import AuthCheck from '../auth-check';
import Header from '../header';
import Attachments from '../attachments';
import Media from '../media';
import { editChecklist, fetchChecklistResolutions } from './actions';
import { fetchPart } from '../part/actions';
import { fetchBarrel } from '../barrel/actions';
import { fetchSteelFrame } from '../steel-frame/actions';
import { fetchVerificationTypes } from '../verification-types/actions';
import { fetchRejectReasons } from '../reject-reasons/actions';

import s from './index.module.scss';


const Checklist = () => {
  const parts = useSelector(state => state.parts);
  const barrels = useSelector(state => state.barrels);
  const steelFrames = useSelector(state => state.steelFrames);
  const verificationTypes = useSelector(state => state.verificationTypes);
  const checklistResolutions = useSelector(state => state.checklistResolutions);
  const rejectReasons = useSelector(state => state.rejectReasons);
  const profile = useSelector(state => state.profile);
  const { partId, barrelId, steelFrameName, checklistId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (partId) {
      dispatch(
        fetchPart(partId),
      );
    }
    if (barrelId) {
      dispatch(
        fetchBarrel(barrelId),
      );
    }
    if (steelFrameName) {
      dispatch(
        fetchSteelFrame(steelFrameName),
      );
    }
    dispatch(fetchChecklistResolutions());
    dispatch(fetchRejectReasons());
    dispatch(fetchVerificationTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistId]);

  let checklist;
  let part;
  if (partId && parts.list && parts.list[partId]) {
    part = parts.list[partId];
    if (part.qcChecklists && part.qcChecklists[checklistId]) {
      checklist = part.qcChecklists[checklistId];
      if (parts.list[partId].qcChecklists[checklistId].resolution &&
        parts.list[partId].qcChecklists[checklistId].resolution.id === 6
      ) {
        $('#rejectReasonField').removeClass('hidden');
      }
    }
  }
  let barrel;
  if (barrelId && barrels.list && barrels.list[barrelId]) {
    barrel = barrels.list[barrelId];
    if (barrel.qcChecklists && barrel.qcChecklists[checklistId]) {
      checklist = barrel.qcChecklists[checklistId];
    }
  }
  let steelFrame;
  if (steelFrameName && steelFrames.list && steelFrames.list[steelFrameName]) {
    steelFrame = steelFrames.list[steelFrameName];
    if (steelFrame.qcChecklists && steelFrame.qcChecklists[checklistId]) {
      checklist = steelFrame.qcChecklists[checklistId];
    }
  }

  const goBack = () => {
    let url;
    if (partId) {
      url = `/parts/${partId}`;
    } else if (barrelId) {
      url = `/barrels/${barrelId}`;
    } else if (steelFrameName) {
      url = `/steel-frames/${steelFrameName}`;
    }
    history.push(url);
  };

  const edit = params => {
    dispatch(
      editChecklist(
        partId, barrelId, steelFrameName, checklistId, part, barrel, steelFrame, profile,
        rejectReasons.list, params.value, goBack,
      ),
    );
  };

  const autoSave = params => {
    dispatch(
      editChecklist(
        partId, barrelId, steelFrameName, checklistId, part, barrel, steelFrame, profile,
        rejectReasons.list, params,
      ),
    );
  };

  const showDrawings = e => {
    const button = $(e.target);
    if (button.next().hasClass('hidden')) {
      button.closest('div').children('img').removeClass('hidden');
      button.text('Hide drawings');
    } else {
      button.closest('div').children('img').addClass('hidden');
      button.text('Show drawings');
    }
  };

  const changeRadio = (e, id) => {
    autoSave({
      [`accepted-${id}`]: e.target.value === 'true' ? true : e.target.value === 'false' ? false : null,
    });
  };

  const calculateResults = (e, id, params) => {
    if (e.target.value) {
      let value;
      if (checklist.type.isImperial) {
        value = convertInchesToMm(e.target.value);
      } else {
        value = parseFloat(e.target.value.replace(',', '.'));
      }

      if (params.isAcceptanceDisabled) {
        autoSave({
          [`accepted-${id}`]: null,
          [`measurement-${id}`]: value,
        });
        return;
      }

      autoSave({
        [`accepted-${id}`]:
          value > (params.reference + params.deviationEnd) || value < (params.reference + params.deviationStart),
        [`measurement-${id}`]: value,
      });
      return;
    }
    autoSave({
      [`accepted-${id}`]: null,
      [`measurement-${id}`]: null,
    });
  };

  const changeResolution = e => {
    const resolutionId = parseInt(e.target.value);
    const params = { resolution: resolutionId };
    if ([5, 6].includes(resolutionId)) {
      $('#rejectReasonField').removeClass('hidden');
    } else {
      $('#rejectReasonField').addClass('hidden');
      params.rejectReason = null;
    }
    autoSave(params);
  };

  const getAttachements = () => {
    const attachments = deepCopy(checklist.attachments);
    checklist.type.attachments.forEach(attachment => {
      if (attachments[attachment.id]) return;
      attachments[attachment.id] = attachment;
    });
    return attachments;
  };

  let resolutions;
  if (checklistResolutions.list) {
    resolutions = Object.keys(checklistResolutions.list).map(id => {
      return {
        label: checklistResolutions.list[id].name,
        value: parseInt(id),
      };
    });
    if (barrel) resolutions = resolutions.filter(resolution => [1, 2, 3].includes(resolution.value));
    if (part || steelFrame) resolutions = resolutions.filter(resolution => [4, 5, 6, 7, 8].includes(resolution.value));
  }

  let drawings = [];
  if (checklist && checklist.type && checklist.type.attachments && checklist.type.attachments.length) {
    checklist.type.attachments.forEach(attachment => {
      if (attachment.type.mime === 'image/jpeg') drawings.push(attachment);
    });
  }

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return ((part && part.isLoading === false) ||
        (barrel && barrel.isLoading === false) ||
        (steelFrame && steelFrame.isLoading === false)) &&
      verificationTypes.isLoading === false && checklistResolutions.isLoading === false ?
    checklist && checklist.verifications && ((part && part.error === false) ||
        (barrel && barrel.error === false) ||
        (steelFrame && steelFrame.error === false)) ?
      <Box className={s.checklist}>
        <Header type='checklist' entity={part || barrel || steelFrame} />
        <Box align='center' margin={{'top': '20px'}} className={s.info} >
          {barrel ?
            <span>Serial: <b>{barrel.serial}</b></span>
          : part ?
            <>
              <span>UUID: <b>{part.uuid}</b></span>
              {part.rfid && !part.rfid.includes(',') && <span>RFID: <b>{part.rfid}</b></span>}
            </>
          : steelFrame ?
            <>
              <span>Name: <b>{steelFrame.name}</b></span>
            </>
          : null}
        </Box>
        <Box margin={{left: '25px'}}>
          <Form onSubmit={edit}>
            <Box margin={{top: 'large', bottom: 'medium'}}>
              {Object.keys(checklist.verifications).map((id, index) => {
                let prevId;
                if (index) prevId = checklist.verifications[Object.keys(checklist.verifications)[index - 1]].id;

                return <Box key={id} className='jqCheckpoint'>
                  {((!prevId && checklist.verifications[id].section) ||
                      (checklist.verifications[id].section && (checklist.verifications[prevId].section &&
                        (checklist.verifications[id].section.id !== checklist.verifications[prevId].section.id ||
                          !checklist.verifications[prevId].section)))) &&
                    <Text weight='bold' margin={{top: prevId && 'large', bottom: 'small'}}>
                      {checklist.verifications[id].section.name}
                    </Text>}
                  {(barrel && checklist.verifications[id].type.id === 39) ?
                    <>
                      <Box className={s.verification} pad={{'vertical': 'xsmall'}}>
                        <Text className={s.label}>Manufacturer data:</Text>
                        <div className={s.text}>{barrel.consistency || '—'}</div>
                      </Box>
                      <Box className={s.verification} pad={{'vertical': 'xsmall'}}>
                        <Text className={s.label}>Measured data:</Text>
                        <FormField htmlFor={`measurement-${id}`} className={s.description}>
                          <TextArea
                            name={`measurement-${id}`}
                            defaultValue={checklist.verifications[id].measurement ||
                              (checklist.verifications[id].description &&
                                Math.round((
                                  barrel.consistency + parseFloat(checklist.verifications[id].description)
                                ) * 100) / 100
                              )
                            }
                            placeholder='Meas.'
                            inputMode='numeric'
                            onBlur={e => autoSave({
                              [`measurement-${id}`]: e.target.value,
                            })}
                          />
                        </FormField>
                      </Box>
                    </>
                  :
                    <Box className={s.verification} pad={{'vertical': 'xsmall'}}>
                      <Text className={s.label}>
                        {checklist.verifications[id].type.name}
                      </Text>
                      <div
                        className={checklist.verifications[id].type.isAcceptanceDisabled ?
                          s.emptyCheckboxes : s.checkboxes}
                      >
                        <RadioButtonGroup
                          name={id}
                          options={[{
                            label: 'OK',
                            value: false,
                          }, {
                            label: 'NOK',
                            value: true,
                          }]}
                          onChange={e => changeRadio(e, id)}
                          value={checklist.verifications[id].accepted}
                          className={s.checkbox}
                        />
                      </div>
                      {(checklist.verifications[id].type.showMeasurement
                          || (checklist.verifications[id].type.deviationStart !== null
                            && checklist.verifications[id].type.reference !== null)) &&
                        <FormField htmlFor={`measurement-${id}`} className={`${s.description} ${s.descriptionSmall}`}>
                          <TextArea
                            name={`measurement-${id}`}
                            onBlur={e => calculateResults(e, id, checklist.verifications[id].type)}
                            placeholder='Meas.'
                            defaultValue={
                              (checklist.verifications[id].measurement
                                && checklist.verifications[id].measurement !== 0) ?
                              checklist.verifications[id].measurement :
                              checklist.verifications[id].type.defaultMeasurement ?
                              checklist.verifications[id].type.defaultMeasurement : null}
                            inputMode='numeric'
                          />
                        </FormField>}
                      <FormField
                        htmlFor={`description-${id}`}
                        className={`
                          ${s.description}
                          ${(checklist.verifications[id].type.showMeasurement
                              || (checklist.verifications[id].type.deviationStart !== null
                                && checklist.verifications[id].type.reference !== null)) ?
                            s.descriptionSmall : ''}
                        `}
                      >
                        <TextArea
                          name={`description-${id}`}
                          placeholder='Desc.'
                          defaultValue={checklist.verifications[id].description
                            || checklist.verifications[id].type.defaultDescription}
                          onBlur={e => autoSave({
                            [`description-${id}`]: e.target.value,
                          })}
                        />
                      </FormField>
                    </Box>}
                </Box>})}
            </Box>
            <Text weight='bold'>
              {`${part ? 'Part' : barrel ? 'Material' : steelFrame ? 'Steel Frame' : null} should be:`}
            </Text>
            <Box margin={{top: 'medium'}}>
              <RadioButtonGroup
                name='resolution'
                options={resolutions}
                defaultValue={checklist.resolution && checklist.resolution.id}
                className={s.summary}
                onChange={changeResolution}
              />
              {!objectIsEmpty(rejectReasons.list) &&
                <FormField id='rejectReasonField' label='Reason' name='rejectReason' className={`${s.reason} hidden`}>
                  <Select
                    id='rejectReason'
                    name='rejectReason'
                    placeholder='Select reason'
                    onChange={e => autoSave({
                      rejectReason: e.target.value,
                    })}
                    options={Object.keys(rejectReasons.list).map(key => rejectReasons.list[key].name)}
                    value={checklist.rejectReason && checklist.rejectReason.name}
                  />
              </FormField>}
              <FormField htmlFor='description' className={s.description}>
                <TextArea
                  name='description'
                  placeholder='Description'
                  defaultValue={checklist.description}
                  onBlur={e => autoSave({
                    description: e.target.value,
                  })}
                />
              </FormField>
            </Box>
            {drawings.length ?
              <Box align='center' className={s.drawings}>
                <span onClick={showDrawings}>Show drawings</span>
                {drawings.map(drawning =>
                  <img
                    key={drawning.id}
                    alt={drawning.name}
                    src={`/api/v1/attachments/${drawning.id}`}
                    className='hidden'
                  />
                )}
              </Box> : null}
            <Box margin={{left: '-10px', top: '10px', bottom: '40px'}}>
              <Media media={checklist.media} />
              <Attachments attachments={getAttachements()} />
            </Box>
            <Box direction='row' margin={{top: 'medium'}}>
              <Button type='submit' primary label='Submit' />
            </Box>
          </Form>
        </Box>
        <AuthCheck isAuthorized={true} />
      </Box>
    :
      <Box className={s.checklist} align='center'>Nothing found</Box>
  :
    <Loader />
};

export default Checklist;
