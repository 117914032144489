import * as types from '../../constants';


export const parts = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_PARTS:
      return {
        ...state,
        ...action.params,
        isLoading: true,
      };

    case types.FETCH_PARTS_SUCCESS:
      return {
        ...state,
        ...action.parts,
        isLoading: false,
        error: false,
      };

    case types.FETCH_PARTS_ERROR:
      return {
        ...state,
        total: 0,
        list: [],
        isLoading: false,
        error: action.error,
      };

    case types.FETCH_PART:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            isLoading: true,
          },
        },
      };

    case types.FETCH_PART_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...action.part,
            isLoading: false,
            error: false,
          },
        },
      };

    case types.FETCH_PART_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            isLoading: false,
            error: action.error,
          },
        },
      };

    case types.EDIT_PART:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...action.part,
            isLoading: action.hideLoader ? false : true,
          },
        },
      };

    case types.EDIT_PART_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
            error: false,
          },
        },
      };

    case types.EDIT_PART_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
            error: action.error,
          },
        },
      };

    case types.REMOVE_PART:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: true,
          },
        },
      };

    case types.REMOVE_PART_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: null,
        },
      };

    case types.REMOVE_PART_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
            error: action.error,
          },
        },
      };

    case types.GENERATE_PART_REPORT:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isGeneratingReportIsLoading: true,
            isGeneratingReport: true,
            isReportReady: false,
          },
        },
      };

    case types.GENERATE_PART_REPORT_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isGeneratingReportIsLoading: false,
            isGeneratingReport: true,
          },
        },
      };

    case types.GENERATE_PART_REPORT_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isGeneratingReportIsLoading: false,
            isGeneratingReport: false,
          },
        },
      };

    case types.PART_REPORT_IS_READY:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isGeneratingReport: false,
            isReportReady: true,
          },
        },
      };

    case types.UPLOAD_PART_ATTACHMENTS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            attachments: {
               ...state.list[action.id].attachments,
              [action.fakeAttachmentId]: {
                ...action.params,
                isLoading: true,
              },
            }
          },
        },
      };

    case types.UPLOAD_PART_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            attachments: {
              ...state.list[action.id].attachments,
              [action.attachmentId]: {
                ...state.list[action.id].attachments[action.fakeAttachmentId],
                id: action.attachmentId,
                isLoading: false,
              },
              [action.fakeAttachmentId]: null,
            },
          },
        },
      };

    case types.UPLOAD_PART_ATTACHMENTS_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            attachments: {
              ...state.list[action.id].attachments,
              [action.fakeAttachmentId]: null,
            },
          },
        },
      };

    case types.ADD_PART_MEDIA:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            media: {
              ...state.list[action.id].media,
              [action.fakeMediaId]: null,
              [action.media.id]: {
                ...state.list[action.id].media[action.fakeMediaId],
                ...action.media,
              },
            },
          },
        },
      };

    case types.REMOVE_PART_ATTACHMENTS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            attachments: {
              ...state.list[action.id].attachments,
              [action.attachmentId]: {
                ...state.list[action.id].attachments[action.attachmentId],
                isLoading: true,
              },
            },
          },
        },
      };

    case types.REMOVE_PART_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            attachments: {
              ...state.list[action.id].attachments,
              [action.attachmentId]: null,
            },
          },
        },
      };

    case types.REMOVE_PART_MEDIA:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            media: {
              ...state.list[action.id].media,
              [action.mediaId]: null,
            },
          },
        },
      };

    case types.UPLOAD_PART_CHECKLIST_ATTACHMENTS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            qcChecklists: {
              ...state.list[action.id].qcChecklists,
              [action.checklistId]: {
                ...state.list[action.id].qcChecklists[action.checklistId],
                attachments: {
                   ...state.list[action.id].qcChecklists[action.checklistId].attachments,
                  [action.fakeAttachmentId]: {
                    ...action.params,
                    isLoading: true,
                  },
                }
              },
            },
          },
        },
      };

    case types.UPLOAD_PART_CHECKLIST_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            qcChecklists: {
              ...state.list[action.id].qcChecklists,
              [action.checklistId]: {
                ...state.list[action.id].qcChecklists[action.checklistId],
                attachments: {
                  ...state.list[action.id].qcChecklists[action.checklistId].attachments,
                  [action.attachmentId]: {
                    ...state.list[action.id].qcChecklists[action.checklistId].attachments[action.fakeAttachmentId],
                    id: action.attachmentId,
                    isLoading: false,
                  },
                  [action.fakeAttachmentId]: null,
                },
              },
            },
          },
        },
      };

    case types.UPLOAD_PART_CHECKLIST_ATTACHMENTS_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            qcChecklists: {
              ...state.list[action.id].qcChecklists,
              [action.checklistId]: {
                ...state.list[action.id].qcChecklists[action.checklistId],
                attachments: {
                  ...state.list[action.id].qcChecklists[action.checklistId].attachments,
                  [action.fakeAttachmentId]: null,
                },
              },
            },
          },
        },
      };

    case types.ADD_PART_CHECKLIST_MEDIA:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            qcChecklists: {
              ...state.list[action.id].qcChecklists,
              [action.checklistId]: {
                ...state.list[action.id].qcChecklists[action.checklistId],
                media: {
                  ...state.list[action.id].qcChecklists[action.checklistId].media,
                  [action.fakeMediaId]: null,
                  [action.media.id]: {
                    ...state.list[action.id].qcChecklists[action.checklistId].media[action.fakeMediaId],
                    ...action.media,
                  },
                },
              },
            },
          },
        },
      };

    case types.REMOVE_PART_CHECKLIST_ATTACHMENTS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            qcChecklists: {
              ...state.list[action.id].qcChecklists,
              [action.checklistId]: {
                ...state.list[action.id].qcChecklists[action.checklistId],
                attachments: {
                  ...state.list[action.id].qcChecklists[action.checklistId].attachments,
                  [action.attachmentId]: {
                    ...state.list[action.id].qcChecklists[action.checklistId].attachments[action.attachmentId],
                    isLoading: true,
                  },
                },
              },
            },
          },
        },
      };

    case types.REMOVE_PART_CHECKLIST_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            qcChecklists: {
              ...state.list[action.id].qcChecklists,
              [action.checklistId]: {
                ...state.list[action.id].qcChecklists[action.checklistId],
                attachments: {
                  ...state.list[action.id].qcChecklists[action.checklistId].attachments,
                  [action.attachmentId]: null,
                },
              },
            },
          },
        },
      };

    case types.REMOVE_PART_CHECKLIST_MEDIA:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            qcChecklists: {
              ...state.list[action.id].qcChecklists,
              [action.checklistId]: {
                ...state.list[action.id].qcChecklists[action.checklistId],
                media: {
                  ...state.list[action.id].qcChecklists[action.checklistId].media,
                  [action.mediaId]: null,
                },
              },
            },
          },
        },
      };

    case types.RESET_PARTS_FILTERS:
      return {
        ...state,
        filters: undefined,
      };

    case types.FILTER_PARTS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters,
        }
      };

    case types.CREATE_PART_CHECKLIST:
      return {
        ...state,
        list: {
          ...state.list,
          [action.entityId]: {
            ...state.list[action.entityId],
            qcChecklists: {
              ...state.list[action.entityId].qcChecklists,
              [action.fakeId]: {
                type: action.checklistType,
                isLoading: true,
              },
            },
          },
        },
      };

    case types.CREATE_PART_CHECKLIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.entityId]: {
            ...state.list[action.entityId],
            qcChecklists: {
              ...state.list[action.entityId].qcChecklists,
              [action.fakeId]: null,
              [action.id]: {
                ...state.list[action.entityId].qcChecklists[action.fakeId],
                id: action.id,
                isLoading: false,
              },
            },
          },
        },
      };

    case types.REMOVE_PART_CHECKLIST:
      return {
        ...state,
        list: {
          ...state.list,
          [action.entityId]: {
            ...state.list[action.entityId],
            qcChecklists: {
              ...state.list[action.entityId].qcChecklists,
              [action.id]: {
                ...state.list[action.entityId].qcChecklists[action.id],
                isLoading: true,
              },
            },
          },
        },
      };

    case types.REMOVE_PART_CHECKLIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.entityId]: {
            ...state.list[action.entityId],
            qcChecklists: {
              ...state.list[action.entityId].qcChecklists,
              [action.id]: null,
            },
          },
        },
      };

    case types.CHANGE_PART_CHECKBOX:
      return {
        ...state,
        list: {
          ...state.list,
          [action.entityId]: {
            ...state.list[action.entityId],
            qcChecklists: {
              ...state.list[action.entityId].qcChecklists,
              [action.checklistId]: {
                ...state.list[action.entityId].qcChecklists[action.checklistId],
                verifications: {
                  ...state.list[action.entityId].qcChecklists[action.checklistId].verifications,
                  [action.id]: {
                    ...state.list[action.entityId].qcChecklists[action.checklistId].verifications[action.id],
                    accepted: action.accepted,
                  },
                },
              },
            },
          },
        },
      };

    case types.RESET_PARTS:
      return {};

    default:
      return state;
  }
};

export const partTypes = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_PART_TYPES:
      return {
        ...state,
        isLoading: true,
      };

    case types.FETCH_PART_TYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        list: action.partTypes,
      };

    case types.FETCH_PART_TYPES_ERROR:
      return {
        ...state,
        total: 0,
        list: [],
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const groupedParts = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_GROUPED_PARTS:
      return {
        ...state,
        ...action.params,
        isLoading: true,
      };

    case types.FETCH_GROUPED_PARTS_SUCCESS:
      return {
        ...state,
        ...action.groupedParts,
        isLoading: false,
        error: false,
      };

    case types.FETCH_GROUPED_PARTS_ERROR:
      return {
        ...state,
        dict: {},
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
