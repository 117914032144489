import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import {
  Box, Table, TableBody, TableCell, TableHeader, TableRow, Text, Pagination, DropButton,
} from 'grommet';
import {
  StatusGood, CircleAlert, Clear,
} from 'grommet-icons';
import Moment from 'react-moment';

import { sortChecklists } from '../../helpers';
import Loader from '../loader';
import AuthCheck from '../auth-check';
import { fetchSteelFrames } from './actions';
import { getPartName } from '../../helpers';

import s from './index.module.scss';


const SteelFrames = () => {
  const steelFrames = useSelector(state => state.steelFrames);
  const stages = useSelector(state => state.stages);
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();

  const changePage = ({ page }) => {
    dispatch(
      fetchSteelFrames({ page }),
    );
  };

  useEffect(() => {
    dispatch(
      fetchSteelFrames({
        page: steelFrames.page || 1,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let steelFramesArray;
  if (steelFrames.total && steelFrames.isLoading === false && stages.isLoading === false) {
    steelFramesArray = Object.keys(steelFrames.list).map(id => {
      const steelFrame = steelFrames.list[id];
      steelFrame.sortedChecklists = sortChecklists(steelFrame.qcChecklists, stages.list);
      return steelFrame;
    });
  }

  const getFormat = inventoryDate => {
    const date = new Date(inventoryDate);
    const currentDate = new Date();
    if (currentDate.getMonth() === date.getMonth() && currentDate.getYear() === date.getYear()) {
      if (currentDate.getDate() === date.getDate()) {
        return '[Today]';
      } else if (currentDate.getDate() - 1 === date.getDate()) {
        return '[Yesterday]';
      }
    }
    if (currentDate.getFullYear() === date.getFullYear()) {
      return 'MMMM Do, dddd';
    } else {
      return 'MMMM Do, y';
    }
  };

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return steelFrames.isLoading === false && stages.isLoading === false ?
    steelFrames.total ?
      <div className={s.steelFrames}>
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>
                <Text className={s.header}>Name</Text>
              </TableCell>
              <TableCell>
                <Text className={s.header}>QC</Text>
              </TableCell>
              <TableCell>
                <Text className={s.header}>Parts Attached</Text>
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {steelFramesArray.map((steelFrame, index) => {
              let prevDay;
              if (steelFramesArray[index + 1]) prevDay = new Date(steelFramesArray[index + 1].inventoryDate).getDate();
              const currentDay = new Date(steelFrame.inventoryDate).getDate();

              return [
                !index &&
                  <TableRow key={index + 101} className={s.dateRow}>
                    <TableCell colSpan='6'>
                      <Moment format={getFormat(steelFrame.inventoryDate)}>{steelFrame.inventoryDate}</Moment>
                    </TableCell>
                  </TableRow>,
                  <TableRow key={steelFrame.name} className={s.row}>
                    <TableCell className={s.name}>
                      <Link to={`/steel-frames/${steelFrame.name}`} className='link'>
                        {steelFrame.name}
                      </Link>
                    </TableCell>
                    <TableCell size='xsmall'>
                      <div className={s.title}>
                        <DropButton
                          dropContent={
                            <Box className={s.drop}>
                              <Table>
                                <TableBody>
                                  {steelFrame.sortedChecklists.length && steelFrame.sortedChecklists.map(checklist =>
                                    checklist && checklist.resolution &&
                                      <TableRow key={checklist.id}>
                                        <TableCell>
                                          <Text
                                            color={[4].includes(checklist.resolution.id) ? '#008000' :
                                              [5, 7].includes(checklist.resolution.id) ? '#ff8d00' :
                                              [6].includes(checklist.resolution.id) ? '#ff0000' : null}
                                          >
                                            {[4].includes(checklist.resolution.id) ?
                                                <StatusGood color='#008000' /> :
                                              [5, 7].includes(checklist.resolution.id) ?
                                                <CircleAlert color='#ff8d00' /> :
                                              [6].includes(checklist.resolution.id) ?
                                                <Clear color='#ff0000' /> : null}
                                            {checklist.resolution.name}
                                          </Text>
                                        </TableCell>
                                        <TableCell>
                                          <Link to={`/steel-frames/${steelFrame.name}/checklists/${checklist.id}`}>
                                            {`${(checklist.type && checklist.type.name) ||
                                                (checklist.stage && checklist.stage.name)
                                              }`}
                                          </Link>
                                        </TableCell>
                                        {checklist.changed &&
                                          <TableCell>
                                            <Moment format='lll'>{checklist.changed}</Moment>
                                            <Text>by {checklist.changedUser.name}</Text>
                                          </TableCell>}
                                      </TableRow>)}
                                </TableBody>
                              </Table>
                            </Box>}
                          dropAlign={{ right: 'left' }}
                        >
                          <div className={s.qc}>
                            {steelFrame.sortedChecklists.length ? steelFrame.sortedChecklists.map(checklist =>
                              checklist.resolution &&
                                ([4].includes(checklist.resolution.id) ?
                                    <div key={checklist.id} className={s.useAsIs} /> :
                                  [5, 7].includes(checklist.resolution.id) ?
                                    <div key={checklist.id} className={s.repair} /> :
                                  [6].includes(checklist.resolution.id) ?
                                    <div key={checklist.id} className={s.scrap} /> : null)
                            ) : null}
                          </div>
                        </DropButton>
                      </div>
                    </TableCell>
                    <TableCell>
                      {steelFrame.parts.length ?
                        steelFrame.parts.map(part =>
                          <span key={part.id}>
                            <Link to={`/parts/${part.id}`} className='link'>
                              {getPartName(part)}
                            </Link>
                            <br />
                          </span>
                        ) : '—'}
                    </TableCell>
                  </TableRow>,
                  prevDay !== currentDay && steelFramesArray[index + 1] &&
                  <TableRow key={index + 102} className={s.dateRow}>
                    <TableCell colSpan='6'>
                      <Moment format={getFormat(steelFramesArray[index + 1].inventoryDate)}>
                        {steelFramesArray[index + 1].inventoryDate}
                      </Moment>
                    </TableCell>
                  </TableRow>]})}
          </TableBody>
        </Table>

        {steelFrames.total > 50 &&
          <Box>
            <Pagination
              className={s.pagination}
              numberItems={steelFrames.total}
              step={50}
              numberMiddlePages={3}
              onChange={changePage}
              page={steelFrames.page}
              alignSelf='center'
            />
          </Box>
        }
        <AuthCheck isAuthorized={true} />
      </div>
    :
      <Box className={s.steelFrames} align='center'>Nothing found</Box>
  :
    <Loader mod={s.loader} />
};

export default SteelFrames;
