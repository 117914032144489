import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as types from '../../constants';


const AuthCheck = (props) => {
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (props.isAuthorized && !profile.isAuthorized) {
      dispatch({
        type: types.CHANGE_IS_AUTHORIZED,
        params: {
          isAuthorized: true,
        },
      });
    } else if (!props.isAuthorized) {
      history.push('/sign-in');
    }
  });

  return null;
};

export default AuthCheck;
