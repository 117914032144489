import { getAPIAddress, handleRes, formatText, handleError } from '../../helpers';
import * as types from '../../constants';


export const findPartByRfid = (rfid, successCallback) => dispatch => {
  if (rfid.length > 100 || rfid.length < 10) {
    let text;
    if (rfid.length > 100) {
      text = 'Id is too long';
    }
    if (rfid.length < 10) {
      text = 'Id is too short';
    }
    dispatch({
      type: types.SHOW_NOTIFICATION,
      params: {
        text,
        type: 'error',
      },
    });
    return;
  }

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/parts?` + new URLSearchParams({
    rfid,
    page: 1,
    per_page: 1,
  }))
    .then(res => handleRes(res))
    .then(res => {
      if (res.total === undefined) throw res;

      successCallback(res.list[0])
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });;
};
