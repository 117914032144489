export const SIGNIN = 'SIGNIN';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_ERROR = 'SIGNIN_ERROR';

export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNOUT_ERROR = 'SIGNOUT_ERROR';

export const CHANGE_IS_AUTHORIZED = 'CHANGE_IS_AUTHORIZED';

export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_ERROR = 'FETCH_PROFILE_ERROR';

export const FETCH_PARTS = 'FETCH_PARTS';
export const FETCH_PARTS_SUCCESS = 'FETCH_PARTS_SUCCESS';
export const FETCH_PARTS_ERROR = 'FETCH_PARTS_ERROR';

export const REMOVE_PART = 'REMOVE_PART';
export const REMOVE_PART_SUCCESS = 'REMOVE_PART_SUCCESS';
export const REMOVE_PART_ERROR = 'REMOVE_PART_ERROR';

export const RESET_PARTS = 'RESET_PARTS';

export const FETCH_PART = 'FETCH_PART';
export const FETCH_PART_SUCCESS = 'FETCH_PART_SUCCESS';
export const FETCH_PART_ERROR = 'FETCH_PART_ERROR';

export const EDIT_PART = 'EDIT_PART';
export const EDIT_PART_SUCCESS = 'EDIT_PART_SUCCESS';
export const EDIT_PART_ERROR = 'EDIT_PART_ERROR';
export const CHANGE_PART_CHECKBOX = 'CHANGE_PART_CHECKBOX';

export const FETCH_GROUPED_PARTS = 'FETCH_GROUPED_PARTS';
export const FETCH_GROUPED_PARTS_SUCCESS = 'FETCH_GROUPED_PARTS_SUCCESS';
export const FETCH_GROUPED_PARTS_ERROR = 'FETCH_GROUPED_PARTS_ERROR';

export const FETCH_DEVICES = 'FETCH_DEVICES';
export const FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS';
export const FETCH_DEVICES_ERROR = 'FETCH_DEVICES_ERROR';

export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_ERROR = 'FETCH_LOCATIONS_ERROR';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export const FETCH_CHECKLIST_TYPES = 'FETCH_CHECKLIST_TYPES';
export const FETCH_CHECKLIST_TYPES_SUCCESS = 'FETCH_CHECKLIST_TYPES_SUCCESS';
export const FETCH_CHECKLIST_TYPES_ERROR = 'FETCH_CHECKLIST_TYPES_ERROR';

export const FETCH_CHECKLIST_TYPE = 'FETCH_CHECKLIST_TYPE';
export const FETCH_CHECKLIST_TYPE_SUCCESS = 'FETCH_CHECKLIST_TYPE_SUCCESS';
export const FETCH_CHECKLIST_TYPE_ERROR = 'FETCH_CHECKLIST_TYPE_ERROR';

export const CREATE_CHECKLIST_TYPE = 'CREATE_CHECKLIST_TYPE';
export const CREATE_CHECKLIST_TYPE_SUCCESS = 'CREATE_CHECKLIST_TYPE_SUCCESS';
export const CREATE_CHECKLIST_TYPE_ERROR = 'CREATE_CHECKLIST_TYPE_ERROR';

export const EDIT_CHECKLIST_TYPE = 'EDIT_CHECKLIST_TYPE';
export const EDIT_CHECKLIST_TYPE_SUCCESS = 'EDIT_CHECKLIST_TYPE_SUCCESS';
export const EDIT_CHECKLIST_TYPE_ERROR = 'EDIT_CHECKLIST_TYPE_ERROR';

export const REMOVE_CHECKLIST_TYPE = 'REMOVE_CHECKLIST_TYPE';
export const REMOVE_CHECKLIST_TYPE_SUCCESS = 'REMOVE_CHECKLIST_TYPE_SUCCESS';
export const REMOVE_CHECKLIST_TYPE_ERROR = 'REMOVE_CHECKLIST_TYPE_ERROR';

export const UPLOAD_CHECKLIST_TYPE_ATTACHMENTS = 'UPLOAD_CHECKLIST_TYPE_ATTACHMENTS';
export const UPLOAD_CHECKLIST_TYPE_ATTACHMENTS_SUCCESS = 'UPLOAD_CHECKLIST_TYPE_ATTACHMENTS_SUCCESS';
export const UPLOAD_CHECKLIST_TYPE_ATTACHMENTS_ERROR = 'UPLOAD_CHECKLIST_TYPE_ATTACHMENTS_ERROR';

export const REMOVE_CHECKLIST_TYPE_ATTACHMENTS = 'REMOVE_CHECKLIST_TYPE_ATTACHMENTS';
export const REMOVE_CHECKLIST_TYPE_ATTACHMENTS_SUCCESS = 'REMOVE_CHECKLIST_TYPE_ATTACHMENTS_SUCCESS';
export const REMOVE_CHECKLIST_TYPE_ATTACHMENTS_ERROR = 'REMOVE_CHECKLIST_TYPE_ATTACHMENTS_ERROR';

export const SAVE_CHECKLIST_TYPE = 'SAVE_CHECKLIST_TYPE';

export const FETCH_VERIFICATION_TYPES = 'FETCH_VERIFICATION_TYPES';
export const FETCH_VERIFICATION_TYPES_SUCCESS = 'FETCH_VERIFICATION_TYPES_SUCCESS';
export const FETCH_VERIFICATION_TYPES_ERROR = 'FETCH_VERIFICATION_TYPES_ERROR';

export const ADD_VERIFICATION_TYPES = 'ADD_VERIFICATION_TYPES';
export const CONVERT_VERIFICATION_TYPES = 'CONVERT_VERIFICATION_TYPES';
export const DELETE_VERIFICATION_TYPE = 'DELETE_VERIFICATION_TYPE';

export const ADD_VERIFICATION_TYPE = 'ADD_VERIFICATION_TYPE';
export const ADD_VERIFICATION_TYPE_SUCCESS = 'ADD_VERIFICATION_TYPE_SUCCESS';
export const ADD_VERIFICATION_TYPE_ERROR = 'ADD_VERIFICATION_TYPE_ERROR';

export const CREATE_VERIFICATION_TYPE = 'CREATE_VERIFICATION_TYPE';
export const CREATE_VERIFICATION_TYPE_SUCCESS = 'CREATE_VERIFICATION_TYPE_SUCCESS';
export const CREATE_VERIFICATION_TYPE_ERROR = 'CREATE_VERIFICATION_TYPE_ERROR';

export const EDIT_VERIFICATION_TYPE = 'EDIT_VERIFICATION_TYPE';
export const EDIT_VERIFICATION_TYPE_SUCCESS = 'EDIT_VERIFICATION_TYPE_SUCCESS';
export const EDIT_VERIFICATION_TYPE_ERROR = 'EDIT_VERIFICATION_TYPE_ERROR';

export const REMOVE_VERIFICATION_TYPE = 'REMOVE_VERIFICATION_TYPE';
export const REMOVE_VERIFICATION_TYPE_SUCCESS = 'REMOVE_VERIFICATION_TYPE_SUCCESS';
export const REMOVE_VERIFICATION_TYPE_ERROR = 'REMOVE_VERIFICATION_TYPE_ERROR';

export const CHOOSE_VERIFICATION_TYPE = 'CHOOSE_VERIFICATION_TYPE';
export const UNCHOOSE_VERIFICATION_TYPE = 'UNCHOOSE_VERIFICATION_TYPE';
export const RESET_CHOSEN_VERIFICATION_TYPES = 'RESET_CHOSEN_VERIFICATION_TYPES';

export const FETCH_CHECKLIST_RESOLUTIONS = 'FETCH_CHECKLIST_RESOLUTIONS';
export const FETCH_CHECKLIST_RESOLUTIONS_SUCCESS = 'FETCH_CHECKLIST_RESOLUTIONS_SUCCESS';
export const FETCH_CHECKLIST_RESOLUTIONS_ERROR = 'FETCH_CHECKLIST_RESOLUTIONS_ERROR';

export const FETCH_BARRELS = 'FETCH_BARRELS';
export const FETCH_BARRELS_SUCCESS = 'FETCH_BARRELS_SUCCESS';
export const FETCH_BARRELS_ERROR = 'FETCH_BARRELS_ERROR';

export const REMOVE_BARREL = 'REMOVE_BARREL';
export const REMOVE_BARREL_SUCCESS = 'REMOVE_BARREL_SUCCESS';
export const REMOVE_BARREL_ERROR = 'REMOVE_BARREL_ERROR';

export const RESET_BARRELS = 'RESET_BARRELS';

export const FETCH_BARREL = 'FETCH_BARREL';
export const FETCH_BARREL_SUCCESS = 'FETCH_BARREL_SUCCESS';
export const FETCH_BARREL_ERROR = 'FETCH_BARREL_ERROR';

export const EDIT_BARREL = 'EDIT_BARREL';
export const EDIT_BARREL_SUCCESS = 'EDIT_BARREL_SUCCESS';
export const EDIT_BARREL_ERROR = 'EDIT_BARREL_ERROR';
export const CHANGE_BARREL_CHECKBOX = 'CHANGE_BARREL_CHECKBOX';

export const GENERATE_PART_REPORT = 'GENERATE_PART_REPORT';
export const GENERATE_PART_REPORT_SUCCESS = 'GENERATE_PART_REPORT_SUCCESS';
export const GENERATE_PART_REPORT_ERROR = 'GENERATE_PART_REPORT_ERROR';
export const PART_REPORT_IS_READY = 'PART_REPORT_IS_READY';

export const GENERATE_BARREL_REPORT = 'GENERATE_BARREL_REPORT';
export const GENERATE_BARREL_REPORT_SUCCESS = 'GENERATE_BARREL_REPORT_SUCCESS';
export const GENERATE_BARREL_REPORT_ERROR = 'GENERATE_BARREL_REPORT_ERROR';
export const BARREL_REPORT_IS_READY = 'BARREL_REPORT_IS_READY';

export const CREATE_PART_CHECKLIST = 'CREATE_PART_CHECKLIST';
export const CREATE_PART_CHECKLIST_SUCCESS = 'CREATE_PART_CHECKLIST_SUCCESS';
export const CREATE_PART_CHECKLIST_ERROR = 'CREATE_PART_CHECKLIST_ERROR';

export const REMOVE_PART_CHECKLIST = 'REMOVE_PART_CHECKLIST';
export const REMOVE_PART_CHECKLIST_SUCCESS = 'REMOVE_PART_CHECKLIST_SUCCESS';
export const REMOVE_PART_CHECKLIST_ERROR = 'REMOVE_PART_CHECKLIST_ERROR';

export const CREATE_BARREL_CHECKLIST = 'CREATE_BARREL_CHECKLIST';
export const CREATE_BARREL_CHECKLIST_SUCCESS = 'CREATE_BARREL_CHECKLIST_SUCCESS';
export const CREATE_BARREL_CHECKLIST_ERROR = 'CREATE_BARREL_CHECKLIST_ERROR';

export const REMOVE_BARREL_CHECKLIST = 'REMOVE_BARREL_CHECKLIST';
export const REMOVE_BARREL_CHECKLIST_SUCCESS = 'REMOVE_BARREL_CHECKLIST_SUCCESS';
export const REMOVE_BARREL_CHECKLIST_ERROR = 'REMOVE_BARREL_CHECKLIST_ERROR';

export const CREATE_STEEL_FRAME_CHECKLIST = 'CREATE_STEEL_FRAME_CHECKLIST';
export const CREATE_STEEL_FRAME_CHECKLIST_SUCCESS = 'CREATE_STEEL_FRAME_CHECKLIST_SUCCESS';
export const CREATE_STEEL_FRAME_CHECKLIST_ERROR = 'CREATE_STEEL_FRAME_CHECKLIST_ERROR';

export const REMOVE_STEEL_FRAME_CHECKLIST = 'REMOVE_STEEL_FRAME_CHECKLIST';
export const REMOVE_STEEL_FRAME_CHECKLIST_SUCCESS = 'REMOVE_STEEL_FRAME_CHECKLIST_SUCCESS';
export const REMOVE_STEEL_FRAME_CHECKLIST_ERROR = 'REMOVE_STEEL_FRAME_CHECKLIST_ERROR';

export const UPLOAD_BARREL_ATTACHMENTS = 'UPLOAD_BARREL_ATTACHMENTS';
export const UPLOAD_BARREL_ATTACHMENTS_SUCCESS = 'UPLOAD_BARREL_ATTACHMENTS_SUCCESS';
export const UPLOAD_BARREL_ATTACHMENTS_ERROR = 'UPLOAD_BARREL_ATTACHMENTS_ERROR';
export const ADD_BARREL_MEDIA = 'ADD_BARREL_MEDIA';

export const UPLOAD_BARREL_CHECKLIST_ATTACHMENTS = 'UPLOAD_BARREL_CHECKLIST_ATTACHMENTS';
export const UPLOAD_BARREL_CHECKLIST_ATTACHMENTS_SUCCESS = 'UPLOAD_BARREL_CHECKLIST_ATTACHMENTS_SUCCESS';
export const UPLOAD_BARREL_CHECKLIST_ATTACHMENTS_ERROR = 'UPLOAD_BARREL_CHECKLIST_ATTACHMENTS_ERROR';
export const ADD_BARREL_CHECKLIST_MEDIA = 'ADD_BARREL_CHECKLIST_MEDIA';

export const REMOVE_BARREL_ATTACHMENTS = 'REMOVE_BARREL_ATTACHMENTS';
export const REMOVE_BARREL_ATTACHMENTS_SUCCESS = 'REMOVE_BARREL_ATTACHMENTS_SUCCESS';
export const REMOVE_BARREL_ATTACHMENTS_ERROR = 'REMOVE_BARREL_ATTACHMENTS_ERROR';
export const REMOVE_BARREL_MEDIA = 'REMOVE_BARREL_MEDIA';

export const REMOVE_BARREL_CHECKLIST_ATTACHMENTS = 'REMOVE_BARREL_CHECKLIST_ATTACHMENTS';
export const REMOVE_BARREL_CHECKLIST_ATTACHMENTS_SUCCESS = 'REMOVE_BARREL_CHECKLIST_ATTACHMENTS_SUCCESS';
export const REMOVE_BARREL_CHECKLIST_ATTACHMENTS_ERROR = 'REMOVE_BARREL_CHECKLIST_ATTACHMENTS_ERROR';
export const REMOVE_BARREL_CHECKLIST_MEDIA = 'REMOVE_BARREL_CHECKLIST_MEDIA';

export const UPLOAD_PART_ATTACHMENTS = 'UPLOAD_PART_ATTACHMENTS';
export const UPLOAD_PART_ATTACHMENTS_SUCCESS = 'UPLOAD_PART_ATTACHMENTS_SUCCESS';
export const UPLOAD_PART_ATTACHMENTS_ERROR = 'UPLOAD_PART_ATTACHMENTS_ERROR';
export const ADD_PART_MEDIA = 'ADD_PART_MEDIA';

export const UPLOAD_PART_CHECKLIST_ATTACHMENTS = 'UPLOAD_PART_CHECKLIST_ATTACHMENTS';
export const UPLOAD_PART_CHECKLIST_ATTACHMENTS_SUCCESS = 'UPLOAD_PART_CHECKLIST_ATTACHMENTS_SUCCESS';
export const UPLOAD_PART_CHECKLIST_ATTACHMENTS_ERROR = 'UPLOAD_PART_CHECKLIST_ATTACHMENTS_ERROR';
export const ADD_PART_CHECKLIST_MEDIA = 'ADD_PART_CHECKLIST_MEDIA';

export const REMOVE_PART_ATTACHMENTS = 'REMOVE_PART_ATTACHMENTS';
export const REMOVE_PART_ATTACHMENTS_SUCCESS = 'REMOVE_PART_ATTACHMENTS_SUCCESS';
export const REMOVE_PART_ATTACHMENTS_ERROR = 'REMOVE_PART_ATTACHMENTS_ERROR';
export const REMOVE_PART_MEDIA = 'REMOVE_PART_MEDIA';

export const REMOVE_PART_CHECKLIST_ATTACHMENTS = 'REMOVE_PART_CHECKLIST_ATTACHMENTS';
export const REMOVE_PART_CHECKLIST_ATTACHMENTS_SUCCESS = 'REMOVE_PART_CHECKLIST_ATTACHMENTS_SUCCESS';
export const REMOVE_PART_CHECKLIST_ATTACHMENTS_ERROR = 'REMOVE_PART_CHECKLIST_ATTACHMENTS_ERROR';
export const REMOVE_PART_CHECKLIST_MEDIA = 'REMOVE_PART_CHECKLIST_MEDIA';

export const FILTER_BARRELS = 'FILTER_BARRELS';
export const RESET_BARRELS_FILTERS = 'RESET_BARRELS_FILTERS';
export const FILTER_PARTS = 'FILTER_PARTS';
export const RESET_PARTS_FILTERS = 'RESET_PARTS_FILTERS';
export const FILTER_CHECKLISTS = 'FILTER_CHECKLISTS';
export const RESET_CHECKLISTS_FILTERS = 'RESET_CHECKLISTS_FILTERS';

export const FETCH_PART_TYPES = 'FETCH_PART_TYPES';
export const FETCH_PART_TYPES_SUCCESS = 'FETCH_PART_TYPES_SUCCESS';
export const FETCH_PART_TYPES_ERROR = 'FETCH_PART_TYPES_ERROR';

export const FETCH_MATERIALS_TYPES = 'FETCH_MATERIALS_TYPES';
export const FETCH_MATERIALS_TYPES_SUCCESS = 'FETCH_MATERIALS_TYPES_SUCCESS';
export const FETCH_MATERIALS_TYPES_ERROR = 'FETCH_MATERIALS_TYPES_ERROR';

export const FETCH_ENTITIES = 'FETCH_ENTITIES';
export const FETCH_ENTITIES_SUCCESS = 'FETCH_ENTITIES_SUCCESS';
export const FETCH_ENTITIES_ERROR = 'FETCH_ENTITIES_ERROR';

export const FETCH_SECTIONS = 'FETCH_SECTIONS';
export const FETCH_SECTIONS_SUCCESS = 'FETCH_SECTIONS_SUCCESS';
export const FETCH_SECTIONS_ERROR = 'FETCH_SECTIONS_ERROR';

export const CREATE_SECTION = 'CREATE_SECTION';
export const CREATE_SECTION_SUCCESS = 'CREATE_SECTION_SUCCESS';
export const CREATE_SECTION_ERROR = 'CREATE_SECTION_ERROR';

export const EDIT_SECTION = 'EDIT_SECTION';
export const EDIT_SECTION_SUCCESS = 'EDIT_SECTION_SUCCESS';
export const EDIT_SECTION_ERROR = 'EDIT_SECTION_ERROR';

export const REMOVE_SECTION = 'REMOVE_SECTION';
export const REMOVE_SECTION_SUCCESS = 'REMOVE_SECTION_SUCCESS';
export const REMOVE_SECTION_ERROR = 'REMOVE_SECTION_ERROR';

export const CHOOSE_SECTION = 'CHOOSE_SECTION';
export const UNCHOOSE_SECTION = 'UNCHOOSE_SECTION';
export const RESET_CHOSEN_SECTIONS = 'RESET_CHOSEN_SECTIONS';

export const FETCH_LIGHT_CALIBRATIONS = 'FETCH_LIGHT_CALIBRATIONS';
export const FETCH_LIGHT_CALIBRATIONS_SUCCESS = 'FETCH_LIGHT_CALIBRATIONS_SUCCESS';
export const FETCH_LIGHT_CALIBRATIONS_ERROR = 'FETCH_LIGHT_CALIBRATIONS_ERROR';

export const FETCH_LIGHT_CALIBRATION = 'FETCH_LIGHT_CALIBRATION';
export const FETCH_LIGHT_CALIBRATION_SUCCESS = 'FETCH_LIGHT_CALIBRATION_SUCCESS';
export const FETCH_LIGHT_CALIBRATION_ERROR = 'FETCH_LIGHT_CALIBRATION_ERROR';

export const REMOVE_LIGHT_CALIBRATION = 'REMOVE_LIGHT_CALIBRATION';
export const REMOVE_LIGHT_CALIBRATION_SUCCESS = 'REMOVE_LIGHT_CALIBRATION_SUCCESS';
export const REMOVE_LIGHT_CALIBRATION_ERROR = 'REMOVE_LIGHT_CALIBRATION_ERROR';

export const FETCH_CHANGELOG = 'FETCH_CHANGELOG';
export const FETCH_CHANGELOG_SUCCESS = 'FETCH_CHANGELOG_SUCCESS';
export const FETCH_CHANGELOG_ERROR = 'FETCH_CHANGELOG_ERROR';

export const FETCH_REJECT_REASONS = 'FETCH_REJECT_REASONS';
export const FETCH_REJECT_REASONS_SUCCESS = 'FETCH_REJECT_REASONS_SUCCESS';
export const FETCH_REJECT_REASONS_ERROR = 'FETCH_REJECT_REASONS_ERROR';

export const CREATE_REJECT_REASON = 'CREATE_REJECT_REASON';
export const CREATE_REJECT_REASON_SUCCESS = 'CREATE_REJECT_REASON_SUCCESS';
export const CREATE_REJECT_REASON_ERROR = 'CREATE_REJECT_REASON_ERROR';

export const EDIT_REJECT_REASON = 'EDIT_REJECT_REASON';
export const EDIT_REJECT_REASON_SUCCESS = 'EDIT_REJECT_REASON_SUCCESS';
export const EDIT_REJECT_REASON_ERROR = 'EDIT_REJECT_REASON_ERROR';

export const REMOVE_REJECT_REASON = 'REMOVE_REJECT_REASON';
export const REMOVE_REJECT_REASON_SUCCESS = 'REMOVE_REJECT_REASON_SUCCESS';
export const REMOVE_REJECT_REASON_ERROR = 'REMOVE_REJECT_REASON_ERROR';

export const CHOOSE_REJECT_REASON = 'CHOOSE_REJECT_REASON';
export const UNCHOOSE_REJECT_REASON = 'UNCHOOSE_REJECT_REASON';
export const RESET_CHOSEN_REJECT_REASONS = 'RESET_CHOSEN_REJECT_REASONS';

export const FETCH_STAGES = 'FETCH_STAGES';
export const FETCH_STAGES_SUCCESS = 'FETCH_STAGES_SUCCESS';
export const FETCH_STAGES_ERROR = 'FETCH_STAGES_ERROR';

export const CREATE_STAGE = 'CREATE_STAGE';
export const CREATE_STAGE_SUCCESS = 'CREATE_STAGE_SUCCESS';
export const CREATE_STAGE_ERROR = 'CREATE_STAGE_ERROR';

export const EDIT_STAGE = 'EDIT_STAGE';
export const EDIT_STAGE_SUCCESS = 'EDIT_STAGE_SUCCESS';
export const EDIT_STAGE_ERROR = 'EDIT_STAGE_ERROR';

export const REMOVE_STAGE = 'REMOVE_STAGE';
export const REMOVE_STAGE_SUCCESS = 'REMOVE_STAGE_SUCCESS';
export const REMOVE_STAGE_ERROR = 'REMOVE_STAGE_ERROR';

export const CHOOSE_STAGE = 'CHOOSE_STAGE';
export const UNCHOOSE_STAGE = 'UNCHOOSE_STAGE';
export const RESET_CHOSEN_STAGES = 'RESET_CHOSEN_STAGES';

export const FETCH_MANUFACTORING_ORDERS = 'FETCH_MANUFACTORING_ORDERS';
export const FETCH_MANUFACTORING_ORDERS_SUCCESS = 'FETCH_MANUFACTORING_ORDERS_SUCCESS';
export const FETCH_MANUFACTORING_ORDERS_ERROR = 'FETCH_MANUFACTORING_ORDERS_ERROR';

export const FETCH_CHECKLISTS = 'FETCH_CHECKLISTS';
export const FETCH_CHECKLISTS_SUCCESS = 'FETCH_CHECKLISTS_SUCCESS';
export const FETCH_CHECKLISTS_ERROR = 'FETCH_CHECKLISTS_ERROR';

export const FETCH_STEEL_FRAMES = 'FETCH_STEEL_FRAMES';
export const FETCH_STEEL_FRAMES_SUCCESS = 'FETCH_STEEL_FRAMES_SUCCESS';
export const FETCH_STEEL_FRAMES_ERROR = 'FETCH_STEEL_FRAMES_ERROR';

export const FETCH_STEEL_FRAME = 'FETCH_STEEL_FRAME';
export const FETCH_STEEL_FRAME_SUCCESS = 'FETCH_STEEL_FRAME_SUCCESS';
export const FETCH_STEEL_FRAME_ERROR = 'FETCH_STEEL_FRAME_ERROR';

export const EDIT_STEEL_FRAME = 'EDIT_STEEL_FRAME';
export const EDIT_STEEL_FRAME_SUCCESS = 'EDIT_STEEL_FRAME_SUCCESS';
export const EDIT_STEEL_FRAME_ERROR = 'EDIT_STEEL_FRAME_ERROR';

export const UPLOAD_STEEL_FRAME_ATTACHMENTS = 'UPLOAD_STEEL_FRAME_ATTACHMENTS';
export const UPLOAD_STEEL_FRAME_ATTACHMENTS_SUCCESS = 'UPLOAD_STEEL_FRAME_ATTACHMENTS_SUCCESS';
export const UPLOAD_STEEL_FRAME_ATTACHMENTS_ERROR = 'UPLOAD_STEEL_FRAME_ATTACHMENTS_ERROR';
export const ADD_STEEL_FRAME_MEDIA = 'ADD_STEEL_FRAME_MEDIA';

export const REMOVE_STEEL_FRAME_ATTACHMENTS = 'REMOVE_STEEL_FRAME_ATTACHMENTS';
export const REMOVE_STEEL_FRAME_ATTACHMENTS_SUCCESS = 'REMOVE_STEEL_FRAME_ATTACHMENTS_SUCCESS';
export const REMOVE_STEEL_FRAME_ATTACHMENTS_ERROR = 'REMOVE_STEEL_FRAME_ATTACHMENTS_ERROR';
export const REMOVE_STEEL_FRAME_MEDIA = 'REMOVE_STEEL_FRAME_MEDIA';

export const UPLOAD_STEEL_FRAME_CHECKLIST_ATTACHMENTS = 'UPLOAD_STEEL_FRAME_CHECKLIST_ATTACHMENTS';
export const UPLOAD_STEEL_FRAME_CHECKLIST_ATTACHMENTS_SUCCESS = 'UPLOAD_STEEL_FRAME_CHECKLIST_ATTACHMENTS_SUCCESS';
export const UPLOAD_STEEL_FRAME_CHECKLIST_ATTACHMENTS_ERROR = 'UPLOAD_STEEL_FRAME_CHECKLIST_ATTACHMENTS_ERROR';
export const ADD_STEEL_FRAME_CHECKLIST_MEDIA = 'ADD_STEEL_FRAME_CHECKLIST_MEDIA';

export const REMOVE_STEEL_FRAME_CHECKLIST_ATTACHMENTS = 'REMOVE_STEEL_FRAME_CHECKLIST_ATTACHMENTS';
export const REMOVE_STEEL_FRAME_CHECKLIST_ATTACHMENTS_SUCCESS = 'REMOVE_STEEL_FRAME_CHECKLIST_ATTACHMENTS_SUCCESS';
export const REMOVE_STEEL_FRAME_CHECKLIST_ATTACHMENTS_ERROR = 'REMOVE_STEEL_FRAME_CHECKLIST_ATTACHMENTS_ERROR';
export const REMOVE_STEEL_FRAME_CHECKLIST_MEDIA = 'REMOVE_STEEL_FRAME_CHECKLIST_MEDIA';

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR';

export const REMOVE_USER = 'REMOVE_USER';
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS';
export const REMOVE_USER_ERROR = 'REMOVE_USER_ERROR';

export const CHOOSE_USER = 'CHOOSE_USER';
export const UNCHOOSE_USER = 'UNCHOOSE_USER';
export const RESET_CHOSEN_USERS = 'RESET_CHOSEN_USERS';

export const FETCH_USER_ROLES = 'FETCH_USER_ROLES';
export const FETCH_USER_ROLES_SUCCESS = 'FETCH_USER_ROLES_SUCCESS';
export const FETCH_USER_ROLES_ERROR = 'FETCH_USER_ROLES_ERROR';

export const FETCH_THERMO_TIMESTAMPS = 'FETCH_THERMO_TIMESTAMPS';
export const FETCH_THERMO_TIMESTAMPS_SUCCESS = 'FETCH_THERMO_TIMESTAMPS_SUCCESS';
export const FETCH_THERMO_TIMESTAMPS_ERROR = 'FETCH_THERMO_TIMESTAMPS_ERROR';

export const FETCH_THERMO_FRAME = 'FETCH_THERMO_FRAME';
export const FETCH_THERMO_FRAME_SUCCESS = 'FETCH_THERMO_FRAME_SUCCESS';
export const FETCH_THERMO_FRAME_ERROR = 'FETCH_THERMO_FRAME_ERROR';
