import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import {
  Box, Form, TextInput, Button,
} from 'grommet';
import {
  FormPreviousLink, FormClose,
} from 'grommet-icons';
import $ from 'jquery';

import Loader from '../loader';
import AuthCheck from '../auth-check';
import { showNotification } from './actions';

import s from './index.module.scss';


const Scanner = () => {
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let prevRfidValue;
    const interval = setInterval(() => {
      const rfid = $('#rfid');
      rfid.focus();
      const value = rfid.val();
      if (value && value !== prevRfidValue) {
        document.execCommand('copy');
        dispatch(
          showNotification(value),
        );
      }
      prevRfidValue = value;
    }, 250);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clear = () => {
    $('#rfid').val('');
  };

  const goBack = () => {
    history.push('/tools');
  };

  return profile && profile.isLoading === false ?
    profile.error === false ?
      <Box className={s.scanner}>
        <Box direction='row' width='100%' justify='between' className={s.navHeader}>
          <Button className='buttonLink' onClick={goBack}>
            <FormPreviousLink />
            Tools
          </Button>
        </Box>
        <Form className={s.search}>
          <TextInput id='rfid' name='rfid' placeholder="Scan part's rfid here" />
          <FormClose onClick={clear} />
        </Form>
      </Box>
    :
      <AuthCheck isAuthorized={false} />
  :
    <Loader />
};

export default Scanner;
