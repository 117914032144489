import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Box, RangeInput, Text, Table, TableBody, TableRow, TableCell,
} from 'grommet';
import {
  PlayFill, StopFill, CaretPrevious, CaretNext, PauseFill,
} from 'grommet-icons';
import Draggable from 'react-draggable';
import Moment from 'react-moment';

import { objectIsEmpty, getLS } from '../../helpers';
import Loader from '../loader';
import AuthCheck from '../auth-check';
import Header from '../header';
import { fetchTimestamps, fetchFrame } from './actions';
import { fetchPart } from '../part/actions';

import s from './index.module.scss';


const Thermo = () => {
  const parts = useSelector(state => state.parts);
  const thermo = useSelector(state => state.thermo);
  const profile = useSelector(state => state.profile);
  const { partId } = useParams();
  const dispatch = useDispatch();

  const [frameId, setFrameId] = useState(0);
  const [timelineIsBlocked, setTimelineToBlock] = useState(false);
  const [allControlsIsBlocked, setAllControlsToBlock] = useState(false);
  const [isPlaying, setToPlay] = useState(false);
  const [stopped, setToStop] = useState(false);
  const [coordinates, setCoordinates] = useState([50, 50]);
  const [activeCameraId, setActiveCameraId] = useState();

  useEffect(() => {
    dispatch(
      fetchPart(
        partId,
        uuid => {
          dispatch(
            fetchTimestamps(
              uuid,
              (streams, initialCameraId) => {
                setActiveCameraId(initialCameraId);
                dispatch(
                  fetchFrame(
                    uuid,
                    `${initialCameraId}-${frameId}`,
                    streams[initialCameraId].timestamps[0]),
                );
              },
            ),
          );
        },
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partId]);

  const getTemperatures = frameId => {
    if (!timelineIsBlocked) {
      setAllControlsToBlock(true);
      if (!thermo.frames[`${activeCameraId}-${frameId}`]) {
        dispatch(
          fetchFrame(
            part.uuid,
            `${activeCameraId}-${frameId}`,
            thermo.streams[activeCameraId].timestamps[frameId],
            () => {
              setAllControlsToBlock(false);
            },
          ),
        );
      } else {
        setAllControlsToBlock(false);
      }
      setFrameId(frameId);
    }
  };

  const changeFrame = frameId => {
    if (!allControlsIsBlocked && frameId >= 0 && frameId < thermo.streams[activeCameraId].maxFrameId) {
      getTemperatures(frameId);
    }
  };

  useEffect(() => {
    if (isPlaying) {
      dispatch(
        fetchFrame(
          part.uuid,
          `${activeCameraId}-${frameId}`,
          thermo.streams[activeCameraId].timestamps[frameId],
          () => {
            if (stopped) {
              setFrameId(0);
              setToPlay(false);
              setTimelineToBlock(false);
              setToStop(false);
            } else {
              if (frameId >= 0 && frameId < thermo.streams[activeCameraId].maxFrameId) {
                setFrameId(frameId + 1);
              } else {
                setFrameId(0);
                setToPlay(false);
                setTimelineToBlock(false);
                setToStop(false);
              }
            }
          },
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, frameId, stopped]);

  useEffect(() => {
    if (activeCameraId && thermo.frames) getTemperatures(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCameraId]);

  const play = () => {
    if (!allControlsIsBlocked) {
      setTimelineToBlock(true);
      setToPlay(true);
    }
  };

  const pause = () => {
    if (!allControlsIsBlocked) {
      setToPlay(false);
      setTimelineToBlock(false);
    }
  };

  const stop = () => {
    if (!allControlsIsBlocked) {
      if (isPlaying) {
        setTimelineToBlock(true);
        setToStop(true);
      } else {
        setFrameId(0);
      }
    }
  };

  const movePicker = (_, position) => {
    setCoordinates([
      Math.round((position.x + 66) / 2), Math.round((762 - 78 - position.y) / 2),
    ])
  };

  const getFrame = () => {
    return thermo.frames[`${activeCameraId}-${frameId}`] || thermo.frames[`${activeCameraId}-${frameId - 1}`];
  };

  const getTemperaturePoint = (xShift, yShift) => {
    const frame = getFrame();
    if (frame.temperatures[coordinates[0] + xShift] &&
        frame.temperatures[coordinates[0] + xShift][coordinates[1] + yShift] &&
        frame.temperatures[coordinates[0] + xShift][coordinates[1] + yShift] < 1000) {
      return frame.temperatures[coordinates[0] + xShift][coordinates[1] + yShift];
    }
    return '—';
  };

  const getBGcolor = temperature => {
    if (temperature === '—') return s.cell1;

    let index = Math.round(temperature / 10) - 1;
    if (index > 11) index = 11;
    return s[`cell${index}`];
  };

  let part;
  if (parts.list && parts.list[partId]) {
    part = parts.list[partId];
  }

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return part && part.isLoading === false && thermo.isLoading === false &&
      thermo.frames && !objectIsEmpty(thermo.frames) &&
      thermo.streams[activeCameraId] && thermo.streams[activeCameraId].maxFrameId ?
    part && part.error === false ?
      <Box
        className={`
          ${s.thermo} ${allControlsIsBlocked ? s.blocked : ''} ${timelineIsBlocked ? s.timelineBlocked : ''}
        `}
      >
        <Header
          type='thermo' camerasIds={Object.keys(thermo.streams)}
          activeCameraId={activeCameraId} callback={setActiveCameraId}
        />
        <Box className={s.timeline}>
          <RangeInput
            disabled={(allControlsIsBlocked || timelineIsBlocked) && true}
            max={thermo.streams[activeCameraId].maxFrameId}
            value={frameId}
            onChange={event => getTemperatures(
              parseInt(event.target.value),
            )}
          />
        </Box>
        <Box margin={{'top': '20px'}} className={s.content}>
          <Box className={s.video}>
            <span className={s.background}></span>
            {getFrame() &&
              <img
                width='576'
                height='764'
                alt={part.name}
                src={`data:image/png;base64, ${getFrame().image64}`}
              />}
            <Draggable bounds={{top: -77, left: -66, right: 508, bottom: 685}} onDrag={movePicker}>
              <div><div /></div>
            </Draggable>
          </Box>
          <Box width='310px' className={s.player}>
            <Text>
              <Moment format='lll'>{thermo.streams[activeCameraId].timestamps[frameId]}</Moment>
            </Text>
            <Text>{`Frame ${frameId + 1} of ${thermo.streams[activeCameraId].maxFrameId + 1}`}</Text>
            <Box className={s.controls}>
              <CaretPrevious
                size='large'
                color='#242829'
                onClick={() => changeFrame(
                  frameId - (getLS('userSettings') && getLS('userSettings').framesToRewind) || 1
                )}
              />
              {isPlaying ?
                <PauseFill size='large' color='#242829' onClick={pause} /> :
                <PlayFill size='large' color='#242829' onClick={play} />}
              <StopFill size='large' color='#242829' onClick={stop} />
              <CaretNext
                size='large'
                color='#242829'
                onClick={() => changeFrame(
                  frameId + (getLS('userSettings') && getLS('userSettings').framesToRewind) || 1
                )}
              />
            </Box>
            {getFrame() ?
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={getBGcolor(getTemperaturePoint(-2, +2))}>
                      {getTemperaturePoint(-2, +2)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(-1, +2))}>
                      {getTemperaturePoint(-1, +2)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(0, +2))}>
                      {getTemperaturePoint(0, +2)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(+1, +2))}>
                      {getTemperaturePoint(+1, +2)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(+2, +2))}>
                      {getTemperaturePoint(+2, +2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={getBGcolor(getTemperaturePoint(-2, +1))}>
                      {getTemperaturePoint(-2, +1)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(-1, +1))}>
                      {getTemperaturePoint(-1, +1)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(0, +1))}>
                      {getTemperaturePoint(0, +1)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(+1, +1))}>
                      {getTemperaturePoint(+1, +1)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(+2, +1))}>
                      {getTemperaturePoint(+2, +1)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={getBGcolor(getTemperaturePoint(-2, 0))}>
                      {getTemperaturePoint(-2, 0)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(-1, 0))}>
                      {getTemperaturePoint(-1, 0)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(0, 0))}>
                      {getTemperaturePoint(0, 0)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(+1, 0))}>
                      {getTemperaturePoint(+1, 0)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(+2, 0))}>
                      {getTemperaturePoint(+2, 0)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={getBGcolor(getTemperaturePoint(-2, -1))}>
                      {getTemperaturePoint(-2, -1)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(-1, -1))}>
                      {getTemperaturePoint(-1, -1)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(0, -1))}>
                      {getTemperaturePoint(0, -1)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(+1, -1))}>
                      {getTemperaturePoint(+1, -1)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(+2, -1))}>
                      {getTemperaturePoint(+2, -1)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={getBGcolor(getTemperaturePoint(-2, -2))}>
                      {getTemperaturePoint(-2, -2)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(-1, -2))}>
                      {getTemperaturePoint(-1, -2)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(0, -2))}>
                      {getTemperaturePoint(0, -2)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(+1, -2))}>
                      {getTemperaturePoint(+1, -2)}
                    </TableCell>
                    <TableCell className={getBGcolor(getTemperaturePoint(+2, -2))}>
                      {getTemperaturePoint(+2, -2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table> :
              <Loader mod={s.loader} type='icon' />}
          </Box>
        </Box>
        <AuthCheck isAuthorized={true} />
      </Box>
    :
      <Box className={s.thermo} align='center'>Nothing found</Box>
  :
    <Loader />
};

export default Thermo;
