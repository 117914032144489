import * as types from '../../constants';


const checklists = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_CHECKLISTS:
      return {
        ...state,
        ...action.params,
        isLoading: true,
      };

    case types.FETCH_CHECKLISTS_SUCCESS:
      return {
        ...state,
        ...action.checklists,
        isLoading: false,
        error: false,
      };

    case types.FETCH_CHECKLISTS_ERROR:
      return {
        ...state,
        total: 0,
        list: [],
        isLoading: false,
        error: action.error,
      };

    case types.RESET_CHECKLISTS_FILTERS:
      return {
        ...state,
        filters: undefined,
      };

    case types.FILTER_CHECKLISTS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters,
        }
      };

    default:
      return state;
  }
};

export default checklists;
