import { getAPIAddress, handleRes, formatText, toSnakeCase, handleError } from '../../helpers';

import * as types from '../../constants';


export const removeChecklist = (id, partId, barrelId, steelFrameName) => dispatch => {
  let entityType;
  let entityId;
  if (partId) {
    entityType = 'part';
    entityId = partId;
  } else if (barrelId) {
    entityType = 'barrel';
    entityId = barrelId;
  } else if (steelFrameName) {
    entityType = 'steel_frame';
    entityId = steelFrameName;
  }
  let constType = entityType.toUpperCase();

  dispatch({
    type: types[`REMOVE_${constType}_CHECKLIST`],
    entityId,
    id,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/qc/checklists/${id}`, {
    method: 'DELETE',
  })
    .then(res => handleRes(res))
    .then(res => {
      if (res.error) throw res;

      dispatch({
        type: types[`REMOVE_${constType}_CHECKLIST_SUCCESS`],
        entityId,
        id,
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Checklist has been deleted!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const createChecklist = (type, partId, barrelId, steelFrameName) => dispatch => {
  let entityType;
  let entityId;
  let entityIdKey;
  if (partId) {
    entityType = 'part';
    entityId = partId;
  } else if (barrelId) {
    entityType = 'barrel';
    entityId = barrelId;
  } else if (steelFrameName) {
    entityType = 'steel_frame';
    entityId = steelFrameName;
    entityIdKey = 'steelFrame'
  }
  let constType = entityType.toUpperCase();
  if (!entityIdKey) entityIdKey = entityType;
  const fakeId = Math.random();

  dispatch({
    type: types[`CREATE_${constType}_CHECKLIST`],
    entityId,
    fakeId,
    checklistType: type,
  });

  const APIAdress = getAPIAddress();
  const entityIdKeyEnding = steelFrameName ? `${entityIdKey}Name` : `${entityIdKey}Id`;
  fetch(`${APIAdress}/qc/checklists`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      toSnakeCase({
        typeId: type.id,
        [entityIdKeyEnding]: entityId,
        name: type.name,
      }),
    ),
  })
    .then(res => handleRes(res))
    .then(res => {
      if (res.error) throw res;

      dispatch({
        type: types[`CREATE_${constType}_CHECKLIST_SUCCESS`],
        entityId,
        fakeId,
        id: res.id,
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Checklist has been created!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

