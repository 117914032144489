import { reset } from '../parts/actions';
import {
  getAPIAddress, toCamelCase, handleRes, formatText, convertArrayToHashmap, formatVerificationType, convertMmToInches,
  formatChangelog, handleError, toSnakeCase,
} from '../../helpers';
import * as types from '../../constants';


export const fetchPart = (id, successCallback) => dispatch => {
  dispatch({
    type: types.FETCH_PART,
    id,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/parts/${id}?` + new URLSearchParams({
    qc: true,
  }))
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      let part = toCamelCase(res);
      part.barrels = convertArrayToHashmap(part.barrels);
      part.qcChecklists = convertArrayToHashmap(part.qcChecklists);
      const MEDIA_MIMES = ['image/jpeg', 'image/png', 'video/mp4'];
      part.media = part.attachments.filter(attachment => {
        if (MEDIA_MIMES.includes(attachment.type.mime)) return attachment;
        return null;
      });
      part.media = convertArrayToHashmap(part.media);
      part.attachments = convertArrayToHashmap(part.attachments);
      Object.keys(part.qcChecklists).forEach(id => {
        part.qcChecklists[id].verifications.forEach(verification => {
          if (verification.measurement && part.qcChecklists[id].type.isImperial) {
            verification.measurement = convertMmToInches(verification.measurement);
          }
          verification.type = formatVerificationType(
            verification.type,
            part.qcChecklists[id].type && part.qcChecklists[id].type.isImperial ? 'Imperial' : 'Metrical',
          );
        });

        part.qcChecklists[id].verifications = convertArrayToHashmap(part.qcChecklists[id].verifications);
        part.qcChecklists[id].media = part.qcChecklists[id].attachments.filter(attachment => {
          if (MEDIA_MIMES.includes(attachment.type.mime)) return attachment;
          return null;
        });
        part.qcChecklists[id].media = convertArrayToHashmap(part.qcChecklists[id].media);
        part.qcChecklists[id].attachments = convertArrayToHashmap(part.qcChecklists[id].attachments);
      });
      if (part.changelog) part.changelog = formatChangelog(part.changelog);

      dispatch({
        type: types.FETCH_PART_SUCCESS,
        id,
        part,
      });
      if (successCallback) successCallback(part.uuid);
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_PART_ERROR,
        id,
        error: formatText(res.error),
      });
    });
};

export const removePart = (id, successCallback) => dispatch => {
  dispatch({
    type: types.REMOVE_PART,
    id,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/parts/${id}`, {
    method: 'DELETE',
  })
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      dispatch({
        type: types.REMOVE_PART_SUCCESS,
        id,
      });
      dispatch(reset());
      successCallback();
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Removed!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.REMOVE_PART_ERROR,
        id,
        error: formatText(res.error),
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const fetchManufacturingOrders = params => dispatch => {
  dispatch({
    type: types.FETCH_MANUFACTORING_ORDERS,
    params,
  });

  const APIAdress = getAPIAddress();
  let reqParams = {
    page: 1,
    perPage: 9000,
  };

  reqParams = toSnakeCase(reqParams);
  fetch(`${APIAdress}/manufacturing_orders?` + new URLSearchParams(reqParams))
    .then(res => handleRes(res))
    .then(res => {
      if (res.total === undefined) throw res;

      let manufacturingOrders = toCamelCase(res);
      manufacturingOrders.list.forEach(manufacturingOrder => {
        manufacturingOrder.name = `${manufacturingOrder.productId[1]} ${manufacturingOrder.name}`;
      });
      manufacturingOrders.list = convertArrayToHashmap(manufacturingOrders.list);

      dispatch({
        type: types.FETCH_MANUFACTORING_ORDERS_SUCCESS,
        manufacturingOrders,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_MANUFACTORING_ORDERS_ERROR,
        error: formatText(res.error),
      });
    });;
};
