import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import {
  Box, Button, Form, FormField, TextInput,
} from 'grommet';
import $ from 'jquery';

import Loader from '../loader';
import { signIn } from './actions';

import s from './index.module.scss';


const SignIn = () => {
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const history = useHistory();
  const [type, setType] = useState('card');

  useEffect(() => {
    let prevCardIdValue;
    const interval = setInterval(() => {
      const value = $('#cardId').val();
      if (value && value !== prevCardIdValue) {
        dispatch(
          signIn(
            { cardId: value },
            goMain,
          ),
        );
      }
      prevCardIdValue = value;
    }, 250);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goMain = () => {
    history.push('/');
  };

  const submit = params => {
    dispatch(
      signIn(params.value, goMain),
    );
  };

  return !profile.isLoading ?
    <div className={s.signIn}>
      <Box>
        Sign in by&nbsp;
        <span onClick={() => setType('card')} className={type === 'card' ? s.active : ''}>
          your card number
        </span> or <br /> by&nbsp;
        <span onClick={() => setType('basicAuth')} className={type === 'basicAuth' ? s.active : ''}>
          email and password
        </span>
      </Box>
      {type === 'card' ?
        <Form onSubmit={submit}>
          <FormField name='cardId' htmlFor='cardId'>
            <TextInput id='cardId' name='cardId' type='cardId' placeholder="Scan your card id here" />
          </FormField>
        </Form> :
      type === 'basicAuth' ?
        <Form onSubmit={submit}>
          <FormField name='email' htmlFor='email' label='E-mail'>
            <TextInput id='email' name='email' type='email' />
          </FormField>
          <FormField name='password' htmlFor='password' label='Password'>
            <TextInput id='password' name='password' type='password' />
          </FormField>
          <Box direction='row' margin={{top: 'medium'}}>
            <Button type='submit' primary label='Submit' />
          </Box>
        </Form> : null}
    </div>
  :
    <Loader />
};


export default SignIn;
