import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import $ from 'jquery';
import { Grommet, Box, Main } from 'grommet';
import queryString from 'query-string';

import { setCookie, getLS, setLS } from './helpers';
import theme from './theme';
import reducers from './reducers';

import Sidebar from './components/sidebar';
import Notification from './components/notification';
import Profile from './pages/profile';
import SignIn from './pages/sign-in';
import Parts from './pages/parts';
import Part from './pages/part';
import Checklist from './pages/checklist';
import Devices from './pages/devices';
import Locations from './pages/locations';
import Barrels from './pages/barrels';
import Barrel from './pages/barrel';
import ChecklistTypes from './pages/checklist-types';
import ChecklistType from './pages/checklist-type';
import LightCalibrations from './pages/light-calibrations';
import LightCalibration from './pages/light-calibration';
import Sections from './pages/sections';
import VerificationTypes from './pages/verification-types';
import Changelog from './pages/changelog';
import NotFound from './pages/not-found';
import Update from './pages/update';
import Tools from './pages/tools';
import Stages from './pages/stages';
import RejectReasons from './pages/reject-reasons';
import Checklists from './pages/checklists';
import Scanner from './pages/scanner';
import SteelFrames from './pages/steel-frames';
import SteelFrame from './pages/steel-frame';
import UserManagement from './pages/user-management';
import Thermo from './pages/thermo';

import s from './index.module.scss';


const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));
const store = createStore(reducers, composedEnhancer);

const session = queryString.parse(document.location.search).session;
const rememberToken = queryString.parse(document.location.search).rememberToken;
if (session && rememberToken) {
  setCookie('session', session);
  setCookie('remember_token', rememberToken);
}

const VERSION = 'v23.03.2';
setCookie('version', VERSION);

const debug = queryString.parse(document.location.search).debug;
if (debug) {
  const settings = getLS('userSettings') || {};
  setLS(
    'userSettings',
    {
      ...settings,
      debug: debug === 'true' ? true : false
    },
  )
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Grommet className={s.grommet} theme={theme} full>
        <Switch>
          <Route exact path='/'><Redirect to='/parts' /></Route>
          <Route exact path='/sign-in' component={SignIn} />
          <Route exact path='/update' component={Update} />
          <Route exact path='/404' component={NotFound} />
          <Route exact path='/tools/scanner' component={Scanner} />
          <>
            <Box direction='row' height={{min: '100%'}} className={s.root}>
              <Sidebar />
              <Main className={s.main}>
                <Box pad={{'vertical': 'medium', 'horizontal': 'small'}}>
                  <Switch>
                    <Route exact path='/profile' component={Profile} />
                    <Route exact path='/parts' component={Parts} />
                    <Route exact path='/parts/:partId' component={Part} />
                    <Route exact path='/parts/:partId/edit' component={Part} />
                    <Route exact path='/parts/:partId/thermo' component={Thermo} />
                    <Route exact path='/parts/:partId/checklists/:checklistId' component={Checklist} />
                    <Route exact path='/grouped-parts' component={Parts} />
                    <Route exact path='/devices' component={Devices} />
                    <Route exact path='/locations' component={Locations} />
                    <Route exact path='/barrels' component={Barrels} />
                    <Route exact path='/barrels/:barrelId' component={Barrel} />
                    <Route exact path='/barrels/:barrelId/edit' component={Barrel} />
                    <Route exact path='/barrels/:barrelId/checklists/:checklistId' component={Checklist} />
                    <Route exact path='/light-calibrations' component={LightCalibrations} />
                    <Route exact path='/light-calibrations/:lightCalibrationId' component={LightCalibration} />
                    <Route exact path='/tools' component={Tools} />
                    <Route exact path='/tools/checklist-types' component={ChecklistTypes} />
                    <Route exact path='/tools/checklist-types/:checklistTypeId' component={ChecklistType} />
                    <Route exact path='/tools/checklist-types/:checklistTypeId/sections' component={Sections} />
                    <Route
                      exact
                      path='/tools/checklist-types/:checklistTypeId/verification-types'
                      component={VerificationTypes}
                    />
                    <Route exact path='/tools/create-checklist-type' component={ChecklistType} />
                    <Route exact path='/tools/create-checklist-type/sections' component={Sections} />
                    <Route exact path='/tools/create-checklist-type/verification-types' component={VerificationTypes} />
                    <Route exact path='/tools/changelog' component={Changelog} />
                    <Route exact path='/tools/changelog/:partId' component={Changelog} />
                    <Route exact path='/tools/work-centers' component={Stages} />
                    <Route exact path='/tools/reject-reasons' component={RejectReasons} />
                    <Route exact path='/tools/user-management' component={UserManagement} />
                    <Route exact path='/checklists' component={Checklists} />
                    <Route exact path='/steel-frames' component={SteelFrames} />
                    <Route exact path='/steel-frames/:steelFrameName' component={SteelFrame} />
                    <Route exact path='/steel-frames/:steelFrameName/checklists/:checklistId' component={Checklist} />

                    <Redirect to='/404' />
                  </Switch>
                </Box>
              </Main>
            </Box>
          </>
        </Switch>
        <div className={s.version}>{VERSION}</div>
        <Notification />
      </Grommet>
    </Router>
  </Provider>,
  $('#root')[0],
);
