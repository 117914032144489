import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { fetchProfile } from '../profile/actions';
import BarrelComponent from '../../components/barrel';
import EditBarrel from '../../components/edit-barrel';

import s from './index.module.scss';


const Barrel = () => {
  const location = useLocation();
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return profile.name ?
    <div className={s.barrel}>
      {location.pathname.indexOf('edit') === -1 ?
        <BarrelComponent /> :
        <EditBarrel />}
    </div> : null;
};

export default Barrel;
