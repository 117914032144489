import { Link } from 'react-router-dom';
import {
  Box, Text, Table, TableBody, TableRow, TableCell, TableHeader,
} from 'grommet';
import Moment from 'react-moment';
import $ from 'jquery';

import { formatChangelog } from '../../helpers';

import s from './index.module.scss';


const Timeline = props => {
  let events = props.part.timings
    .concat(
      formatChangelog(props.part.resolutionsChangelog || []),
    ).sort((prevEvent, event) => {
      return new Date(event.created) - new Date(prevEvent.created);
    });

  const showAll = () => {
    $('#timeline').removeClass(s.halfHidden);
  };

  return <Box id='timeline' className={`${s.timeline} ${events.length > 5 && s.halfHidden}`}>
    <Text margin={{top: 'medium', left: 'small', right: 'small', bottom: 'small'}} weight='bold'>
      Timeline:
    </Text>
    {events.length ?
      <>
        <Table className={s.table}>
          <TableHeader>
            <TableRow>
              <TableCell>
                <Text className={s.header}>Work center</Text>
              </TableCell>
              <TableCell>
                <Text className={s.header}>Time / Checklist</Text>
              </TableCell>
              <TableCell>
                <Text className={s.header}>Duration / Checklist resolution</Text>
              </TableCell>
              <TableCell>
                <Text className={s.header}>Changed by</Text>
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {events.map(event => {
              let name;
              if (event.name) name = event.name.split(' to ')[1].split('for part')[0].trim();

              return <TableRow key={event.id}>
                {event.name ?
                  <>
                    <TableCell></TableCell>
                    <TableCell>
                      <Link to={event.nameData[1].url} className='link'>
                        {event.nameData[1].text}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Text color={['Use as Is', 'Repaired'].includes(name) ? '#008000' :
                        ['Repair', 'Other'].includes(name) ? '#ff8d00' :
                        ['Scrap'].includes(name) ? '#ff0000' : null}
                      >
                        {name}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Moment format='lll'>{event.created}</Moment>
                      <br />
                      <Text>{event.createdUser.name}</Text>
                    </TableCell>
                  </> :
                  <>
                    <TableCell>{event.stageTo.name}</TableCell>
                      {['Scrapped', 'Shipped', 'Failure of print', 'Test/sample'].includes(event.stageTo.name) ?
                        <>
                          <TableCell>
                            <Moment format='lll'>{event.started}</Moment>
                          </TableCell>
                          <TableCell></TableCell>
                        </> :
                        <>
                          <TableCell>
                            <Moment format='lll'>{event.started || props.part.started}</Moment>
                            <span> — </span>
                            {event.ended ? <Moment format='lll'>{event.ended}</Moment> : 'In progress'}
                          </TableCell>
                          <TableCell>
                            <Moment duration={event.started || props.part.started} trim={true} date={event.ended}>
                            </Moment>
                          </TableCell>
                        </>}
                    <TableCell>{event.changedUser ? event.changedUser.name : event.createdUser.name}</TableCell>
                  </>}
              </TableRow>})}
          </TableBody>
        </Table>
        {events.length > 5 &&
          <Box className={s.showAll} onClick={showAll}>
            <span onClick={showAll}>Show all</span>
          </Box>}
      </>
    :
      <Box align='center'>Nothing found</Box>}
    <Link to={`/tools/changelog/${props.part.id}`} className='link'>Full changelog</Link>
  </Box>};

export default Timeline;
