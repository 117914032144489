import * as types from '../../constants';


const entities = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_ENTITIES:
      return {
        ...state,
        isLoading: true,
      };

    case types.FETCH_ENTITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.entities,
      };

    case types.FETCH_ENTITIES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default entities;
