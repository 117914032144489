import {
  getAPIAddress, toCamelCase, toSnakeCase, handleRes, formatText, deepCopy, convertInchesToMm, handleError,
} from '../../helpers';

import * as types from '../../constants';


const getId = key => {
  return parseInt(
    key.split('-')[1]
  );
};

export const editChecklist = (
  partId, barrelId, steelFrameName, checklistId, part, barrel, steelFrame, profile,
  rejectReasons, params, successCallback,
) => dispatch => {
  if (params.rejectReason) {
    const rejectReasonId = Object.keys(rejectReasons).filter(key => rejectReasons[key].name === params.rejectReason);
    params.rejectReason = rejectReasons[rejectReasonId];
  }

  const APIAdress = getAPIAddress();
  let parentEntity;
  let type;
  let id;
  let url;
  let entityActionName;
  if (partId) {
    parentEntity = part;
    type = 'PART'
    id = partId;
    url = `${APIAdress}/parts/${id}`
  } else if (barrelId) {
    parentEntity = barrel;
    type = 'BARREL'
    id = barrelId;
    url = `${APIAdress}/barrels/${id}`
  } else if (steelFrameName) {
    parentEntity = steelFrame;
    type = 'STEEL_FRAME';
    entityActionName = 'steelFrame';
    id = steelFrameName;
    url = `${APIAdress}/steel_frames/${id}`
  }
  parentEntity.qcChecklists = {
    [checklistId]: parentEntity.qcChecklists[checklistId],
  };
  for (let key in params) {
    if (key.includes('resolution')) {
      // Only id, not full <resolution> object
      parentEntity.qcChecklists[checklistId].resolution = {id: params[key]};
    } else if (key.includes('rejectReason')) {
      parentEntity.qcChecklists[checklistId].rejectReason = params[key];
    } else if (key.includes('description-')) {
      parentEntity.qcChecklists[checklistId].verifications[getId(key)].description = params[key];
    } else if (key.includes('measurement-')) {
      parentEntity.qcChecklists[checklistId].verifications[getId(key)].measurement = params[key];
      parentEntity.qcChecklists[checklistId].verifications[getId(key)].measurementWasConverted = true;
    } else if (key.includes('description')) {
      parentEntity.qcChecklists[checklistId].description = params[key];
    } else if (key.includes('accepted-')) {
      parentEntity.qcChecklists[checklistId].verifications[getId(key)].accepted = params[key];
    }
  }
  parentEntity.changed = new Date().toISOString()
  parentEntity.changedUser = { id: profile.id, name: profile.name }

  dispatch({
    type: types[`EDIT_${type}`],
    id,
    [entityActionName || type.toLocaleLowerCase()]: parentEntity,
    hideLoader: successCallback ? false : true,
  });

  const parentEntityReq = deepCopy(parentEntity);
  parentEntityReq.isOnlyChecklistsUpdate = true;
  parentEntityReq.qcChecklists = Object.keys(parentEntityReq.qcChecklists).reduce((qcChecklistsArray, id) => {
    parentEntityReq.qcChecklists[id].verifications = Object.keys(parentEntityReq.qcChecklists[id].verifications)
      .reduce((verificationsArray, verificationId) => {
        if (parentEntityReq.qcChecklists[id].verifications[verificationId].measurement
            && !parentEntityReq.qcChecklists[id].verifications[verificationId].measurementWasConverted
            && parentEntityReq.qcChecklists[id].type.isImperial) {
          parentEntityReq.qcChecklists[id].verifications[verificationId].measurement = convertInchesToMm(
            parentEntityReq.qcChecklists[id].verifications[verificationId].measurement,
          );
        }
        delete parentEntityReq.qcChecklists[id].verifications[verificationId].measurementWasConverted;
        parentEntityReq.qcChecklists[id].verifications[verificationId].typeId =
          parentEntityReq.qcChecklists[id].verifications[verificationId].type.id
        delete parentEntityReq.qcChecklists[id].verifications[verificationId].section;
        delete parentEntityReq.qcChecklists[id].verifications[verificationId].type;
        verificationsArray.push(parentEntityReq.qcChecklists[id].verifications[verificationId]);
        return verificationsArray;
      }, []);
    if (parentEntityReq.qcChecklists[id].resolution) {
      parentEntityReq.qcChecklists[id].resolutionId = parentEntityReq.qcChecklists[id].resolution.id;
    }
    if (parentEntityReq.qcChecklists[id].rejectReason) {
      parentEntityReq.qcChecklists[id].rejectReasonId = parentEntityReq.qcChecklists[id].rejectReason.id;
    } else if (parentEntityReq.qcChecklists[id].rejectReason === null) {
      parentEntityReq.qcChecklists[id].rejectReasonId = null;
    }
    delete parentEntityReq.qcChecklists[id].stage;
    delete parentEntityReq.qcChecklists[id].type;
    delete parentEntityReq.qcChecklists[id].changed;
    delete parentEntityReq.qcChecklists[id].changedByUserId;
    delete parentEntityReq.qcChecklists[id].barrel;
    delete parentEntityReq.qcChecklists[id].resolution;
    delete parentEntityReq.qcChecklists[id].showcaseResolution;
    delete parentEntityReq.qcChecklists[id].changedUser;
    delete parentEntityReq.qcChecklists[id].attachments;
    delete parentEntityReq.qcChecklists[id].media;
    delete parentEntityReq.qcChecklists[id].rejectReason;
    qcChecklistsArray.push(parentEntityReq.qcChecklists[id]);
    return qcChecklistsArray;
  }, []);
  if (partId) {
    parentEntityReq.stageId = parentEntityReq.stage.id;
    parentEntityReq.locationId = parentEntityReq.location.id;
    parentEntityReq.deviceId = parentEntityReq.device.id;
    parentEntityReq.operatorId = parentEntityReq.operator.id;
    if (parentEntityReq.manufacturingOrder) {
      parentEntityReq.manufacturingOrderId = parentEntityReq.manufacturingOrder.id;
    }
    if (parentEntityReq.steelFrame) {
      parentEntityReq.steelFrameName = parentEntityReq.steelFrame.name;
    }
    delete parentEntityReq.id;
    delete parentEntityReq.stage;
    delete parentEntityReq.location;
    delete parentEntityReq.device;
    delete parentEntityReq.operator;
    delete parentEntityReq.rfid;
    delete parentEntityReq.manufacturingOrder;
    delete parentEntityReq.steelFrame;
    delete parentEntityReq.timings;
    delete parentEntityReq.resolutionsChangelog;
    delete parentEntityReq.ended;
    delete parentEntityReq.hasThermo;
  }
  if (barrelId) {
    parentEntityReq.materialId = parentEntityReq.material.id;
    delete parentEntityReq.id;
    delete parentEntityReq.material;
  }
  delete parentEntityReq.isLoading;
  delete parentEntityReq.isReportReady;
  delete parentEntityReq.isGeneratingReportIsLoading;
  delete parentEntityReq.isGeneratingReport;
  delete parentEntityReq.error;
  delete parentEntityReq.changedUser;
  delete parentEntityReq.attachments;
  delete parentEntityReq.media;
  delete parentEntityReq.consistency;
  delete parentEntityReq.type;
  delete parentEntityReq.gCodeComment;
  delete parentEntityReq.pdmTypes;
  delete parentEntityReq.material;
  delete parentEntityReq.barrels;
  delete parentEntityReq.parts;
  delete parentEntityReq.unidentifiedBarrels;
  delete parentEntityReq.printingEvents;
  delete parentEntityReq.printingProcess;
  delete parentEntityReq.rawParams;
  delete parentEntityReq.lightCalibration;
  delete parentEntityReq.changelog;
  delete parentEntityReq.odooLink;
  delete parentEntityReq.sortedChecklists;
  delete parentEntityReq.changed;

  fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      toSnakeCase(parentEntityReq)
    ),
  })
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      dispatch({
        type: types[`EDIT_${type}_SUCCESS`],
        id: res.id,
      });
      if (successCallback) successCallback();
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Saved!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types[`EDIT_${type}_ERROR`],
        id,
        error: formatText(res.error),
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const fetchChecklistResolutions = () => dispatch => {
  dispatch({
    type: types.FETCH_CHECKLIST_RESOLUTIONS,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/qc/checklist_resolutions`)
    .then(res => handleRes(res))
    .then(res => {
      if (!Array.isArray(res)) throw res;

      let resolutions = res;
      resolutions = resolutions.reduce((resolutionsHashMap, resolution) => {
        resolution = toCamelCase(resolution);
        resolutionsHashMap[resolution.id] = resolution;
        return resolutionsHashMap;
      }, {});

      dispatch({
        type: types.FETCH_CHECKLIST_RESOLUTIONS_SUCCESS,
        resolutions: {
          list: resolutions,
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_CHECKLIST_RESOLUTIONS_ERROR,
        error: formatText(res.error),
      });
    });
};
