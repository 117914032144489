import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  Pagination,
} from 'grommet';
import Moment from 'react-moment';

import { clearObject } from '../../helpers';
import Loader from '../loader';
import AuthCheck from '../auth-check';
import { fetchLightCalibrations } from './actions';

import s from './index.module.scss';


const LightCalibrations = () => {
  const devices = useSelector(state => state.devices);
  const lightCalibrations = useSelector(state => state.lightCalibrations);
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();

  const changePage = ({ page }) => {
    dispatch(
      fetchLightCalibrations({ page }),
    );
  };

  useEffect(() => {
    dispatch(
      fetchLightCalibrations({
        page: lightCalibrations.page || 1,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let lightCalibrationsArray;
  if (lightCalibrations.total && lightCalibrations.isLoading === false) {
    lightCalibrationsArray = Object.keys(clearObject(lightCalibrations.list))
      .filter(id => lightCalibrations.list[id].isLoading !== true)
      .sort((prevId, id) => {
        return new Date(lightCalibrations.list[id].changed || lightCalibrations.list[id].created) -
          new Date(lightCalibrations.list[prevId].changed || lightCalibrations.list[prevId].created);
      })
      .map(id => lightCalibrations.list[id]);
  }

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return lightCalibrations.isLoading === false && devices.isLoading === false ?
    lightCalibrations.total ?
      <div className={s.lightCalibrations}>
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>
                <Text className={s.header}>Date</Text>
              </TableCell>
              <TableCell>
                <Text className={s.header}>Power</Text>
              </TableCell>
              <TableCell>
                <Text className={s.header}>Size</Text>
              </TableCell>
              <TableCell>
                <Text className={s.header}>Device</Text>
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {lightCalibrationsArray.map(lightCalibration =>
              (!lightCalibration.error && lightCalibration.created &&
                <TableRow key={lightCalibration.id} className={s.row}>
                  <TableCell>
                    <Link to={`/light-calibrations/${lightCalibration.id}`} className='link'>
                      <Moment format='lll'>{lightCalibration.times[0]}</Moment>
                    </Link>
                  </TableCell>
                  <TableCell>{lightCalibration.power}</TableCell>
                  <TableCell>{lightCalibration.size.join('…')}</TableCell>
                  <TableCell>{lightCalibration.device.name}</TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>

        {lightCalibrations.total > 20 &&
          <Box>
            <Pagination
              className={s.pagination}
              numberItems={lightCalibrations.total}
              step={20}
              numberMiddlePages={3}
              onChange={changePage}
              page={lightCalibrations.page}
              alignSelf='center'
            />
          </Box>
        }
        <AuthCheck isAuthorized={true} />
      </div>
    :
      <Box className={s.lightCalibrations} align='center'>Nothing found</Box>
  :
    <Loader mod={s.loader} />
};

export default LightCalibrations;
