import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Table, TableBody, TableCell, TableHeader, TableRow, Form, TextInput, Menu,
} from 'grommet';
import $ from 'jquery';

import AuthCheck from '../auth-check';
import Loader from '../loader';
import { objectIsEmpty } from '../../helpers';
import { fetchParts } from '../parts/actions';
import { findPartByRfid } from '../../pages/parts/actions';

import s from './index.module.scss';


const Parts = () => {
  const groupedParts = useSelector(state => state.groupedParts);
  const locations = useSelector(state => state.locations);
  const profile = useSelector(state => state.profile);
  const partTypes = useSelector(state => state.partTypes);
  const stages = useSelector(state => state.stages);
  const devices = useSelector(state => state.devices);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      fetchParts(
        {
          grouped: true,
        },
        {
          locations,
          types: partTypes,
          stages,
          devices
        },
        groupedParts.filters,
      ),
    );

    let prevRfidValue;
    const interval = setInterval(() => {
      const value = $('#rfid').val();
      if (value && value !== prevRfidValue) {
        dispatch(
          findPartByRfid(value, goToPart),
        );
      }
      prevRfidValue = value;
    }, 250);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToPart = part => {
    history.push(`/parts/${part.id}`);
  };

  const goToParts = () => {
    history.push('/parts');
  };

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return groupedParts.isLoading === false && locations.isLoading === false && stages.isLoading === false ?
    groupedParts.dict ?
      <div className={s.groupedParts}>
        <Form className={s.controls}>
          <TextInput id='rfid' name='rfid' placeholder="Scan part's rfid here" />
          <Menu
            label='Actions'
            items={[
              { label: 'Group by time', onClick: goToParts },
              // { label: 'Export as CSV', onClick: () => {} },
            ]}
          />
        </Form>
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell align='center'>Use as Is</TableCell>
              <TableCell align='center'>Repair</TableCell>
              <TableCell align='center'>Scrap</TableCell>
              <TableCell align='center'>Other</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {Object.keys(stages.list).map(key => {
              return groupedParts.dict[stages.list[key].name] &&
                <React.Fragment key={key}>
                  <TableRow>
                    <TableCell colSpan='6' className={s.title}>{stages.list[key].name}</TableCell>
                  </TableRow>
                  {Object.keys(groupedParts.dict[stages.list[key].name]).map(partTypeKey => {
                    return !objectIsEmpty(groupedParts.dict[stages.list[key].name][partTypeKey]) &&
                      <TableRow key={partTypeKey}>
                        <TableCell>{partTypeKey}</TableCell>
                        <TableCell align='center'>
                          {groupedParts.dict[stages.list[key].name][partTypeKey]['Use as Is'] || '—'}
                        </TableCell>
                        <TableCell align='center'>
                          {groupedParts.dict[stages.list[key].name][partTypeKey]['Repair'] || '—'}
                        </TableCell>
                        <TableCell align='center'>
                          {groupedParts.dict[stages.list[key].name][partTypeKey]['Scrap'] || '—'}
                        </TableCell>
                        <TableCell align='center'>
                          {groupedParts.dict[stages.list[key].name][partTypeKey]['Other'] || '—'}
                        </TableCell>
                      </TableRow>})}
                </React.Fragment>})}
          </TableBody>
        </Table>
        <AuthCheck isAuthorized={true} />
      </div>
    :
      <Box className={s.groupedParts} align='center'>Nothing found</Box>
  :
    <Loader mod={s.loader} />
};

export default Parts;
