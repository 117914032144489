import { getAPIAddress, toCamelCase, handleRes, formatText, handleError } from '../../helpers';
import * as types from '../../constants';


export const fetchEntities = () => dispatch => {
  dispatch({
    type: types.FETCH_ENTITIES,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/entities`)
    .then(res => handleRes(res))
    .then(res => {
      if (!Array.isArray(res)) throw res;

      let entities = res;
      entities = entities.reduce((entitiesHashMap, entity) => {
        entity = toCamelCase(entity)
        entitiesHashMap[entity.id] = entity;
        return entitiesHashMap;
      }, {});

      dispatch({
        type: types.FETCH_ENTITIES_SUCCESS,
        entities,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_ENTITIES_ERROR,
        error: formatText(res.error),
      });
    });
};
