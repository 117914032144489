import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box, Table, TableBody, TableCell, TableHeader, TableRow, Text, Pagination, TextInput, Form, Button, Layer, FormField,
} from 'grommet';
import {
  Checkbox, CheckboxSelected, Close, FormPreviousLink,
} from 'grommet-icons';
import Moment from 'react-moment';
import $ from 'jquery';

import { clearObject, objectIsEmpty } from '../../helpers';
import Loader from '../loader';
import AuthCheck from '../auth-check';
import { fetchStages, createStage, editStage, removeStage, choose, unchoose, reset } from './actions';

import s from './index.module.scss';


const Stages = () => {
  const stages = useSelector(state => state.stages);
  const choosenStages = useSelector(state => state.choosenStages);
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchValue, setSearchValue] = useState('');
  const [modalIsActive, toggleModal] = useState();
  const [removeModalIsActive, toggleRemoveModal] = useState();
  const [showActionButtons, toggleActionButtons] = useState();
  const [editFormId, changeEditFormId] = useState();

  useEffect(() => {
    dispatch(
      fetchStages({
        page: stages.page || 1,
      }),
    );
    dispatch(reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let show;
    let editId;
    Object.keys(choosenStages).forEach(id => {
      if (choosenStages[id]) {
        show = true;
        editId === undefined ? editId = id : editId = false;
      }
    });
    changeEditFormId(editId);
    if (show) {
      toggleActionButtons(true);
      $('#controls').children('.submit').prop('disabled', false).removeClass('disabled');
    } else {
      toggleActionButtons(false);
      $('#controls').children('.submit').prop('disabled', true).addClass('disabled');
    }
    if (editId) {
      $('#controls').children('.submitEdit').prop('disabled', false).removeClass('disabled');
    } else {
      $('#controls').children('.submitEdit').prop('disabled', true).addClass('disabled');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choosenStages, showActionButtons]);

  const changePage = ({ page }) => {
    const params = { page };
    if (searchValue) params.name = searchValue;
    dispatch(
      fetchStages(params),
    );
  };

  const search = (value, page) => {
    if (value) {
      dispatch(
        fetchStages({
          page: 1,
          perPage: 30,
          name: value,
        }),
      );
    } else {
      dispatch(
        fetchStages({ page: page || 1 }),
      );
    }
    setSearchValue(value);
  };

  const chooseStage = id => {
    if (choosenStages[id]) {
      dispatch(
        unchoose(id),
      );
    } else {
      dispatch(
        choose(stages.list[id]),
      );
    }
  };

  const goBack = () => {
    history.push('/tools');
  };

  let stagesArray = [];
  if (stages.total && stages.isLoading === false) {
    stagesArray = Object.keys(clearObject(stages.list))
      .map(id => stages.list[id]);
  }

  const submitStage = e => {
    if (modalIsActive === 'edit') {
      dispatch(
        editStage(
          editFormId,
          e.value,
          id => {
            search(searchValue, stages.page);
            toggleModal(false);
            dispatch(
              unchoose(id),
            );
          },
        ),
      );
    } else if (modalIsActive === 'create') {
      dispatch(
        createStage(
          e.value,
          () => {
            search(searchValue, stages.page);
            toggleModal(false);
          },
        ),
      );
    }
  };

  const remove = () => {
    let stageIds = Object.keys(choosenStages).filter(id => choosenStages[id]);
    stageIds.forEach(id => {
      dispatch(
        removeStage(id),
      );
      dispatch(
        unchoose(id),
      );
    });
    toggleRemoveModal(false);
  };

  const toggleButton = e => {
    if (e.target.value) {
      $('#stageForm .submit').prop('disabled', false).removeClass('disabled');
    } else {
      $('#stageForm .submit').prop('disabled', true).addClass('disabled');
    }
  };

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return <Box className={s.stages}>
    <Box direction='row' width='100%' justify='between' className={s.navHeader}>
      <Button className='buttonLink' onClick={goBack}>
        <FormPreviousLink />
        Tools
      </Button>
    </Box>
    <div id='controls' className={s.controls}>
      <Button type='button' primary label='Edit' className='submitEdit' onClick={() => toggleModal('edit')} />
      <Button
        type='button' primary label='Remove' className='submit' color='#ff0000'
        onClick={() => toggleRemoveModal(true)}
      />
      {removeModalIsActive &&
        <Layer
          className='modal40'
          onEsc={() => toggleRemoveModal(false)}
          onClickOutside={() => toggleRemoveModal(false)}
        >
          <Text>Are you sure?</Text>
          <Box direction='row' className='removeModalButtons'>
            <Button type='button' label='Discard' onClick={() => toggleRemoveModal(false)} />
            <Button type='button' primary label='Remove' onClick={remove} color='#ff0000' />
          </Box>
          <Close onClick={() => toggleRemoveModal(false)} />
        </Layer>}
      <Button type='button' primary label='Add' onClick={() => toggleModal('create')} />
      {modalIsActive &&
        <Layer
          className='modal60'
          onEsc={() => toggleModal(false)}
          onClickOutside={() => toggleModal(false)}
        >
          <Form id='stageForm' onSubmit={submitStage}>
            <FormField htmlFor='name' label='Name'>
              <TextInput
                id='name'
                name='name'
                defaultValue={modalIsActive === 'edit' ? choosenStages[editFormId].name : null}
                onChange={toggleButton}
              />
            </FormField>
            <FormField htmlFor='order' label='Order'>
              <TextInput
                id='order'
                name='order'
                type='number'
                defaultValue={modalIsActive === 'edit' ? choosenStages[editFormId].order : null}
                onChange={toggleButton}
              />
            </FormField>
            <Box direction='row' margin={{top: 'medium'}}>
              <Button type='submit' primary disabled label='Submit' className='submit disabled' />
            </Box>
          </Form>
          <Close onClick={() => toggleModal(false)} />
        </Layer>}
    </div>
    <Form>
      <TextInput
        onChange={e => search(e.target.value)}
        placeholder='Type work center name here'
        value={searchValue}
      />
    </Form>
    {stages.isLoading === false ?
      !objectIsEmpty(stages.list) ?
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Text className={s.header}>Name</Text>
                </TableCell>
                <TableCell>
                  <Text className={s.header}>Order</Text>
                </TableCell>
                <TableCell>
                  <Text className={s.header}>Created</Text>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {stagesArray.map(stage =>
                stage.id &&
                  <TableRow
                    key={stage.id}
                    className={s.row}
                    onClick={() => chooseStage(stage.id)}
                  >
                    <TableCell>
                      {stage.isLoading ?
                        <Loader mod={s.loader} type='icon' /> :
                        choosenStages[stage.id] ? <CheckboxSelected /> : <Checkbox />}
                    </TableCell>
                    <TableCell>
                      <Text>{stage.name}</Text>
                    </TableCell>
                    <TableCell size='small'>
                      <Text>{stage.order}</Text>
                    </TableCell>
                    <TableCell size='small'>
                      <Moment format='lll'>{stage.created}</Moment>
                    </TableCell>
                  </TableRow>)}
            </TableBody>
          </Table>

          {stages.total > 30 &&
            <Box>
              <Pagination
                className={s.pagination}
                numberItems={stages.total}
                step={30}
                numberMiddlePages={3}
                onChange={changePage}
                page={stages.page}
                alignSelf='center'
              />
            </Box>
          }
          <AuthCheck isAuthorized={true} />
        </>
      :
        <Box className={s.empty} align='center'>Nothing found</Box>
    :
      <Loader mod={s.loader} />}
  </Box>
};

export default Stages;
