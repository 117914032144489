import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Table, TableBody, TableCell, TableHeader, TableRow, Text,
} from 'grommet';
import Moment from 'react-moment';

import Loader from '../loader';
import AuthCheck from '../auth-check';
import { fetchLocations } from './actions';

import s from './index.module.scss';


const Locations = () => {
  const locations = useSelector(state => state.locations);
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLocations());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return locations.list && locations.isLoading === false ?
    <div className={s.locations}>
      <Table>
        <TableHeader>
          <TableRow>
              <TableCell>
                <Text className={s.name}>Name</Text>
              </TableCell>
              <TableCell>
                <Text className={s.name}>Description</Text>
              </TableCell>
              <TableCell>
                <Text className={s.name}>Created</Text>
              </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.keys(locations.list).map(id => (
            <TableRow key={id}>
                <TableCell>
                  <Text>
                    <Text className={s.name}>{locations.list[id].name}</Text>
                  </Text>
                </TableCell>
                <TableCell>
                  <Text>
                    <Text>{locations.list[id].description}</Text>
                  </Text>
                </TableCell>
                <TableCell>
                  <Moment format='lll'>{locations.list[id].created}</Moment>
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <AuthCheck isAuthorized={true} />
    </div>
  :
    <Loader />
};

export default Locations;
