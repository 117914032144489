import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  Pagination,
  TextInput,
  Form,
  Button,
  Layer,
  FormField,
  CheckBox,
  TextArea,
} from 'grommet';
import {
  Checkbox,
  CheckboxSelected,
  Close,
  FormPreviousLink,
  StatusInfo,
} from 'grommet-icons';
import $ from 'jquery';
import { last } from 'lodash';

import { clearObject, formatVerificationType, objectIsEmpty, convertMmToInches } from '../../helpers';
import Loader from '../loader';
import AuthCheck from '../auth-check';
import {
  fetchVerificationTypes, createVerificationType, editVerificationType, removeVerificationType, choose, unchoose, reset,
} from './actions';
import { fetchChecklistType, addVerificationTypes } from '../checklist-type/actions';

import s from './index.module.scss';


const VerificationTypes = () => {
  const checklistTypes = useSelector(state => state.checklistTypes);
  const verificationTypes = useSelector(state => state.verificationTypes);
  const choosenVerificationTypes = useSelector(state => state.choosenVerificationTypes);
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const { checklistTypeId } = useParams();
  const history = useHistory();

  const [searchValue, setSearchValue] = useState('');
  const [modalIsActive, toggleModal] = useState();
  const [removeModalIsActive, toggleRemoveModal] = useState();
  const [showActionButtons, toggleActionButtons] = useState();
  const [infoModalStatuses, changeInfoModals] = useState({});
  const [editFormId, changeEditFormId] = useState();

  let fakeChecklistTypeId;
  if (!checklistTypeId && checklistTypes.list) {
    fakeChecklistTypeId = last(
      Object.keys(checklistTypes.list),
    );
  }

  useEffect(() => {
    if (!checklistTypes.list || !checklistTypes.list[checklistTypeId || fakeChecklistTypeId]) {
      dispatch(
        fetchChecklistType(checklistTypeId || fakeChecklistTypeId),
      );
    }
    dispatch(
      fetchVerificationTypes({
        page: verificationTypes.page || 1,
      }),
    );
    dispatch(reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let show;
    let editId;
    Object.keys(choosenVerificationTypes).forEach(id => {
      if (choosenVerificationTypes[id].state) {
        show = true;
        editId === undefined ? editId = id : editId = false;
      }
    });
    changeEditFormId(editId);
    if (show) {
      toggleActionButtons(true);
      $('#controls').children('.submit').prop('disabled', false).removeClass('disabled');
    } else {
      toggleActionButtons(false);
      $('#controls').children('.submit').prop('disabled', true).addClass('disabled');
    }
    if (editId) {
      $('#controls').children('.submitEdit').prop('disabled', false).removeClass('disabled');
    } else {
      $('#controls').children('.submitEdit').prop('disabled', true).addClass('disabled');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choosenVerificationTypes, showActionButtons]);

  const changePage = ({ page }) => {
    const params = {
      page,
    };
    if (searchValue) {
      params.name = searchValue;
    }
    dispatch(
      fetchVerificationTypes(params),
    );
  };

  const search = (value, page) => {
    if (value) {
      dispatch(
        fetchVerificationTypes({
          page: 1,
          perPage: 30,
          name: value,
        }),
      );
    } else {
      dispatch(
        fetchVerificationTypes({ page: page || 1 }),
      );
    }
    setSearchValue(value);
  };

  const chooseVerificationType = (id, parentId) => {
    if (choosenVerificationTypes[id] && choosenVerificationTypes[id].state) {
      dispatch(
        unchoose(id),
      );
    } else {
      dispatch(
        choose(
          parentId ? verificationTypes.list[parentId].subtypes[id] : verificationTypes.list[id],
          parentId,
        ),
      );
    }
  };

  const goBack = () => {
    if (checklistTypeId) {
      history.push(`/tools/checklist-types/${checklistTypeId}`);
    } else {
      history.push('/tools/create-checklist-type');
    }
  };

  const chooseVarificationTypes = () => {
    const output = [];
    Object.keys(choosenVerificationTypes).filter(id => choosenVerificationTypes[id].state).forEach(id => {
      const verificationType = formatVerificationType(
        choosenVerificationTypes[id],
        checklistType.isImperial ? 'Imperial' : 'Metrical',
      );
      verificationType.position = Object.keys(checklistType.verificationTypes).length + 1;
      output.push(verificationType);
    });

    dispatch(
      addVerificationTypes(checklistTypeId || fakeChecklistTypeId, output),
    );
    goBack();
  };

  let checklistType;
  if (checklistTypes.list && checklistTypes.list[checklistTypeId || fakeChecklistTypeId]) {
    checklistType = checklistTypes.list[checklistTypeId || fakeChecklistTypeId];
  }

  let verificationTypesArray = [];
  if (verificationTypes.total && verificationTypes.isLoading === false) {
    verificationTypesArray = Object.keys(clearObject(verificationTypes.list))
      .map(id => verificationTypes.list[id]).reverse();
  }

  const submitVerificationType = e => {
    if (modalIsActive === 'edit') {
      dispatch(
        editVerificationType(
          checklistType.id,
          editFormId,
          e.value,
          choosenVerificationTypes[editFormId],
          checklistType.isImperial,
          id => {
            search(searchValue, verificationTypes.page);
            toggleModal(false);
            dispatch(
              unchoose(id),
            );
          },
        ),
      );
    } else {
      dispatch(
        createVerificationType(
          checklistType.id,
          e.value,
          checklistType.isImperial,
          () => {
            search(searchValue, verificationTypes.page);
            toggleModal(false);
          },
        ),
      );
    }
  };

  const remove = () => {
    let typesIds = Object.keys(choosenVerificationTypes).filter(id => choosenVerificationTypes[id].state);
    typesIds.forEach(id => {
      dispatch(
        removeVerificationType(
          id,
          () => search(searchValue),
        ),
      );
      dispatch(
        unchoose(id),
      );
    });
    toggleRemoveModal(false);
  };

  const toggleButton = () => {
    if (
      ($('#verificationTypeForm #name').prop('value').length &&
        $('#verificationTypeForm #deviationStart').prop('value').length &&
        $('#verificationTypeForm #deviationEnd').prop('value').length &&
        $('#verificationTypeForm #reference').prop('value').length) ||
      ($('#verificationTypeForm #name').prop('value').length &&
        !$('#verificationTypeForm #deviationStart').prop('value').length &&
        !$('#verificationTypeForm #deviationEnd').prop('value').length)
    ) {
      $('#verificationTypeForm .submit').prop('disabled', false).removeClass('disabled');
    } else {
      $('#verificationTypeForm .submit').prop('disabled', true).addClass('disabled');
    }
  };

  const enableMeasurement = e => {
    if (e.target.checked) {
      $('#measurement').removeClass('hidden');
    } else {
      $('#measurement').addClass('hidden');
    }
    toggleButton();
  };

  const toggleInfoModal = (e, status, id, closeAll) => {
    if (closeAll) {
      changeInfoModals({
        [id]: status,
      });
      e.stopPropagation();
      return;
    }

    changeInfoModals({
      ...infoModalStatuses,
      [id]: status,
    });
    e.stopPropagation();
  };

  const getDefaultParamForForm = name => {
    if (modalIsActive === 'edit') {
      let mmResult = choosenVerificationTypes[editFormId][name];
      if (mmResult && checklistType.isImperial) return convertMmToInches(mmResult);
      return mmResult;
    }
  };

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return <Box className={s.verificationTypes}>
    <Box direction='row' width='100%' justify='between' className={s.navHeader}>
      <Button className='buttonLink' onClick={goBack}>
        <FormPreviousLink />
        Checklist
      </Button>
    </Box>
    <div id='controls' className={s.controls}>
      <Button type='button' primary label='Choose' className='submit' onClick={chooseVarificationTypes} />
      <Button type='button' primary label='Edit' className='submitEdit' onClick={() => toggleModal('edit')} />
      <Button
        type='button' primary label='Remove' className='submit' color='#ff0000'
        onClick={() => toggleRemoveModal(true)}
      />
      {removeModalIsActive &&
        <Layer
          className='modal40'
          onEsc={() => toggleRemoveModal(false)}
          onClickOutside={() => toggleRemoveModal(false)}
        >
          <Text>Are you sure?</Text>
          <Box direction='row' className='removeModalButtons'>
            <Button type='button' label='Discard' onClick={() => toggleRemoveModal(false)} />
            <Button type='button' primary label='Remove' onClick={remove} color='#ff0000' />
          </Box>
          <Close onClick={() => toggleRemoveModal(false)} />
        </Layer>}
      <Button type='button' primary label='Add' onClick={() => toggleModal('create')} />
      {modalIsActive &&
        <Layer
          className={`${s.createVerification} modal60`}
          onEsc={() => toggleModal(false)}
          onClickOutside={() => toggleModal(false)}
        >
          <Form id='verificationTypeForm' onSubmit={submitVerificationType}>
            <FormField htmlFor='name' label='Name'>
              <TextInput
                id='name'
                name='name'
                onChange={toggleButton}
                defaultValue={modalIsActive === 'edit' ?
                  choosenVerificationTypes[editFormId].name :
                  undefined}
              />
            </FormField>
            <Box className={s.checkbox}>
              <CheckBox
                name='isAcceptanceDisabled'
                label='Enable acceptance (OK/NOK)'
                defaultChecked={modalIsActive === 'edit' ?
                  !choosenVerificationTypes[editFormId].isAcceptanceDisabled :
                  true}
                onChange={toggleButton}
              />
            </Box>
            <Box className={s.checkbox}>
              <CheckBox
                name='showMeasurement'
                label='Enable measurement'
                defaultChecked={modalIsActive === 'edit' ?
                  (choosenVerificationTypes[editFormId].showMeasurement || undefined) :
                  undefined}
                onChange={enableMeasurement}
              />
            </Box>
            <Box
              id='measurement'
              className={
                `${s.measurement} ${modalIsActive === 'edit' ?
                    (choosenVerificationTypes[editFormId].showMeasurement ? '' : 'hidden') :
                    'hidden'}`}
            >
              <Box direction='row'>
                <FormField htmlFor='reference' label='Reference'>
                  <TextInput
                    id='reference'
                    name='reference'
                    defaultValue={getDefaultParamForForm('reference')}
                    onChange={toggleButton}
                  />
                </FormField>
                <FormField htmlFor='defaultMeasurement' label='Default value'>
                  <TextInput
                    id='defaultMeasurement'
                    name='defaultMeasurement'
                    defaultValue={getDefaultParamForForm('defaultMeasurement')}
                    onChange={toggleButton}
                  />
                </FormField>
              </Box>
              <Box direction='row'>
                <FormField htmlFor='deviationStart' label='Deviation start'>
                  <TextInput
                    id='deviationStart'
                    name='deviationStart'
                    defaultValue={getDefaultParamForForm('deviationStart')}
                    onChange={toggleButton}
                  />
                </FormField>
                <FormField htmlFor='deviationEnd' label='Deviation end'>
                  <TextInput
                    id='deviationEnd'
                    name='deviationEnd'
                    defaultValue={getDefaultParamForForm('deviationEnd')}
                    onChange={toggleButton}
                  />
                </FormField>
              </Box>
            </Box>
            <FormField htmlFor='defaultDescription' label='Default description'>
              <TextArea
                id='defaultDescription'
                name='defaultDescription'
                defaultValue={modalIsActive === 'edit' ?
                  choosenVerificationTypes[editFormId].defaultDescription :
                  undefined}
                onChange={toggleButton}
              />
            </FormField>
            <Box direction='row' margin={{top: 'medium'}}>
              <Button type='submit' primary disabled label='Submit' className='submit disabled' />
            </Box>
          </Form>
          <Close onClick={() => toggleModal(false)} />
        </Layer>}
    </div>
    <Form>
      <TextInput
        onChange={e => search(e.target.value)}
        placeholder='Type verification name here'
        value={searchValue}
      />
    </Form>
    {verificationTypes.isLoading === false ?
      !objectIsEmpty(verificationTypes.list) ?
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Text className={s.header}>Name</Text>
                </TableCell>
                <TableCell>
                  <Text className={s.header}>
                    Reference
                    <Text size='xsmall'>{checklistType.isImperial ? ' (inches)' : ' (mm)'}</Text>
                  </Text>
                </TableCell>
                <TableCell>
                  <Text className={s.header}>
                    Deviation start
                    <Text size='xsmall'>{checklistType.isImperial ? ' (inches)' : ' (mm)'}</Text>
                  </Text>
                </TableCell>
                <TableCell>
                  <Text className={s.header}>
                    Deviation end
                    <Text size='xsmall'>{checklistType.isImperial ? ' (inches)' : ' (mm)'}</Text>
                  </Text>
                </TableCell>
                <TableCell align='center'>
                  <Text className={s.header}>Full info</Text>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {verificationTypesArray.map(verificationType => {
                return [
                  verificationType.id &&
                    <TableRow
                      key={verificationType.id}
                      className={s.row}
                      onClick={() => chooseVerificationType(verificationType.id)}
                    >
                      <TableCell>
                        {verificationType.isLoading ?
                          <Loader mod={s.loader} type='icon' /> :
                          choosenVerificationTypes[verificationType.id]
                              && choosenVerificationTypes[verificationType.id].state ?
                            <CheckboxSelected /> :
                            <Checkbox />}
                      </TableCell>
                      <TableCell>
                        <Text>{verificationType.name}</Text>
                      </TableCell>
                      <TableCell size='xsmall'>
                        <Text>
                          {verificationType.reference && checklistType.isImperial ?
                            convertMmToInches(verificationType.reference) : verificationType.reference}
                        </Text>
                      </TableCell>
                      <TableCell size='xsmall'>
                        <Text>
                          {verificationType.deviationStart && checklistType.isImperial ?
                            convertMmToInches(verificationType.deviationStart) : verificationType.deviationStart}
                        </Text>
                      </TableCell>
                      <TableCell size='xsmall'>
                        <Text>
                          {verificationType.deviationEnd && checklistType.isImperial ?
                            convertMmToInches(verificationType.deviationEnd) : verificationType.deviationEnd}
                        </Text>
                      </TableCell>
                      <TableCell size='xxsmall' className={s.fullInfo}>
                        <StatusInfo
                          onClick={e => toggleInfoModal(e, true, verificationType.id, true)}
                        />
                        {infoModalStatuses[verificationType.id] &&
                          <Layer
                            className='modal60'
                            onEsc={e => toggleInfoModal(e, false, verificationType.id)}
                            onClickOutside={e => toggleInfoModal(e, false, verificationType.id)}
                          >
                            <Table className={s.infoTable}>
                              <TableBody>
                                <TableRow>
                                  <TableCell>Name: </TableCell>
                                  <TableCell>{verificationType.name}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Acceptance is enabled (OK/NOK): </TableCell>
                                  <TableCell>{verificationType.isAcceptanceDisabled ? 'No' : 'Yes'}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Measurement field is enabled: </TableCell>
                                  <TableCell>
                                    {(verificationType.reference
                                        || verificationType.deviationStart
                                        || verificationType.showMeasurement)
                                      ? 'Yes' : 'No'}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Default measurement: </TableCell>
                                  <TableCell>
                                    {verificationType.defaultMeasurement && checklistType.isImperial ?
                                      convertMmToInches(verificationType.defaultMeasurement) :
                                      verificationType.defaultMeasurement}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Reference: </TableCell>
                                  <TableCell>
                                    {verificationType.reference && checklistType.isImperial ?
                                      convertMmToInches(verificationType.reference) : verificationType.reference}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Deviation start: </TableCell>
                                  <TableCell>
                                    {verificationType.deviationStart && checklistType.isImperial ?
                                      convertMmToInches(verificationType.deviationStart) :
                                      verificationType.deviationStart}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Deviation end: </TableCell>
                                  <TableCell>
                                    {verificationType.deviationEnd && checklistType.isImperial ?
                                      convertMmToInches(verificationType.deviationEnd) :
                                      verificationType.deviationEnd}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Default Description: </TableCell>
                                  <TableCell>{verificationType.defaultDescription}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                            <Close onClick={e => toggleInfoModal(e, false, verificationType.id)} />
                          </Layer>}
                      </TableCell>
                    </TableRow>,
                    (verificationType.subtypes && !objectIsEmpty(verificationType.subtypes))
                        && Object.keys(verificationType.subtypes).map(id =>
                      <TableRow
                        key={id}
                        className={`${s.row} ${s.subRow}`}
                        onClick={() => chooseVerificationType(id, verificationType.id)}
                      >
                        <TableCell>
                          {verificationType.subtypes[id].isLoading ?
                            <Loader mod={s.loader} type='icon' /> :
                            choosenVerificationTypes[id] && choosenVerificationTypes[id].state ?
                              <CheckboxSelected /> :
                              <Checkbox />}
                        </TableCell>
                        <TableCell>
                          <Text>{verificationType.subtypes[id].name}</Text>
                        </TableCell>
                        <TableCell size='xsmall'>
                          <Text>
                            {verificationType.subtypes[id].reference && checklistType.isImperial ?
                              convertMmToInches(verificationType.subtypes[id].reference) :
                              verificationType.subtypes[id].reference}
                          </Text>
                        </TableCell>
                        <TableCell size='xsmall'>
                          <Text>
                            {verificationType.subtypes[id].deviationStart && checklistType.isImperial ?
                              convertMmToInches(verificationType.subtypes[id].deviationStart) :
                              verificationType.subtypes[id].deviationStart}
                          </Text>
                        </TableCell>
                        <TableCell size='xsmall'>
                          <Text>
                            {verificationType.subtypes[id].deviationEnd && checklistType.isImperial ?
                              convertMmToInches(verificationType.subtypes[id].deviationEnd) :
                              verificationType.subtypes[id].deviationEnd}
                          </Text>
                        </TableCell>
                        <TableCell size='xxsmall' className={s.fullInfo}>
                          <StatusInfo
                            onClick={e => toggleInfoModal(e, true, id, true)}
                          />
                          {infoModalStatuses[id] &&
                            <Layer
                              className='modal60'
                              onEsc={e => toggleInfoModal(e, false, id)}
                              onClickOutside={e => toggleInfoModal(e, false, id)}
                            >
                              <Table className={s.infoTable}>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>Name: </TableCell>
                                    <TableCell>{verificationType.subtypes[id].name}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Acceptance is enabled (OK/NOK): </TableCell>
                                    <TableCell>
                                      {verificationType.subtypes[id].isAcceptanceDisabled ? 'NOK' : 'OK'}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Measurement field is enabled: </TableCell>
                                    <TableCell>
                                      {(verificationType.subtypes[id].reference
                                          || verificationType.subtypes[id].deviationStart
                                          || verificationType.subtypes[id].showMeasurement)
                                        ? 'No' : 'Yes'}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Default measurement: </TableCell>
                                    <TableCell>
                                      {verificationType.subtypes[id].defaultMeasurement && checklistType.isImperial ?
                                        convertMmToInches(verificationType.subtypes[id].defaultMeasurement) :
                                        verificationType.subtypes[id].defaultMeasurement}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Reference: </TableCell>
                                    <TableCell>
                                      {verificationType.subtypes[id].reference && checklistType.isImperial ?
                                        convertMmToInches(verificationType.subtypes[id].reference) :
                                        verificationType.subtypes[id].reference}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Deviation start: </TableCell>
                                    <TableCell>
                                      {verificationType.subtypes[id].deviationStart && checklistType.isImperial ?
                                        convertMmToInches(verificationType.subtypes[id].deviationStart) :
                                        verificationType.subtypes[id].deviationStart}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Deviation end: </TableCell>
                                    <TableCell>
                                      {verificationType.subtypes[id].deviationEnd && checklistType.isImperial ?
                                        convertMmToInches(verificationType.subtypes[id].deviationEnd) :
                                        verificationType.subtypes[id].deviationEnd}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Default Description: </TableCell>
                                    <TableCell>{verificationType.subtypes[id].defaultDescription}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                              <Close onClick={e => toggleInfoModal(e, false, id)} />
                            </Layer>}
                        </TableCell>
                      </TableRow>,
                    )
                ]})}
            </TableBody>
          </Table>

          {verificationTypes.total > 30 &&
            <Box>
              <Pagination
                className={s.pagination}
                numberItems={verificationTypes.total}
                step={30}
                numberMiddlePages={3}
                onChange={changePage}
                page={verificationTypes.page}
                alignSelf='center'
              />
            </Box>
          }
          <AuthCheck isAuthorized={true} />
        </>
      :
        <Box className={s.empty} align='center'>Nothing found</Box>
    :
      <Loader mod={s.loader} />}
  </Box>
};

export default VerificationTypes;
