import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  Box, Table, TableBody, TableCell, TableHeader, TableRow, Text, Pagination, TextInput, Form, Button, Layer, FormField,
} from 'grommet';
import {
  Checkbox, CheckboxSelected, Close, FormPreviousLink,
} from 'grommet-icons';
import Moment from 'react-moment';
import $ from 'jquery';
import { last } from 'lodash';

import { clearObject, formatVerificationType, objectIsEmpty } from '../../helpers';
import Loader from '../loader';
import AuthCheck from '../auth-check';
import { fetchSections, createSection, editSection, removeSection, choose, unchoose, reset } from './actions';
import { fetchChecklistType, addVerificationTypes } from '../checklist-type/actions';

import s from './index.module.scss';


const Sections = () => {
  const checklistTypes = useSelector(state => state.checklistTypes);
  const sections = useSelector(state => state.sections);
  const choosenSections = useSelector(state => state.choosenSections);
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const { checklistTypeId } = useParams();
  const history = useHistory();

  const [searchValue, setSearchValue] = useState('');
  const [modalIsActive, toggleModal] = useState();
  const [removeModalIsActive, toggleRemoveModal] = useState();
  const [showActionButtons, toggleActionButtons] = useState();
  const [editFormId, changeEditFormId] = useState();

  let fakeChecklistTypeId;
  if (!checklistTypeId && checklistTypes.list) {
    fakeChecklistTypeId = last(
      Object.keys(checklistTypes.list),
    );
  }

  useEffect(() => {
    if (!checklistTypes.list || !checklistTypes.list[checklistTypeId || fakeChecklistTypeId]) {
      dispatch(
        fetchChecklistType(checklistTypeId || fakeChecklistTypeId),
      );
    }
    dispatch(
      fetchSections({
        page: sections.page || 1,
      }),
    );
    dispatch(reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let show;
    let editId;
    Object.keys(choosenSections).forEach(id => {
      if (choosenSections[id]) {
        show = true;
        editId === undefined ? editId = id : editId = false;
      }
    });
    changeEditFormId(editId);
    if (show) {
      toggleActionButtons(true);
      $('#controls').children('.submit').prop('disabled', false).removeClass('disabled');
    } else {
      toggleActionButtons(false);
      $('#controls').children('.submit').prop('disabled', true).addClass('disabled');
    }
    if (editId) {
      $('#controls').children('.submitEdit').prop('disabled', false).removeClass('disabled');
    } else {
      $('#controls').children('.submitEdit').prop('disabled', true).addClass('disabled');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choosenSections, showActionButtons]);

  const changePage = ({ page }) => {
    const params = { page };
    if (searchValue) params.name = searchValue;
    dispatch(
      fetchSections(params),
    );
  };

  const search = (value, page) => {
    if (value) {
      dispatch(
        fetchSections({
          page: 1,
          perPage: 30,
          name: value,
        }),
      );
    } else {
      dispatch(
        fetchSections({ page: page || 1 }),
      );
    }
    setSearchValue(value);
  };

  const chooseSection = id => {
    if (choosenSections[id]) {
      dispatch(
        unchoose(id),
      );
    } else {
      dispatch(
        choose(sections.list[id]),
      );
    }
  };

  const goBack = () => {
    if (checklistTypeId) {
      history.push(`/tools/checklist-types/${checklistTypeId}`);
    } else {
      history.push('/tools/create-checklist-type');
    }
  };

  const chooseSections = () => {
    const output = [];
    Object.keys(choosenSections).filter(id => choosenSections[id]).forEach(id => {
      const section = formatVerificationType(choosenSections[id]);
      section.position = Object.keys(checklistType.verificationTypes).length + 1;
      output.push(section);
    });

    dispatch(
      addVerificationTypes(checklistTypeId || fakeChecklistTypeId, output, 'section'),
    );
    goBack();
  };

  let checklistType;
  if (checklistTypes.list && checklistTypes.list[checklistTypeId || fakeChecklistTypeId]) {
    checklistType = checklistTypes.list[checklistTypeId || fakeChecklistTypeId];
  }

  let sectionsArray = [];
  if (sections.total && sections.isLoading === false) {
    sectionsArray = Object.keys(clearObject(sections.list))
      .map(id => sections.list[id]).reverse();
  }

  const submitSection = e => {
    if (modalIsActive === 'edit') {
      dispatch(
        editSection(
          editFormId,
          e.value,
          id => {
            search(searchValue, sections.page);
            toggleModal(false);
            dispatch(
              unchoose(id),
            );
          },
        ),
      );
    } else if (modalIsActive === 'create') {
      dispatch(
        createSection(
          e.value,
          () => {
            search(searchValue, sections.page);
            toggleModal(false);
          },
        ),
      );
    }
  };

  const remove = () => {
    let sectionIds = Object.keys(choosenSections).filter(id => choosenSections[id]);
    sectionIds.forEach(id => {
      dispatch(
        removeSection(id),
      );
      dispatch(
        unchoose(id),
      );
    });
    toggleRemoveModal(false);
  };

  const toggleButton = e => {
    if (e.target.value) {
      $('#sectionForm .submit').prop('disabled', false).removeClass('disabled');
    } else {
      $('#sectionForm .submit').prop('disabled', true).addClass('disabled');
    }
  };

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return <Box className={s.sections}>
    <Box direction='row' width='100%' justify='between' className={s.navHeader}>
      <Button className='buttonLink' onClick={goBack}>
        <FormPreviousLink />
        Checklist
      </Button>
    </Box>
    <div id='controls' className={s.controls}>
      <Button type='button' primary label='Choose' className='submit' onClick={chooseSections} />
      <Button type='button' primary label='Edit' className='submitEdit' onClick={() => toggleModal('edit')} />
      <Button
        type='button' primary label='Remove' className='submit' color='#ff0000'
        onClick={() => toggleRemoveModal(true)}
      />
      {removeModalIsActive &&
        <Layer
          className='modal40'
          onEsc={() => toggleRemoveModal(false)}
          onClickOutside={() => toggleRemoveModal(false)}
        >
          <Text>Are you sure?</Text>
          <Box direction='row' className='removeModalButtons'>
            <Button type='button' label='Discard' onClick={() => toggleRemoveModal(false)} />
            <Button type='button' primary label='Remove' onClick={remove} color='#ff0000' />
          </Box>
          <Close onClick={() => toggleRemoveModal(false)} />
        </Layer>}
      <Button type='button' primary label='Add' onClick={() => toggleModal('create')} />
      {modalIsActive &&
        <Layer
          className='modal60'
          onEsc={() => toggleModal(false)}
          onClickOutside={() => toggleModal(false)}
        >
          <Form id='sectionForm' onSubmit={submitSection}>
            <FormField htmlFor='name' label='Name'>
              <TextInput
                id='name'
                name='name'
                defaultValue={modalIsActive === 'edit' ? choosenSections[editFormId].name : null}
                onChange={toggleButton}
              />
            </FormField>
            <Box direction='row' margin={{top: 'medium'}}>
              <Button type='submit' primary disabled label='Submit' className='submit disabled' />
            </Box>
          </Form>
          <Close onClick={() => toggleModal(false)} />
        </Layer>
      }
    </div>
    <Form>
      <TextInput
        onChange={e => search(e.target.value)}
        placeholder='Type section name here'
        value={searchValue}
      />
    </Form>
    {sections.isLoading === false ?
      !objectIsEmpty(sections.list) ?
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Text className={s.header}>Name</Text>
                </TableCell>
                <TableCell>
                  <Text className={s.header}>Created</Text>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {sectionsArray.map(section =>
                section.id &&
                  <TableRow
                    key={section.id}
                    className={s.row}
                    onClick={() => chooseSection(section.id)}
                  >
                    <TableCell>
                      {section.isLoading ?
                        <Loader mod={s.loader} type='icon' /> :
                        choosenSections[section.id] ? <CheckboxSelected /> : <Checkbox />}
                    </TableCell>
                    <TableCell>
                      <Text>{section.name}</Text>
                    </TableCell>
                    <TableCell size='small'>
                      <Moment format='lll'>{section.created}</Moment>
                    </TableCell>
                  </TableRow>
              )}
            </TableBody>
          </Table>

          {sections.total > 30 &&
            <Box>
              <Pagination
                className={s.pagination}
                numberItems={sections.total}
                step={30}
                numberMiddlePages={3}
                onChange={changePage}
                page={sections.page}
                alignSelf='center'
              />
            </Box>
          }
          <AuthCheck isAuthorized={true} />
        </>
      :
        <Box className={s.empty} align='center'>Nothing found</Box>
    :
      <Loader mod={s.loader} />}
  </Box>
};

export default Sections;
