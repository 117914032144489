import {
  getAPIAddress, toCamelCase, handleRes, formatText, toSnakeCase, convertArrayToHashmap, handleError,
} from '../../helpers';
import * as types from '../../constants';


export const fetchRejectReasons = params => dispatch => {
  dispatch({
    type: types.FETCH_REJECT_REASONS,
    params,
  });

  const APIAdress = getAPIAddress();
  let reqParams = {
    page: 1,
    perPage: 9000,
  };
  if (params) {
    reqParams = {
      perPage: 30,
      ...params,
    };
  }
  reqParams = toSnakeCase(reqParams);
  fetch(`${APIAdress}/qc/reject_reasons?` + new URLSearchParams(reqParams))
    .then(res => handleRes(res))
    .then(res => {
      if (res.total === undefined) throw res;

      let rejectReasons = toCamelCase(res);
      rejectReasons.list = convertArrayToHashmap(rejectReasons.list);

      dispatch({
        type: types.FETCH_REJECT_REASONS_SUCCESS,
        rejectReasons,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_REJECT_REASONS_ERROR,
        error: formatText(res.error),
      });
    });
};

export const createRejectReason = (rejectReason, successCallback) => dispatch => {
  const fakeId = Math.random();

  dispatch({
    type: types.CREATE_REJECT_REASON,
    fakeId,
    rejectReason,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/qc/reject_reasons`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      toSnakeCase(rejectReason),
    ),
  })
    .then(res => handleRes(res))
    .then(res => {
      if (res.error) throw res;

      dispatch({
        type: types.CREATE_REJECT_REASON_SUCCESS,
        fakeId,
        rejectReason: {
          id: res.id,
        },
      });
      if (successCallback) successCallback(res.id);
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Reason has been created!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.CREATE_REJECT_REASON_ERROR,
        fakeId,
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const editRejectReason = (id, params, successCallback) => dispatch => {
  dispatch({
    type: types.EDIT_REJECT_REASON,
    id,
    params,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/qc/reject_reasons/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      toSnakeCase(params),
    ),
  })
    .then(res => handleRes(res))
    .then(res => {
      if (res.error) throw res;

      dispatch({
        type: types.EDIT_REJECT_REASON_SUCCESS,
        id: res.id,
      });
      if (successCallback) successCallback(res.id);
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'RejectReason has been edited!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.EDIT_REJECT_REASON_ERROR,
        id,
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const removeRejectReason = (id, successCallback) => dispatch => {
  dispatch({
    type: types.REMOVE_REJECT_REASON,
    id,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/qc/reject_reasons/${id}`, {
    method: 'DELETE',
  })
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      dispatch({
        type: types.REMOVE_REJECT_REASON_SUCCESS,
        id,
      });
      if (successCallback) successCallback();
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Removed!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.REMOVE_REJECT_REASON_ERROR,
        id,
        error: formatText(res.error),
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};

export const choose = rejectReason => dispatch => {
  dispatch({
    type: types.CHOOSE_REJECT_REASON,
    rejectReason,
  });
};

export const unchoose = id => dispatch => {
  dispatch({
    type: types.UNCHOOSE_REJECT_REASON,
    id,
  });
};

export const reset = () => dispatch => {
  dispatch({
    type: types.RESET_CHOSEN_REJECT_REASONS,
  });
};
