import { getAPIAddress, toCamelCase, handleRes, formatText, handleError } from '../../helpers';
import * as types from '../../constants';


export const fetchDevices = () => dispatch => {
  dispatch({
    type: types.FETCH_DEVICES,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/devices`)
    .then(res => handleRes(res))
    .then(res => {
      if (!Array.isArray(res)) throw res;

      let devices = res;
      devices = devices.reduce((devicesHashMap, device) => {
        device = toCamelCase(device)
        devicesHashMap[device.id] = device;
        return devicesHashMap;
      }, {});

      dispatch({
        type: types.FETCH_DEVICES_SUCCESS,
        devices,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_DEVICES_ERROR,
        error: formatText(res.error),
      });
    });
};
