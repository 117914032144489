import * as types from '../../constants';


const thermo = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_THERMO_TIMESTAMPS:
      return {
        ...state,
        isLoading: true,
      };

    case types.FETCH_THERMO_TIMESTAMPS_SUCCESS:
      return {
        ...state,
        ...action.streams,
        isLoading: false,
      };

    case types.FETCH_THERMO_TIMESTAMPS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case types.FETCH_THERMO_FRAME:
      return {
        ...state,
        ...action.params,
        isLoading: action.isLoading,
      };

    case types.FETCH_THERMO_FRAME_SUCCESS:
      return {
        ...state,
        ...action.params,
        frames: {
          ...state.frames,
          ...action.frame,
        },
        isLoading: false,
      };

    case types.FETCH_THERMO_FRAME_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default thermo;
