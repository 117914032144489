import { getAPIAddress, toSnakeCase, handleRes, formatText, handleError } from '../../helpers';
import * as types from '../../constants';


export const editBarrel = (id, barrel, params, successCallback) => dispatch => {
  barrel = {...barrel, ...params};

  dispatch({
    type: types.EDIT_BARREL,
    id,
    barrel,
  });

  barrel.materialId = barrel.material.id;
  delete barrel.id;
  delete barrel.isLoading;
  delete barrel.qcChecklists;
  delete barrel.error;
  delete barrel.changedUser;
  delete barrel.attachments;
  delete barrel.consistency;
  delete barrel.parts;
  delete barrel.material;
  delete barrel.media;
  delete barrel.changelog;
  delete barrel.odooLink;
  delete barrel.sortedChecklists;
  if (!barrel.viscosity) barrel.viscosity = null;
  if (!barrel.netWeight) barrel.netWeight = null;
  if (!barrel.grossWeight) barrel.grossWeight = null;

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/barrels/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      toSnakeCase(barrel),
    ),
  })
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      dispatch({
        type: types.EDIT_BARREL_SUCCESS,
        id: res.id,
      });
      successCallback();
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Changed!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.EDIT_BARREL_ERROR,
        id,
        error: formatText(res.error),
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};
