import { Link } from 'react-router-dom';
import {
  Text, Box,
} from 'grommet';
import Slider from 'react-slick';
import { isEmpty } from 'lodash';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { clearObject } from '../../helpers';

import s from './index.module.scss';


const Media = props => {
  let previewSettings;
  let media;
  if (!isEmpty(props.media)) {
    media = clearObject(props.media);
    previewSettings = {
      lazyLoad: true,
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: Object.keys(media).length > 4 ? 4 : Object.keys(media).length,
      slidesToScroll: Object.keys(media).length > 4 ? 4 : Object.keys(media).length,
    };
  }

  return !isEmpty(media) ?
    <Box className={s.media}>
      <Text margin={{top: 'medium', left: 'small', right: 'small', bottom: 'small'}} weight='bold'>
        Media:
      </Text>
      <Box className={s.preview}>
        <Slider {...previewSettings}>
          {Object.keys(media).map(key => {
            return media[key] &&
              media[key].type.mime === 'video/mp4' ?
                <div key={key} className={s.slide}>
                  <video
                    alt={media[key].name}
                    width={Object.keys(media).length === 1 ? '25%' :
                      Object.keys(media).length === 2 ? '75%' : '95%'}
                    controls
                  >
                    <source src={`/api/v1/attachments/${key}#t=0.1`} type='video/mp4' />
                  </video>
                </div> :
                <div key={key} className={s.slide}>
                  <Link
                    target='_blank'
                    to={`/api/v1/attachments/${key}`}
                  >
                    <img
                      alt={media[key].name}
                      width={Object.keys(media).length === 1 ? '25%' :
                        Object.keys(media).length === 2 ? '75%' : '95%'}
                      src={`/api/v1/attachments/${key}`}
                    />
                  </Link>
                </div>})}
        </Slider>
      </Box>
    </Box>
    : null
};

export default Media;
