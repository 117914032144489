import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LocationsComponent from '../../components/locations';
import { fetchProfile } from '../profile/actions';

import s from './index.module.scss';


const Locations = () => {
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.locations}>
      {profile.name &&
        <LocationsComponent />}
    </div>
  );
};

export default Locations;