import { reset } from '../barrels/actions';
import {
  getAPIAddress, toCamelCase, handleRes, formatText, convertArrayToHashmap, convertViscosityToConsistency,
  formatVerificationType, convertMmToInches, formatChangelog, handleError,
} from '../../helpers';

import * as types from '../../constants';


export const fetchBarrel = id => dispatch => {
  dispatch({
    type: types.FETCH_BARREL,
    id,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/barrels/${id}?` + new URLSearchParams({
    qc: true,
  }))
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      let barrel = toCamelCase(res);
      barrel.parts = convertArrayToHashmap(barrel.parts);
      barrel.qcChecklists = convertArrayToHashmap(barrel.qcChecklists);
      const MEDIA_MIMES = ['image/jpeg', 'image/png', 'video/mp4'];
      barrel.media = barrel.attachments.filter(attachment => {
        if (MEDIA_MIMES.includes(attachment.type.mime)) return attachment;
        return null;
      });
      barrel.media = convertArrayToHashmap(barrel.media);
      barrel.attachments = convertArrayToHashmap(barrel.attachments);
      Object.keys(barrel.qcChecklists).forEach(id => {
        barrel.qcChecklists[id].verifications.forEach(verification => {
          if (verification.measurement && barrel.qcChecklists[id].type.isImperial) {
            verification.measurement = convertMmToInches(verification.measurement);
          }
          verification.type = formatVerificationType(
            verification.type,
            barrel.qcChecklists[id].type && barrel.qcChecklists[id].type.isImperial ? 'Imperial' : 'Metrical',
          );
        });

        barrel.qcChecklists[id].verifications = convertArrayToHashmap(barrel.qcChecklists[id].verifications);
        barrel.qcChecklists[id].media = barrel.qcChecklists[id].attachments.filter(attachment => {
          if (MEDIA_MIMES.includes(attachment.type.mime)) return attachment;
          return null;
        });
        barrel.qcChecklists[id].media = convertArrayToHashmap(barrel.qcChecklists[id].media);
        barrel.qcChecklists[id].attachments = convertArrayToHashmap(barrel.qcChecklists[id].attachments);
      });
      if (barrel.viscosity) {
        barrel.consistency = convertViscosityToConsistency(barrel.viscosity);
      }
      if (barrel.changelog) barrel.changelog = formatChangelog(barrel.changelog);

      dispatch({
        type: types.FETCH_BARREL_SUCCESS,
        id,
        barrel,
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.FETCH_BARREL_ERROR,
        id,
        error: formatText(res.error),
      });
    });
};

export const removeBarrel = (id, successCallback) => dispatch => {
  dispatch({
    type: types.REMOVE_BARREL,
    id,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/barrels/${id}`, {
    method: 'DELETE',
  })
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      dispatch({
        type: types.REMOVE_BARREL_SUCCESS,
        id,
      });
      dispatch(reset());
      successCallback();
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Removed!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.REMOVE_BARREL_ERROR,
        id,
        error: formatText(res.error),
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};
