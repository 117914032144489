import { useSelector, useDispatch } from 'react-redux';
import { Box, Text } from 'grommet';

import { hideNotification } from './actions';

import s from './index.module.scss';


const Notification = () => {
  const notification = useSelector(state => state.notification);
  let background;
  if (notification.type === 'success') {
    background = 'status-ok';
  } else if (notification.type === 'error') {
    background = 'status-error';
  } else {
    background = 'status-warning';
  }

  const dispatch = useDispatch();
  if (notification.type) {
    setTimeout(() => {
      dispatch(hideNotification())
    }, 5000);
  }

  return notification.type ?
    <Box
      className={`${s.notification} ${notification.modifier ? notification.modifier : ''}`}
      background={background}
      pad={{top: 'small', bottom: 'small', left: 'medium', right: 'medium'}}
    >
      <Text>{notification.text}</Text>
    </Box>
  :
    null
};

export default Notification;
