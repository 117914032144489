import { getAPIAddress, toSnakeCase, handleRes, formatText, handleError } from '../../helpers';
import * as types from '../../constants';


export const signIn = (params, successCallback) => (dispatch) => {
  dispatch({
    type: types.SIGNIN,
  });

  const APIAdress = getAPIAddress();
  fetch(`${APIAdress}/auth/sign-in`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      toSnakeCase(params),
    ),
  })
    .then(res => handleRes(res))
    .then(res => {
      if (!res.id) throw res;

      dispatch({
        type: types.SIGNIN_SUCCESS,
        params: {
          id: res.id,
        },
      });
      successCallback();
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'success',
          text: 'Opened!',
        },
      });
    })
    .catch(res => {
      handleError(res);

      dispatch({
        type: types.SIGNIN_ERROR,
        error: formatText(res.error),
      });
      dispatch({
        type: types.SHOW_NOTIFICATION,
        params: {
          type: 'error',
          text: formatText(res.error),
        },
      });
    });
};
