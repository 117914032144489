import * as types from '../../constants';


const profile = (state = {}, action) => {
  switch (action.type) {
    case types.SIGNIN:
      return {
        ...state,
        isLoading: true,
      };

    case types.SIGNIN_SUCCESS:
      return {
        ...state,
        ...action.params,
        isLoading: false,
        error: false,
      };

    case types.SIGNIN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case types.SIGNOUT:
      return {
        ...state,
        isLoading: true,
      };

    case types.SIGNOUT_SUCCESS:
      return {
        isLoading: false,
        error: false,
      };

    case types.SIGNOUT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case types.CHANGE_IS_AUTHORIZED:
      return {
        ...state,
        ...action.params,
      };

    case types.FETCH_PROFILE:
      return {
        ...state,
        isLoading: true,
      };

    case types.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.profile,
        isLoading: false,
        error: false,
      };

    case types.FETCH_PROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default profile;
