import * as types from '../../constants';


const lightCalibrations = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_LIGHT_CALIBRATIONS:
      return {
        ...state,
        ...action.params,
        isLoading: true,
      };

    case types.FETCH_LIGHT_CALIBRATIONS_SUCCESS:
      return {
        ...state,
        ...action.lightCalibrations,
        isLoading: false,
      };

    case types.FETCH_LIGHT_CALIBRATIONS_ERROR:
      return {
        ...state,
        total: 0,
        list: [],
        isLoading: false,
        error: action.error,
      };

    case types.FETCH_LIGHT_CALIBRATION:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            isLoading: true,
          },
        },
      };

    case types.FETCH_LIGHT_CALIBRATION_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...action.lightCalibration,
            isLoading: false,
            error: false,
          },
        },
      };

    case types.FETCH_LIGHT_CALIBRATION_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            isLoading: false,
            error: action.error,
          },
        },
      };

    case types.REMOVE_LIGHT_CALIBRATION:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: true,
          },
        },
      };

    case types.REMOVE_LIGHT_CALIBRATION_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: null,
        },
      };

    case types.REMOVE_LIGHT_CALIBRATION_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          [action.id]: {
            ...state.list[action.id],
            isLoading: false,
            error: action.error,
          },
        },
      };

    default:
      return state;
  }
};

export default lightCalibrations;

