import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import {
  Box, Table, TableBody, TableCell, TableRow, Text, Form, FormField, TextInput, Select, TextArea, Button,
} from 'grommet';
import $ from 'jquery';

import Loader from '../loader';
import AuthCheck from '../auth-check';
import Header from '../header';
import Attachments from '../attachments';
import {
  createChecklistType, editChecklistType, saveChecklistType, convertVerificationTypes,
} from '../checklist-type/actions';
import { reset as resetParts } from '../parts/actions';
import { reset as resetBarrels } from '../barrels/actions';
import DraggableVerifications from '../draggable-verifications';

import s from './index.module.scss';


const ChecklistConstructor = props => {
  const locations = useSelector(state => state.locations);
  const partTypes = useSelector(state => state.partTypes);
  const materialTypes = useSelector(state => state.materialTypes);
  const entities = useSelector(state => state.entities);
  const verificationTypes = useSelector(state => state.verificationTypes);
  const sections = useSelector(state => state.sections);
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const history = useHistory();

  const [subtypes, setSubtypes] = useState([]);
  const [isSubtypesDisabled, setSubtypesIsDisabled] = useState(true);
  const [type, setType] = useState();
  const [currentSubtypes, setCurrentSubtypes] = useState();
  const [isAttachmentsDisabled, setAttachmentsIsDisabled] = useState(true);

  if (props.checklistType && props.checklistType.entity && !subtypes.length) {
    setSubtypesIsDisabled(false);
    setAttachmentsIsDisabled(false);
    let subtypesToSelect = ['All'];
    if (props.checklistType.entity.name === 'Part') {
      subtypesToSelect = subtypesToSelect.concat(Object.keys(partTypes.list).map(key => partTypes.list[key].name));
    } else if (props.checklistType.entity.name === 'Material') {
      subtypesToSelect = subtypesToSelect.concat(
        Object.keys(materialTypes.list).map(key => materialTypes.list[key].name),
      );
    }
    setSubtypes(subtypesToSelect);
  }

  let sortedVerifications;
  const getSortedVerifications = verifications => {
    sortedVerifications = verifications;
  };

  const goBack = () => {
    history.push('/tools/checklist-types');
  };

  const goToChecklistType = id => {
    history.push(`/tools/checklist-types/${id}`);
  };

  const isCreatePage = () => {
    return Number.isInteger(props.checklistType.id) ? false : true;
  };

  const submit = e => {
    const params = {
      params: e.value,
      checklistType: props.checklistType,
      entities: entities.list,
      partTypes: partTypes.list,
      materialTypes: materialTypes.list,
      locations: locations.list,
      sortedVerifications,
      successCallback: isCreatePage() ? goToChecklistType : goBack,
    };
    if (isCreatePage()) {
      dispatch(
        createChecklistType(params),
      );
    } else {
      dispatch(
        editChecklistType(params),
      );
    }
    dispatch(resetParts());
    dispatch(resetBarrels());
  };

  const getSubtypesDefaults = () => {
    if (props.checklistType && props.checklistType.entity
      && (props.checklistType.partTypes || props.checklistType.materials)
    ) {
      if (props.checklistType.entity.name === 'Part') {
        if (props.checklistType.partTypes.length === Object.keys(partTypes.list).length) return 'All';
        return props.checklistType.partTypes.map(type => type.name);
      } else if (props.checklistType.entity.name === 'Material') {
        if (props.checklistType.materials.length === Object.keys(materialTypes.list).length) return 'All';
        return props.checklistType.materials.map(type => type.name);
      }
    }
  };

  const getLocationsDefaults = () => {
    if (props.checklistType && props.checklistType.locations) {
      if (props.checklistType.locations.length === Object.keys(locations.list).length) return 'All';
      return props.checklistType.locations.map(location => location.name)
    }
  };

  const unlockButton = () => {
    if ($('#name').prop('value') && $('#type input').prop('value')) {
      $('.submit').prop('disabled', false).removeClass('disabled');
    } else {
      $('.submit').prop('disabled', true).addClass('disabled');
    }
  };

  useEffect(() => {
    unlockButton();
  }, []);

  const changeName = e => {
    unlockButton();
    dispatch(
      saveChecklistType(
        props.checklistType.id,
        { name: e.target.value },
      ),
    );
  };

  const changeType = e => {
    setType(e.value);
    setSubtypesIsDisabled(false);
    setAttachmentsIsDisabled(false);
    let subtypesToSelect = ['All'];
    if (e.value === 'Part') {
      subtypesToSelect = subtypesToSelect.concat(Object.keys(partTypes.list).map(key => partTypes.list[key].name));
    } else if (e.value === 'Material') {
      subtypesToSelect = subtypesToSelect.concat(
        Object.keys(materialTypes.list).map(key => materialTypes.list[key].name),
      );
    }
    setSubtypes(subtypesToSelect);
    unlockButton();
    setCurrentSubtypes(null);
    dispatch(
      saveChecklistType(
        props.checklistType.id,
        { type: e.value },
        { entities },
      ),
    );
  };

  const changeSubtypes = e => {
    setCurrentSubtypes(e.value);
    dispatch(
      saveChecklistType(
        props.checklistType.id,
        { subtypes: e.value },
        {
          type: props.checklistType.entity,
          partTypes, materialTypes,
        },
      ),
    );
  };

  const changeLocations = e => {
    dispatch(
      saveChecklistType(
        props.checklistType.id,
        { locations: e.value },
        { locations },
      ),
    );
  };

  const changeDescription = e => {
    dispatch(
      saveChecklistType(
        props.checklistType.id,
        { description: e.target.value },
      ),
    );
  };

  const changeUnits = e => {
    dispatch(
      saveChecklistType(
        props.checklistType.id,
        { isImperial: e.value === 'Imperial' },
      ),
    );
    dispatch(
      convertVerificationTypes(
        e.value, props.checklistType.id, props.checklistType.verificationTypes, verificationTypes.list, sections.list,
      ),
    );
  };

  const goToSections = () => {
    if (isCreatePage()) {
      history.push('/tools/create-checklist-type/sections');
    } else {
      history.push(`/tools/checklist-types/${props.checklistType.id}/sections`);
    }
  };

  const goToVerificationTypes = () => {
    if (isCreatePage()) {
      history.push('/tools/create-checklist-type/verification-types');
    } else {
      history.push(`/tools/checklist-types/${props.checklistType.id}/verification-types`);
    }
  };

  const getLocations = () => {
    let locationsToSelect = ['All'];
    return locationsToSelect.concat(Object.keys(locations.list).map(key => locations.list[key].name))
  };

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return partTypes.isLoading === false && locations.isLoading === false && entities.isLoading === false
      && materialTypes.isLoading === false && verificationTypes.isLoading === false ?
    !props.checklistType || !props.checklistType.error ?
      <Box className={s.checklistConstructor}>
        {!props.checklistType && <Header type='createChecklistType' />}
        {isCreatePage() ?
          <Form onSubmit={submit}>
            <Box>
              <Text margin='small' weight='bold'>Basic information:</Text>
              <Table className={s.basicInfo}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <FormField htmlFor='name' label='Name'>
                        <TextInput
                          id='name'
                          name='name'
                          defaultValue={props.checklistType && props.checklistType.name}
                          onChange={changeName}
                        />
                      </FormField>
                    </TableCell>
                    <TableCell>
                      <FormField label='Type' name='type'>
                        <Select
                          id='type'
                          name='type'
                          placeholder='Select type'
                          onChange={changeType}
                          options={Object.keys(entities.list).map(key => entities.list[key].name)}
                          value={type}
                          defaultValue={props.checklistType && props.checklistType.entity
                            && entities.list[props.checklistType.entity.id].name}
                        />
                      </FormField>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            <Box direction='row' margin={{top: 'medium', left: 'small'}} className={s.submit}>
              <Button type='submit' primary disabled label='Go next' className='submit disabled' />
            </Box>
          </Form>
        :
          <>
            <Form onSubmit={submit}>
              <Box className={s.blockWithSep}>
                <Text margin='small' weight='bold'>Basic information:</Text>
                <Table className={s.basicInfo}>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <FormField htmlFor='name' label='Name'>
                          <TextInput
                            id='name'
                            name='name'
                            defaultValue={props.checklistType && props.checklistType.name}
                            onChange={changeName}
                          />
                        </FormField>
                      </TableCell>
                      <TableCell>
                        <FormField label='Type' name='type'>
                          <Select
                            id='type'
                            name='type'
                            placeholder='Select type'
                            onChange={changeType}
                            options={Object.keys(entities.list).map(key => entities.list[key].name)}
                            value={type}
                            defaultValue={props.checklistType && props.checklistType.entity
                              && entities.list[props.checklistType.entity.id].name
                            }
                          />
                        </FormField>
                      </TableCell>
                      <TableCell>
                        <FormField label='Subtypes' name='subtypes'>
                          <Select
                            name='subtypes'
                            placeholder='Select subtypes'
                            multiple
                            disabled={isSubtypesDisabled}
                            closeOnChange={false}
                            options={subtypes}
                            value={currentSubtypes}
                            defaultValue={getSubtypesDefaults()}
                            onChange={changeSubtypes}
                          />
                        </FormField>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <FormField label='Units' name='units'>
                          <Select
                            name='units'
                            placeholder='Select units'
                            options={['Metrical', 'Imperial']}
                            defaultValue={props.checklistType.isImperial ? 'Imperial' : 'Metrical'}
                            onChange={changeUnits}
                          />
                        </FormField>
                      </TableCell>
                      <TableCell>
                        <FormField label='Locations' name='locations'>
                          <Select
                            name='locations'
                            placeholder='Select locations'
                            multiple
                            closeOnChange={false}
                            options={getLocations()}
                            defaultValue={getLocationsDefaults()}
                            onChange={changeLocations}
                          />
                        </FormField>
                      </TableCell>
                      <TableCell>
                        <FormField htmlFor='description' label='Description'>
                          <TextArea
                            id='description'
                            name='description'
                            defaultValue={props.checklistType && props.checklistType.description}
                            onChange={changeDescription}
                          />
                        </FormField>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Box direction='row' margin={{top: 'medium', left: 'small'}} className={s.submit}>
                <Button type='submit' primary label='Submit' className='submit' />
              </Box>
            </Form>
          <Box className={`${s.verifications} ${!isAttachmentsDisabled && s.blockWithSep}`}>
            <Text margin='small' weight='bold'>Verifications:</Text>
            {props.checklistType && props.checklistType.verificationTypes &&
              <DraggableVerifications
                checklistTypeId={props.checklistType.id}
                verifications={props.checklistType.verificationTypes}
                callback={getSortedVerifications}
              />
            }
            <Button onClick={goToSections} className='link'>Add sections</Button>
            <Button onClick={goToVerificationTypes} className='link'>Add verifications</Button>
          </Box>
          <Box margin={{top: '-25px', left: 'small', bottom: 'medium'}}>
            <Attachments attachments={props.checklistType.attachments} disabled={isAttachmentsDisabled} />
          </Box>
        </>}
        <AuthCheck isAuthorized={true} />
      </Box>
    :
      <Box className={s.checklistConstructor} align='center'>{props.checklistType.error}</Box>
  :
    <Loader />
};

export default ChecklistConstructor;
