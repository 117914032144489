import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Table, TableBody, TableCell, TableHeader, TableRow, Text, Pagination, Form, TextInput, DropButton, Layer, Menu,
} from 'grommet';
import {
  StatusGood, CircleAlert, Clear, StatusInfo, Close, Logout, Script, Flag,
} from 'grommet-icons';
import Moment from 'react-moment';
import $ from 'jquery';

import { getLS, sortChecklists } from '../../helpers';
import { makeFilterParamsString } from './helpers';
import AuthCheck from '../auth-check';
import Loader from '../loader';
import { fetchParts, showExportError } from './actions';
import { findPartByRfid } from '../../pages/parts/actions';
import { getPartName } from '../../helpers';

import s from './index.module.scss';


const Parts = () => {
  const parts = useSelector(state => state.parts);
  const locations = useSelector(state => state.locations);
  const profile = useSelector(state => state.profile);
  const partTypes = useSelector(state => state.partTypes);
  const stages = useSelector(state => state.stages);
  const devices = useSelector(state => state.devices);
  const dispatch = useDispatch();
  const history = useHistory();
  const [infoModalStatuses, changeInfoModals] = useState({});
  const [sort, setSort] = useState('started');

  const changePage = ({ page }) => {
    dispatch(
      fetchParts(
        { page, sort },
        {
          locations,
          types: partTypes,
          stages,
          devices,
        },
        parts.filters,
      ),
    );
  };

  useEffect(() => {
    dispatch(
      fetchParts(
        {
          page: parts.page || 1,
          sort,
        },
        {
          locations,
          types: partTypes,
          stages,
          devices,
        },
        parts.filters,
      ),
    );

    let prevRfidValue;
    const interval = setInterval(() => {
      const value = $('#rfid').val();
      if (value && value !== prevRfidValue) {
        dispatch(
          findPartByRfid(value, goToPart),
        );
      }
      prevRfidValue = value;
    }, 250);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      fetchParts(
        {
          page: parts.page || 1,
          sort,
        },
        {
          locations,
          types: partTypes,
          stages,
          devices,
        },
        parts.filters,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  const goToPart = part => {
    history.push(`/parts/${part.id}`);
  };

  const goToGroupedParts = () => {
    history.push('/grouped-parts');
  };

  let partsArray;
  const userSettings = getLS('userSettings');
  if (parts.total && parts.isLoading === false && stages.isLoading === false) {
    partsArray = Object.keys(parts.list)
      .filter(id => parts.list[id].isLoading !== true)
      .filter(id => {
        return (userSettings && userSettings.debug) || !parts.list[id].isDebug;
      })
      .sort((prevId, id) => {
        return new Date(parts.list[id][sort]) - new Date(parts.list[prevId][sort]);
      })
      .map(id => {
        const part = parts.list[id];
        part.sortedChecklists = sortChecklists(part.qcChecklists, stages.list);
        return part;
      });
  }

  const toggleInfoModal = (e, status, id, closeAll) => {
    if (closeAll) {
      changeInfoModals({
        [id]: status,
      });
      e.stopPropagation();
      return;
    }

    changeInfoModals({
      ...infoModalStatuses,
      [id]: status,
    });
    e.stopPropagation();
  };

  const exportAsCSV = type => {
    if (parts.total > 100) {
      dispatch(showExportError());
      return;
    }

    const typeString = `as${type}Csv`;
    window.location.replace(
      '/api/v1/parts?' + makeFilterParamsString(
        {
          page: 1,
          perPage: 9000,
          [typeString]: true,
        },
        parts.filters,
        stages, locations, devices, partTypes,
      ),
    );
  };

  const getFormat = started => {
    const date = new Date(started);
    const currentDate = new Date();
    if (currentDate.getMonth() === date.getMonth() && currentDate.getYear() === date.getYear()) {
      if (currentDate.getDate() === date.getDate()) {
        return '[Today]';
      } else if (currentDate.getDate() - 1 === date.getDate()) {
        return '[Yesterday]';
      }
    }
    if (currentDate.getFullYear() === date.getFullYear()) {
      return 'MMMM Do, dddd';
    } else {
      return 'MMMM Do, y';
    }
  };

  const getRangeFormat = (part, {type}={}) => {
    if (sort === type) return 'hh:mm A'

    const startedDay = new Date(part.started).getDate();
    const endedDay = new Date(part.ended).getDate();
    if (startedDay !== endedDay) return 'D MMM hh:mm A';
    return 'hh:mm A';
  };

  if (profile.isLoading === false && !profile.id) return <AuthCheck isAuthorized={false} />;

  return parts.isLoading === false && locations.isLoading === false && stages.isLoading === false ?
    parts.total ?
      <div className={s.parts}>
        <Form className={s.controls}>
          <TextInput id='rfid' name='rfid' placeholder="Scan part's rfid here" />
          <Menu
            label='Actions'
            items={[
              { label: 'Group by work centers and panel types', onClick: goToGroupedParts },
              { label: 'Export QC as CSV', onClick: () => { exportAsCSV('QC'); } },
              { label: 'Export timings as CSV', onClick: () => { exportAsCSV('Timings'); } },
            ]}
          />
        </Form>
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>ID / Name</TableCell>
              <TableCell
                className={sort === 'started' && s.activeHeadline}
                onClick={() => setSort('started')}
              >
                Started
              </TableCell>
              <TableCell
                className={sort === 'ended' && s.activeHeadline}
                onClick={() => setSort('ended')}
              >
                Last update
              </TableCell>
              <TableCell>Work center</TableCell>
              <TableCell>QC</TableCell>
              <TableCell align='center'>Details</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {partsArray.map((part, index) => {
              let prevDay;
              if (partsArray[index + 1]) prevDay = new Date(partsArray[index + 1][sort]).getDate();
              const currentDay = new Date(part[sort]).getDate();

              return [
                !index &&
                  <TableRow key={index + 101} className={s.dateRow}>
                    <TableCell colSpan='6'>
                      <Moment format={getFormat(part[sort])}>{part[sort]}</Moment>
                    </TableCell>
                  </TableRow>,
                  <TableRow key={index} className={`${s.row} ${!part.rfid && s.rowDim}`}>
                    <TableCell size='medium' className={s.name}>
                      <div className={s.title}>
                        {part.type && (part.type.name.includes('curve') ?
                          <span title='Curve'><Script /></span> :
                        part.type.name.includes('Sample') ?
                          <span title='Sample'><Flag /></span> : null)}
                        <Link to={`/parts/${part.id}`} className='link'>
                          {getPartName(part)}
                        </Link>
                      </div>
                    </TableCell>
                    <TableCell size={sort === 'started' ? 'small' : 'medium'} className={s.time}>
                      <Moment
                        format={getRangeFormat(part, {type: 'started'})}
                      >
                        {part.started}
                      </Moment>
                    </TableCell>
                    <TableCell size={sort === 'ended' ? 'small' : 'medium'} className={s.time}>
                      {part.ended ?
                        <Moment
                          format={getRangeFormat(part, {type: 'ended'})}
                        >
                          {part.ended}
                        </Moment> :
                        'In progress'}
                    </TableCell>
                    <TableCell size='small'>{part.stage ? part.stage.name : '—'}</TableCell>
                    <TableCell size='xsmall'>
                      <div className={s.title}>
                        <DropButton
                          dropContent={
                            <Box className={s.drop}>
                              <Table>
                                <TableBody>
                                  {part.sortedChecklists.length && part.sortedChecklists.map(checklist =>
                                    checklist && checklist.resolution &&
                                      <TableRow key={checklist.id}>
                                        <TableCell>
                                          <Text
                                            color={[4, 8].includes(checklist.resolution.id) ? '#008000' :
                                              [5, 7].includes(checklist.resolution.id) ? '#ff8d00' :
                                              [6].includes(checklist.resolution.id) ? '#ff0000' : null}
                                          >
                                            {[4, 8].includes(checklist.resolution.id) ?
                                                <StatusGood color='#008000' /> :
                                              [5, 7].includes(checklist.resolution.id) ?
                                                <CircleAlert color='#ff8d00' /> :
                                              [6].includes(checklist.resolution.id) ?
                                                <Clear color='#ff0000' /> : null}
                                            {checklist.resolution.name}
                                          </Text>
                                        </TableCell>
                                        <TableCell>
                                          <Link to={`/parts/${part.id}/checklists/${checklist.id}`}>
                                            {`${(checklist.type && checklist.type.name) ||
                                                (checklist.stage && checklist.stage.name)
                                              }`}
                                          </Link>
                                        </TableCell>
                                        {checklist.changed &&
                                          <TableCell>
                                            <Moment format='lll'>{checklist.changed}</Moment>
                                            <Text>by {checklist.changedUser.name}</Text>
                                          </TableCell>}
                                      </TableRow>)}
                                </TableBody>
                              </Table>
                            </Box>}
                          dropAlign={{ right: 'left' }}
                        >
                          <div className={s.qc}>
                            {part.sortedChecklists.length ? part.sortedChecklists.map(checklist =>
                              checklist.resolution &&
                                ([4].includes(checklist.resolution.id) ?
                                    <div key={checklist.id} className={s.useAsIs} /> :
                                  [5, 7].includes(checklist.resolution.id) ?
                                    <div key={checklist.id} className={s.repair} /> :
                                  [6].includes(checklist.resolution.id) ?
                                    <div key={checklist.id} className={s.scrap} /> :
                                  [8].includes(checklist.resolution.id) ?
                                    <div key={checklist.id} className={s.repaired} /> : null)

                            ) : null}
                          </div>
                        </DropButton>
                      </div>
                    </TableCell>
                    <TableCell size='xxsmall' className={s.fullInfo}>
                      <StatusInfo
                        onClick={e => toggleInfoModal(e, true, part.id, true)}
                      />
                      {infoModalStatuses[part.id] &&
                        <Layer
                          className='modal60'
                          onEsc={e => toggleInfoModal(e, false, part.id)}
                          onClickOutside={e => toggleInfoModal(e, false, part.id)}
                        >
                          <Table className={s.infoTable}>
                            <TableBody>
                              <TableRow>
                                <TableCell>Name: </TableCell>
                                <TableCell>{part.name}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>UUID: </TableCell>
                                <TableCell>{part.uuid}</TableCell>
                              </TableRow>
                              {part.materialUsage ?
                                <TableRow>
                                  <TableCell>Material Consumed (kg): </TableCell>
                                  <TableCell>{part.materialUsage}</TableCell>
                                </TableRow>
                              : null}
                              {(part.dimensions && part.dimensions.join('') !== '000') ?
                                <TableRow>
                                  <TableCell>Dimensions (L×W×H): </TableCell>
                                  <TableCell>{part.dimensions.join('×')}</TableCell>
                                </TableRow>
                              : null}
                              {part.type ?
                                <TableRow>
                                  <TableCell>Type: </TableCell>
                                  <TableCell>{part.type.name}</TableCell>
                                </TableRow>
                              : null}
                              {part.description ?
                                <TableRow>
                                  <TableCell>Description: </TableCell>
                                  <TableCell>{part.description}</TableCell>
                                </TableRow>
                              : null}
                              {part.location ?
                                <TableRow>
                                  <TableCell>Location: </TableCell>
                                  <TableCell>{part.location.name}</TableCell>
                                </TableRow>
                              : null}
                              {part.manufacturingOrder ?
                                <TableRow>
                                  <TableCell>Manufacturing order: </TableCell>
                                  <TableCell>
                                    <Link
                                      to={{ pathname: part.manufacturingOrder.link }}
                                      target='_blank'
                                      className={`link ${s.odoo}`}
                                    >
                                      <Logout />
                                      {part.manufacturingOrder.name}
                                    </Link>
                                  </TableCell>
                                </TableRow>
                              : null}
                              {part.steelFrame ?
                                <TableRow>
                                  <TableCell>Steel frame: </TableCell>
                                  <TableCell>
                                    <Link to={`/steel-frames/${part.steelFrame.name}`} className='link'>
                                      {part.steelFrame.name}
                                    </Link>
                                  </TableCell>
                                </TableRow>
                              : null}
                              {part.rfid ?
                                <TableRow>
                                  <TableCell>RFID: </TableCell>
                                  <TableCell>{part.rfid}</TableCell>
                                </TableRow>
                              : null}
                              {part.insignia ?
                                <TableRow>
                                  <TableCell>Insignia: </TableCell>
                                  <TableCell>{part.insignia}</TableCell>
                                </TableRow>
                              : null}
                              {(part.changedUser && part.changedUser.id !== 12) ?
                                <TableRow>
                                  <TableCell>Last changed by: </TableCell>
                                  <TableCell>{part.changedUser.name}</TableCell>
                                </TableRow>
                              : null}
                            </TableBody>
                          </Table>
                          <Close onClick={e => toggleInfoModal(e, false, part.id)} />
                        </Layer>}
                    </TableCell>
                  </TableRow>,
                  prevDay !== currentDay && partsArray[index + 1] &&
                  <TableRow key={index + 102} className={s.dateRow}>
                    <TableCell colSpan='6'>
                      <Moment format={getFormat(partsArray[index + 1][sort])}>
                        {partsArray[index + 1][sort]}
                      </Moment>
                    </TableCell>
                  </TableRow>,
              ]
          })}
          </TableBody>
        </Table>

        {parts.total > 30 &&
          <Box>
            <Pagination
              className={s.pagination}
              numberItems={parts.total}
              step={30}
              numberMiddlePages={3}
              onChange={changePage}
              page={parts.page}
              alignSelf='center'
            />
          </Box>}
        <AuthCheck isAuthorized={true} />
      </div>
    :
      <Box className={s.parts} align='center'>Nothing found</Box>
  :
    <Loader mod={s.loader} />
};

export default Parts;
