import * as types from '../../constants';


const notification = (state = {}, action) => {
  switch (action.type) {
    case types.SHOW_NOTIFICATION:
      return {
        ...state,
        ...action.params,
      };

    case types.HIDE_NOTIFICATION:
      return {};

    default:
      return state;
  }
};

export default notification;
