import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChecklistTypeComponent from '../../components/checklist-type';
import { fetchLocations } from '../../components/locations/actions';
import { fetchPartTypes } from '../../components/parts/actions';
import { fetchMaterialTypes } from '../../components/material-types/actions';
import { fetchEntities } from '../../components/entities/actions';
import { fetchVerificationTypes } from '../../components/verification-types/actions';
import { fetchSections } from '../../components/sections/actions';
import { fetchProfile } from '../profile/actions';

import s from './index.module.scss';


const ChecklistType = () => {
  const profile = useSelector(state => state.profile);
  const locations = useSelector(state => state.locations);
  const partTypes = useSelector(state => state.partTypes);
  const materialTypes = useSelector(state => state.materialTypes);
  const entities = useSelector(state => state.entities);
  const verificationTypes = useSelector(state => state.verificationTypes);
  const sections = useSelector(state => state.sections);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProfile());
    dispatch(fetchLocations());
    dispatch(fetchPartTypes());
    dispatch(fetchMaterialTypes());
    dispatch(fetchEntities());
    dispatch(fetchVerificationTypes());
    dispatch(fetchSections());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.checklistType}>
      {profile.name && locations.list && partTypes.list && materialTypes.list && entities.list
          && verificationTypes.list && sections.list &&
        <ChecklistTypeComponent />}
    </div>
  );
};

export default ChecklistType;
